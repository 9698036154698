<template>
    <div class="offer-mobile" :class="{ 'active': rowActive  }">
        <a target="_blank"
           :href="'/Poskytovatel/' + item.PartnerCode"
           @click="gtmTrackClickUI('Detail partnera', {partner: item.PartnerCode});">
            <img :src="item.PartnerLogoUrl" class="small-img-table-mobile" />
        </a>

        <template v-if="item.PartnerCode === 'OB'">
            <div style="text-align: center; margin: 10px 0;">Úrokové sazby jsou stanoveny individuálně, pro aktuální sazbu kontaktujte pobočky banky.</div>
        </template>
        <template v-else>
            <div class="offer-mobile-parameters">
                <div>
                    {{$t('credit.resultTable.rate')}}
                    <vue-numeric currency="%"
                                 precision="2"
                                 currency-symbol-position="suffix"
                                 separator="space"
                                 read-only="true"
                                 v-model="item.Rate"
                                 class="bold" />
                </div>
                <div>
                    {{$t('credit.resultTable.fixation')}}
                    <vue-numeric :currency="$t('global.years')"
                                 currency-symbol-position="suffix"
                                 separator="space"
                                 read-only="true"
                                 v-model="fixace"
                                 class="bold" />
                </div>
                <div>
                    {{$t('credit.resultTable.maturity')}}
                    <span class="bold"
                          v-html="formatedMaturity">
                    </span>
                </div>
                <div>
                    {{$t('credit.resultTable.payment')}}
                    <vue-numeric :currency="$t('global.currencySymbol')"
                                 currency-symbol-position="suffix"
                                 separator="space"
                                 read-only="true"
                                 v-model="item.Installment"
                                 class="bold" />
                </div>
                <div>
                    {{$t('credit.resultTable.monthlyExpenses')}}
                    <vue-numeric :currency="$t('global.currencySymbol')"
                                 currency-symbol-position="suffix"
                                 separator="space"
                                 read-only="true"
                                 v-model="item.MonthlyCosts"
                                 class="bold" />
                </div>
                <div>
                    {{$t('credit.resultTable.processingCosts')}}
                    <vue-numeric :currency="$t('global.currencySymbol')"
                                 currency-symbol-position="suffix"
                                 separator="space"
                                 read-only="true"
                                 v-model="item.ProcessingCosts"
                                 class="bold" />
                </div>
                <div v-if="localization == 'cs'">
                    {{$t('credit.resultTable.fixCostsPerYear')}}
                    <vue-numeric :currency="$t('global.currencySymbol')"
                                 currency-symbol-position="suffix"
                                 separator="space"
                                 read-only="true"
                                 v-model="item.FixCostsPerYear"
                                 class="bold" />
                </div>
                <div v-if="localization == 'sk'">
                    Zostatok istiny úveru
                    <vue-numeric :currency="$t('global.currencySymbol')"
                                 currency-symbol-position="suffix"
                                 separator="space"
                                 read-only="true"
                                 v-model="item.BalanceAfterFixation"
                                 class="bold" />
                </div>
                <div>
                    {{$t('credit.resultTable.rpsn')}}
                    <vue-numeric currency="%"
                                 precision="2"
                                 currency-symbol-position="suffix"
                                 separator="space"
                                 read-only="true"
                                 v-model="rpsn"
                                 class="bold"></vue-numeric>
                </div>
            </div>
            <div class="text-center">
                <b v-if="item.PartnerCode == 'KB'">
                    <span v-bTooltip="'Výpočet bonity není k dispozici'">-</span>
                </b>
                <b v-else>
                    <template v-if="item.BonitaCalcState === 'Completed'">
                        <vue-numeric v-if="item.MaxAmount > 0"
                                     :currency="$t('global.currencySymbol')"
                                     currency-symbol-position="suffix"
                                     separator="space"
                                     read-only="true"
                                     v-model="item.MaxAmount"
                                     style="font-size: 18px;"
                                     :style="{color: item.MaxAmountColor}"></vue-numeric>
                        <span v-else>-</span>
                    </template>
                    <template v-else-if="item.BonitaCalcState === 'Loading'">
                        <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    <template v-else>
                        <span>{{ $t('credit.resultTable.noBonita') }}</span>
                    </template>
                </b>
            </div>
            <div class="badge-holder">
                <span v-for="prop in item.Properties"
                      :key="prop.Zkratka"
                      class="badge"
                      v-bTooltip="prop.CelyNazev"
                      :style="getPropColor(prop)">
                    {{ prop.Zkratka }}
                </span>
            </div>
            <div class="offer-mobile-buttons">
                <button type="button"
                        :class="['btn btn-default-gray', { selected: item.Selected }]"
                        @click="$emit('toggleSelectClicked')">
                    <img class="left" src="@/assets/icons/select-document.svg" />{{ $t('credit.resultTable.buttons.select') }}
                </button>
                <button type="button"
                        class="btn btn-default-gray"
                        @click="linkToChart">
                    <img class="chart left" src="@/assets/icons/splatky-graf.svg" />{{ $t('credit.resultTable.buttons.chart') }}
                </button>
            </div>

            <div class="offer-mobile-buttons dropdown" v-if="dorisMortgagesAllowed && item.PartnerCode == 'HB'">
                <button type="button"
                        class="btn btn-primary btn-arrange dropdown-toggle"
                        data-toggle="dropdown"
                        aria-expanded="false">
                    {{ $t('credit.resultTable.buttons.arrange') }} <img class="mr-6" src="@/assets/icons/downFill.svg" />
                </button>
                <ul class="dropdown-menu dropdown-menu-right">
                    <li>
                        <a :href="pricingOfferLink" :target="dorisLinkTarget"
                           @click="gtmTrackClickUI('Sjednání cenové nabídky', {partner: item.PartnerCode});">Garance úrokové sazby</a>
                    </li>
                    <li>
                        <a href="/Doris?login=true&redirectTo=/Mortgages/cz/Application/CreateNewApplication/HB" :target="dorisLinkTarget"
                           @click="gtmTrackClickUI('Sjednání online žádosti', {partner: item.PartnerCode});">Online žádost</a>
                    </li>
                </ul>
            </div>
            <div class="offer-mobile-buttons" v-if="item.ShowOnlineContractLink">
                <a :href="item.OnlineContractLink" target="_blank" type="button" class="btn btn-primary btn-arrange"
                   @click="gtmTrackClickUI('Sjednání', {partner: item.PartnerCode});">{{ $t('credit.resultTable.buttons.arrange') }}</a>
            </div>
            <div class="offer-mobile-buttons" v-if="loanParams.loanPurpose == 'Refinancing'">
                <a :href="refinanceHrefLink" target="_blank" type="button" class="btn btn-primary btn-arrange">Refinancovat</a>
            </div>
            <div class="row-arrow offer-row-arrow-mobile" v-if="!selectedOnly && !expandResults && isFirstInGroup && groupHasAlternatives">
                <img v-if="groupVisible" src="@/assets/icons/upFill.svg" @click="toggleGroupRows" />
                <img v-else src="@/assets/icons/downFill.svg" @click="toggleGroupRows" />
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        name: 'v-result-table-item',
        props: {
            item: Object,
            loanParams: Object,
            isFirstInGroup: Boolean,
            groupHasAlternatives: Boolean,
            selectedOnly: Boolean,
            expandResults: Boolean,
            sortProperty: String,
            sortProperties: Array,
            dorisMortgagesAllowed: Boolean,
            dorisLinkTarget: String,
            localization: String
        },
        data() {
            return {
                groupVisible: false,
            }
        },
        computed: {
            calendarHrefLink: function () {
                return '/GuidRedirect.aspx?module=payments&params=' + encodeURIComponent(this.calendarParams);
            },
            refinanceHrefLink: function () {
                return '/GuidRedirect.aspx?module=refinance&params=' + encodeURIComponent(this.calendarParams);
            },
            calendarParams: function () {
                return '?product=' + this.item.PartnerCode
                    + '&amount=' + this.loanParams.loanAmount
                    + '&rate=' + this.item.Rate / 100
                    + '&maturity=' + this.item.Maturity
                    + '&fixation=' + this.item.Fixation
                    ;
            },
            pricingOfferLink() {
                return '/Doris.aspx?action=newPricingOffer&code=HB&rate=' + this.item.Rate.toString().replace('.', ',')
                    + '&fixation=' + this.item.Fixation
                    + '&maturity=' + this.item.Maturity;
            },
            fixace() {
                return this.item.Fixation / 12;
            },
            rpsn() {
                return this.item.RPSN * 100;
            },
            rowActive() {
                if (this.selectedOnly) return this.item.Selected;
                if (this.expandResults) return true;
                return this.isFirstInGroup;
            },
            formatedMaturity() {
                return this.item.MaturityString.replace('<br/>', ' ');
            }
        },
        methods: {
            formattedTooltip(items) {
                var val = '<table><tbody>';
                for (const el of items) {
                    val += '<tr><td class="text-right">' + el[0] + '</td><td class="text-right">' + el[1] + '</td></tr>';
                }
                val += '</tbody></table>';
                return val;
            },
            toggleGroupRows(event) {
                this.groupVisible = !this.groupVisible;
                $(event.target).closest('div.offer-mobile').siblings().toggleClass('active');
            },
            getPropColor(prop) {
                var color = 'rgb(181 211 249)';
                if (prop.TypSlevy == 'Akce') color = 'rgb(255, 175, 204)';
                if (prop.TypSlevy == 'Metodika') color = 'rgb(202, 202, 202)';
                //Ponecháno pro případ navrácení funkčnosti
                //var color = prop.ColorOfBubble.includes(',') ? 'rgba(' + prop.ColorOfBubble + ')' : prop.ColorOfBubble
                return 'background-color: ' + color;

            },
            linkToChart() {
                window.open(this.calendarHrefLink, '_blank');
            }
        }
    }
</script>

<style scoped>
    .badge-holder {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .bonitaMaturityColumn {
        padding: 1px 8px;
    }

    .button-cell {
        display: flex;
        gap: 0px 5px;
    }

        .button-cell > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

    .row-arrow {
        margin-left: auto;
    }

        .row-arrow > img {
            cursor: pointer;
            filter: brightness(0) saturate(100%) invert(42%) sepia(9%) saturate(5890%) hue-rotate(178deg) brightness(97%) contrast(94%);
        }

    .badge {
        float: right;
        margin: 1px;
        font-size: 11px;
        color: #333;
    }
</style>