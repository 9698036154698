<template>
    <div id="newApplicant">
        <div class="modal-body">
            <div class="center-block">
                <div class="info-panel">
                    <h5 style="font-weight: bold">{{ $t('credit.resultTablePopUp.newApplicantTitle') }}</h5>
                    <p>{{ $t('credit.resultTablePopUp.newApplicantSubtitle') }}</p>
                </div>

                <div v-for="alert in alerts" v-bind:key="alert" :class="['alert', alert.class ]" role="alert">
                    {{ alert.message }}
                </div>

                <div class="input-box">
                    <v-labeled-input :label="$t('credit.resultTablePopUp.buttons.newClient')"
                                     v-model="localApplicantName"
                                     inputSize="70"
                                     isRequired="true"
                                     :maxLength="32" />
                    <v-labeled-input :label="$t('credit.resultTablePopUp.modelationNameInputLabel')"
                                     v-model="localCalculationName"
                                     inputSize="70"
                                     isRequired="true"
                                     :maxLength="32" />
                    <v-labeled-input :label="$t('credit.resultTablePopUp.modelationNoteInputLabel')"
                                     v-model="localCalculationNote"
                                     inputSize="70" />
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button"
                    class="btn btn-default pull-left"
                    data-dismiss="modal">
                <img class="left" src="@/assets/icons/X.svg" />{{ $t('credit.resultTablePopUp.buttons.cancel') }}
            </button>
            <span v-if="namesAreValid"
                  style="margin-left: 5px;"
                  v-bTooltip="$t('credit.resultTablePopUp.buttons.nameRequirements')">
                <button type="button"
                        class="btn btn-primary"
                        disabled="true">
                    <img class="left" src="@/assets/icons/savesmall.svg" />{{ $t('global.save') }}
                </button>
            </span>
            <button v-else
                    type="button"
                    class="btn btn-primary"
                    @click="saveApplicant($event)">
                <img class="left" src="@/assets/icons/savesmall.svg" />{{ $t('global.save') }}
            </button>
        </div>
    </div>
</template>

<script>
    import LabeledInput from '@/components/LabeledInput.vue'

    export default {
        name: 'NewApplicant',
        data() {
            return {
                alerts: [],
                localApplicantName: this.applicantName,
                localCalculationName: this.calculationName,
                localCalculationNote: ''
            }
        },
        components: {
            "v-labeled-input": LabeledInput
        },
        watch: {
            localApplicantName: function () {
                this.$emit('update:applicantName', this.localApplicantName);
            },
            localCalculationName: function () {
                this.$emit('update:calculationName', this.localCalculationName);
            },
        },
        computed: {
            namesAreValid: function(){
                return (!this.localCalculationName.trim() || !this.localApplicantName.trim());
            }
        },
        methods: {
            saveApplicant: function (event) {
                $(event.target).attr("disabled", true);
                $(event.target).html('<i class="fas fa-spinner fa-spin"></i>');
                
                this.axios.post("/WebServices/StoredCalculations.asmx/SaveApplicant", {
                    headers: { Accept: 'application/json' },
                    applicantName: this.applicantName,
                    calculationName: this.calculationName,
                    calculationNote: this.localCalculationNote
                })
                    .then(response => {
                        var parsedResponse = JSON.parse(response.data.d);
                        if (parsedResponse.ResponseStatusCode == 200) {
                            this.$emit('set-saved-calculation-info', parsedResponse.Data);
                            this.$emit('update:stepName', 'completed');
                        }
                        if (parsedResponse.ResponseStatusCode == 404)
                            this.alerts.push({ class: "alert-danger", message: parsedResponse.Data });
                        if (parsedResponse.ResponseStatusCode == 500)
                            this.alerts.push({ class: "alert-danger", message: parsedResponse.Data });
                        if (parsedResponse.ResponseStatusCode == 406)
                            this.alerts.push({ class: "alert-danger", message: parsedResponse.Data });

                        $(event.target).removeAttr("disabled");
                        $(event.target).text('Uložit');
                    })
            }            
        },      
        props: {
            applicantName: String,
            calculationName: String,
        }
    }
</script>

<style>
    .input-box {
        margin: 0 auto;
        max-width: 500px;
    }
</style>