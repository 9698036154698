<template>
    <div class="modal" id="filterModal" tabindex="-1">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ $t('credit.resultTable.buttons.discountsAndFees') }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div v-if="ratesFilters?.length === 1" class="modal-body">
                    <table v-if="windowWidth > 600" class="table">
                        <thead class="thead">
                            <tr>
                                <th>{{ $t('credit.discountsAndFeesModal.selectAll') }}</th>
                                <th>{{ $t('credit.discountsAndFeesModal.tradeDiscount') }}</th>
                                <th style="text-align: center;">{{ $t('credit.discountsAndFeesModal.monthlyCosts') }}</th>
                                <th style="text-align: center; min-width: 170px;">{{ $t('credit.discountsAndFeesModal.processingCosts') }}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody class="tbody"
                               v-for="(partner, index) in ratesFilters[0].items"
                               :key="index">
                            <tr>
                                <td>
                                    <label>
                                        <input type="checkbox" v-model="partner.vybranyPartner" />
                                        {{partner.nazev}}
                                    </label>
                                </td>
                                <td>
                                    <v-input class="hypo-input"
                                             v-model="partner.obchodniSleva"
                                             addonString="%"
                                             number="true"
                                             precision="2"
                                             minus="true" />
                                </td>
                                <td style="text-align: center;">
                                    <button :class="[ 'btn btn-default', { selected : partner.monthlyFilled }]"
                                            type="button"
                                            @click="showMonthlyCostsModal(0, index)">
                                        Zvolit poplatek
                                    </button>
                                </td>
                                <td style="text-align: center;">
                                    <button :class="[ 'btn btn-default', { selected : partner.processingFilled }]"
                                            type="button"
                                            @click="showProcessingCostsModal(0, index)">
                                        Zvolit poplatek
                                    </button>
                                </td>
                                <td v-if="partner.vlastnosti?.length > 0" @click="partner.vlastnostiViditelne = !partner.vlastnostiViditelne">
                                    <img v-if="partner.vlastnostiViditelne" class="heading-arrow" src="@/assets/icons/downFill.svg" />
                                    <img v-else class="heading-arrow" src="@/assets/icons/downFill.svg" />
                                </td>
                            </tr>
                            <tr v-if="partner.vlastnostiViditelne && partner.vlastnosti?.length > 0">
                                <td colspan="5" style="padding: 8px 40px;">
                                    <div class="checkbox-container-custom">
                                        <label v-for="(item, index) in partner.vlastnosti"
                                               :key="index"
                                               v-bPopover="item.tooltip">
                                            <input type="checkbox"
                                                   v-model="item.selected" />
                                            {{item.text}}
                                        </label>
                                    </div>
                                </td>

                            </tr>
                        </tbody>
                    </table>

                    <div v-else>
                        <div class="filter-item"
                             v-for="(partner, index) in ratesFilters[0].items"
                             :key="index">

                            <div>
                                <label>
                                    <input type="checkbox" v-model="partner.vybranyPartner" />
                                    {{partner.nazev}}
                                </label>
                            </div>

                            <v-labeled-input v-model="partner.obchodniSleva"
                                             :label="$t('credit.discountsAndFeesModal.tradeDiscount')"
                                             addonString="%"
                                             number="true"
                                             precision="2">
                            </v-labeled-input>
                            <div :class="['hypo-form-group']">
                                <label>{{$t('credit.discountsAndFeesModal.monthlyCosts')}}</label>
                                <button :class="[ 'btn btn-default', { selected : partner.monthlyFilled }]"
                                        type="button"
                                        @click="showMonthlyCostsModal(0, index)">
                                    {{ $t('credit.discountsAndFeesModal.fillCosts') }}
                                </button>
                            </div>
                            <div :class="['hypo-form-group']">
                                <label>{{$t('credit.discountsAndFeesModal.processingCosts')}}</label>
                                <button :class="[ 'btn btn-default', { selected : partner.processingFilled }]"
                                        type="button"
                                        @click="showProcessingCostsModal(0, index)">
                                    {{ $t('credit.discountsAndFeesModal.fillCosts') }}
                                </button>
                            </div>

                            <div v-if="partner.vlastnosti?.length > 0" @click="partner.vlastnostiViditelne = !partner.vlastnostiViditelne" class="offer-row-arrow-mobile">
                                <img v-if="partner.vlastnostiViditelne" class="heading-arrow" src="@/assets/icons/downFill.svg" />
                                <img v-else class="heading-arrow" src="@/assets/icons/downFill.svg" />
                            </div>

                            <div v-if="partner.vlastnostiViditelne && partner.vlastnosti?.length > 0" style="padding-top: 8px;">
                                <div class="checkbox-container-custom">
                                    <label v-for="(item, index) in partner.vlastnosti"
                                           :key="index"
                                           v-bPopover="item.tooltip">
                                        <input type="checkbox"
                                               v-model="item.selected" />
                                        {{item.text}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="modal-body">
                    <ul class="nav justify-content-center nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link active" @click="changeActiveWindow('jednotlive', $event);">{{ $t('credit.discountsAndFeesModal.specificVariants') }}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="changeActiveWindow('vsechny', $event);">{{ $t('credit.discountsAndFeesModal.allVariants') }}</a>
                        </li>
                    </ul>

                    <div v-if="activeWindow === 'jednotlive'">
                        <div v-for="(filterPartners, indexFilter) in ratesFilters" :key="indexFilter" class="panel hypo-panel panel-default panel-filter panel-rates-filter">
                            <div class="panel-heading" @click="toggleActiveWindow(indexFilter)">
                                <input type="checkbox" :id="indexFilter + 'chb'" v-model="filterPartners.filled" disabled="disabled" />
                                Varianta {{indexFilter + 1}}
                                <img class="group-reset"
                                     src="@/assets/icons/update-auto.svg"
                                     @click="resetFilterVariant(indexFilter)"
                                     v-bTooltip="$t('global.defaultValues')" />
                                <img v-if="filterPartners.active" class="heading-arrow" src="/CreditWorthiness/img/upFill.svg">
                                <img v-else class="heading-arrow" src="/CreditWorthiness/img/downFill.svg">
                            </div>
                            <div :class="[ 'panel-body collapse', { in : filterPartners.active }]">
                                <table v-if="windowWidth > 600" class="table">
                                    <thead class="thead">
                                        <tr>
                                            <th>{{ $t('credit.discountsAndFeesModal.selectAll') }}</th>
                                            <th>{{ $t('credit.discountsAndFeesModal.tradeDiscount') }}</th>
                                            <th style="text-align: center;">{{ $t('credit.discountsAndFeesModal.monthlyCosts') }}</th>
                                            <th style="text-align: center; min-width: 170px;">{{ $t('credit.discountsAndFeesModal.processingCosts') }}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody class="tbody"
                                           v-for="(partner, index) in filterPartners.items"
                                           :key="index">
                                        <tr>
                                            <td>
                                                <label>
                                                    <input type="checkbox" v-model="partner.vybranyPartner" />
                                                    {{partner.nazev}}
                                                </label>
                                            </td>
                                            <td>
                                                <v-input class="hypo-input"
                                                         v-model="partner.obchodniSleva"
                                                         addonString="%"
                                                         number="true"
                                                         precision="2"
                                                         minus="true" />
                                            </td>
                                            <td style="text-align: center;">
                                                <button :class="[ 'btn btn-default', { selected : partner.monthlyFilled }]"
                                                        type="button"
                                                        @click="showMonthlyCostsModal(indexFilter, index)">
                                                    {{ $t('credit.discountsAndFeesModal.fillCosts') }}
                                                </button>
                                            </td>
                                            <td style="text-align: center;">
                                                <button :class="[ 'btn btn-default', { selected : partner.processingFilled }]"
                                                        type="button"
                                                        @click="showProcessingCostsModal(indexFilter, index)">
                                                    {{ $t('credit.discountsAndFeesModal.fillCosts') }}
                                                </button>
                                            </td>
                                            <td v-if="partner.vlastnosti?.length > 0" @click="partner.vlastnostiViditelne = !partner.vlastnostiViditelne">
                                                <img v-if="partner.vlastnostiViditelne" class="heading-arrow" src="@/assets/icons/downFill.svg" />
                                                <img v-else class="heading-arrow" src="@/assets/icons/downFill.svg" />
                                            </td>
                                        </tr>
                                        <tr v-if="partner.vlastnostiViditelne && partner.vlastnosti?.length > 0">
                                            <td colspan="5" style="padding: 8px 40px;">
                                                <div class="checkbox-container-custom">
                                                    <label v-for="(item, index) in partner.vlastnosti"
                                                           :key="index"
                                                           v-bPopover="item.tooltip">
                                                        <input type="checkbox"
                                                               v-model="item.selected" />
                                                        {{item.text}}
                                                    </label>
                                                </div>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>

                                <div v-else>
                                    <div class="filter-item"
                                         v-for="(partner, index) in filterPartners.items"
                                         :key="index">

                                        <div>
                                            <label>
                                                <input type="checkbox" v-model="partner.vybranyPartner" />
                                                {{partner.nazev}}
                                            </label>
                                        </div>

                                        <v-labeled-input v-model="partner.obchodniSleva"
                                                         :label="$t('credit.discountsAndFeesModal.tradeDiscount')"
                                                         addonString="%"
                                                         number="true"
                                                         precision="2">
                                        </v-labeled-input>
                                        <div :class="['hypo-form-group']">
                                            <label>{{$t('credit.discountsAndFeesModal.monthlyCosts')}}</label>
                                            <button :class="[ 'btn btn-default', { selected : partner.monthlyFilled }]"
                                                    type="button"
                                                    @click="showMonthlyCostsModal(indexFilter, index)">
                                                {{ $t('credit.discountsAndFeesModal.fillCosts') }}
                                            </button>
                                        </div>
                                        <div :class="['hypo-form-group']">
                                            <label>{{$t('credit.discountsAndFeesModal.processingCosts')}}</label>
                                            <button :class="[ 'btn btn-default', { selected : partner.processingFilled }]"
                                                    type="button"
                                                    @click="showProcessingCostsModal(indexFilter, index)">
                                                {{ $t('credit.discountsAndFeesModal.fillCosts') }}
                                            </button>
                                        </div>

                                        <div v-if="partner.vlastnosti?.length > 0" @click="partner.vlastnostiViditelne = !partner.vlastnostiViditelne" class="offer-row-arrow-mobile">
                                            <img v-if="partner.vlastnostiViditelne" class="heading-arrow" src="@/assets/icons/downFill.svg" />
                                            <img v-else class="heading-arrow" src="@/assets/icons/downFill.svg" />
                                        </div>

                                        <div v-if="partner.vlastnostiViditelne && partner.vlastnosti?.length > 0" style="padding-top: 8px;">
                                            <div class="checkbox-container-custom">
                                                <label v-for="(item, index) in partner.vlastnosti"
                                                       :key="index"
                                                       v-bPopover="item.tooltip">
                                                    <input type="checkbox"
                                                           v-model="item.selected" />
                                                    {{item.text}}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div v-if="activeWindow === 'vsechny'">
                        <table v-if="windowWidth > 600" class="table">
                            <thead class="thead">
                                <tr>
                                    <th>{{ $t('credit.discountsAndFeesModal.selectAll') }}</th>
                                    <th>{{ $t('credit.discountsAndFeesModal.tradeDiscount') }}</th>
                                    <th style="text-align: center;">{{ $t('credit.discountsAndFeesModal.monthlyCosts') }}</th>
                                    <th style="text-align: center; min-width: 170px;">{{ $t('credit.discountsAndFeesModal.processingCosts') }}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody class="tbody"
                                   v-for="(partner, index) in ratesFilters[0].items"
                                   :key="index">
                                <tr>
                                    <td>
                                        <label>
                                            <input type="checkbox" v-model="partner.vybranyPartner" />
                                            {{partner.nazev}}
                                        </label>
                                    </td>
                                    <td>
                                        <v-input class="hypo-input"
                                                 v-model="partner.obchodniSleva"
                                                 addonString="%"
                                                 number="true"
                                                 precision="2"
                                                 minus="true" />
                                    </td>
                                    <td style="text-align: center;">
                                        <button :class="[ 'btn btn-default', { selected : partner.monthlyFilled }]"
                                                type="button"
                                                @click="showMonthlyCostsModal(0, index)">
                                            {{ $t('credit.discountsAndFeesModal.fillCosts') }}
                                        </button>
                                    </td>
                                    <td style="text-align: center;">
                                        <button :class="[ 'btn btn-default', { selected : partner.processingFilled }]"
                                                type="button"
                                                @click="showProcessingCostsModal(0, index)">
                                            {{ $t('credit.discountsAndFeesModal.fillCosts') }}
                                        </button>
                                    </td>
                                    <td v-if="partner.vlastnosti?.length > 0" @click="partner.vlastnostiViditelne = !partner.vlastnostiViditelne">
                                        <img v-if="partner.vlastnostiViditelne" class="heading-arrow" src="@/assets/icons/downFill.svg" />
                                        <img v-else class="heading-arrow" src="@/assets/icons/downFill.svg" />
                                    </td>
                                </tr>
                                <tr v-if="partner.vlastnostiViditelne && partner.vlastnosti?.length > 0">
                                    <td colspan="5" style="padding: 8px 40px;">
                                        <div class="checkbox-container-custom">
                                            <label v-for="(item, index) in partner.vlastnosti"
                                                   :key="index"
                                                   v-bPopover="item.tooltip">
                                                <input type="checkbox"
                                                       v-model="item.selected" />
                                                {{item.text}}
                                            </label>
                                        </div>
                                    </td>

                                </tr>
                            </tbody>
                        </table>

                        <div v-else>
                            <div class="filter-item"
                                 v-for="(partner, index) in ratesFilters[0].items"
                                 :key="index">

                                <div>
                                    <label>
                                        <input type="checkbox" v-model="partner.vybranyPartner" />
                                        {{partner.nazev}}
                                    </label>
                                </div>

                                <v-labeled-input v-model="partner.obchodniSleva"
                                                 :label="$t('credit.discountsAndFeesModal.tradeDiscount')"
                                                 addonString="%"
                                                 number="true"
                                                 precision="2">
                                </v-labeled-input>
                                <div :class="['hypo-form-group']">
                                    <label>{{$t('credit.discountsAndFeesModal.monthlyCosts')}}</label>
                                    <button :class="[ 'btn btn-default', { selected : partner.monthlyFilled }]"
                                            type="button"
                                            @click="showMonthlyCostsModal(0, index)">
                                        {{ $t('credit.discountsAndFeesModal.fillCosts') }}
                                    </button>
                                </div>
                                <div :class="['hypo-form-group']">
                                    <label>{{$t('credit.discountsAndFeesModal.processingCosts')}}</label>
                                    <button :class="[ 'btn btn-default', { selected : partner.processingFilled }]"
                                            type="button"
                                            @click="showProcessingCostsModal(0, index)">
                                        {{ $t('credit.discountsAndFeesModal.fillCosts') }}
                                    </button>
                                </div>

                                <div v-if="partner.vlastnosti?.length > 0" @click="partner.vlastnostiViditelne = !partner.vlastnostiViditelne" class="offer-row-arrow-mobile">
                                    <img v-if="partner.vlastnostiViditelne" class="heading-arrow" src="@/assets/icons/downFill.svg" />
                                    <img v-else class="heading-arrow" src="@/assets/icons/downFill.svg" />
                                </div>

                                <div v-if="partner.vlastnostiViditelne && partner.vlastnosti?.length > 0" style="padding-top: 8px;">
                                    <div class="checkbox-container-custom">
                                        <label v-for="(item, index) in partner.vlastnosti"
                                               :key="index"
                                               v-bPopover="item.tooltip">
                                            <input type="checkbox"
                                                   v-model="item.selected" />
                                            {{item.text}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button"
                            class="btn btn-default pull-left"
                            data-dismiss="modal">
                        <img class="left" src="@/assets/icons/X.svg" />{{ $t('global.cancel') }}
                    </button>
                    <button type="button"
                            class="btn btn-default"
                            @click="resetFilter">
                        <img class="pencil-reset left" src="@/assets/icons/update-auto.svg" />{{ $t('global.setDefault') }}
                    </button>
                    <button type="button"
                            class="btn btn-primary"
                            data-dismiss="modal"
                            @click="saveChanges">
                        <img class="left" src="@/assets/icons/savesmall.svg" />{{ $t('global.saveAndCount') }}
                    </button>
                </div>
            </div>
        </div>

        <div class="modal fade" id="filterModalZpracovani" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">{{ $t('credit.discountsAndFeesModal.processingCosts') }}</h4>
                        <button type="button" class="close" @click="hidefilterModalZpracovani" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <v-labeled-input v-model="ratesFilters[editingVariantId].items[editingPartnerId].nakladyNaZpracovaniRozpis.poplatekZaOdhad"
                                         :label="$t('credit.discountsAndFeesModal.poplatekZaOdhad')"
                                         :addonString="$t('global.currencySymbol')">
                        </v-labeled-input>
                        <v-labeled-input v-model="ratesFilters[editingVariantId].items[editingPartnerId].nakladyNaZpracovaniRozpis.poplatekZaZpracovani"
                                         :label="$t('credit.discountsAndFeesModal.poplatekZaZpracovani')"
                                         :addonString="$t('global.currencySymbol')">
                        </v-labeled-input>
                        <v-labeled-input v-model="ratesFilters[editingVariantId].items[editingPartnerId].nakladyNaZpracovaniRozpis.poplatekZaCerpani"
                                         :label="$t('credit.discountsAndFeesModal.poplatekZaCerpani')"
                                         :addonString="$t('global.currencySymbol')">
                        </v-labeled-input>
                        <v-labeled-input v-model="ratesFilters[editingVariantId].items[editingPartnerId].nakladyNaZpracovaniRozpis.poplatekZaCerpaniZS"
                                         :label="$t('credit.discountsAndFeesModal.poplatekZaCerpaniZS')"
                                         :addonString="$t('global.currencySymbol')">
                        </v-labeled-input>
                        <v-labeled-input v-model="ratesFilters[editingVariantId].items[editingPartnerId].nakladyNaZpracovaniRozpis.jine"
                                         :label="$t('credit.discountsAndFeesModal.other')"
                                         :addonString="$t('global.currencySymbol')">
                        </v-labeled-input>
                    </div>

                    <div class="modal-footer">
                        <button type="button"
                                class="btn btn-default"
                                @click="resetVariantProcessingCosts">
                            <img class="pencil-reset left" src="@/assets/icons/update-auto.svg" />{{ $t('global.setDefault') }}
                        </button>
                        <button type="button"
                                class="btn btn-primary"
                                @click="hidefilterModalZpracovani">
                            <img class="left" src="@/assets/icons/savesmall.svg" />{{ $t('global.save') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="filterModalMesicni" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">{{ $t('credit.discountsAndFeesModal.monthlyCosts') }}</h4>
                        <button type="button" class="close" @click="hidefilterModalMesicni" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <v-labeled-input v-model="ratesFilters[editingVariantId].items[editingPartnerId].mesicniNakladyRozpis.poplatekZaSpravu"
                                         :label="$t('credit.discountsAndFeesModal.poplatekZaSpravu')"
                                         :addonString="$t('global.currencySymbol')">
                        </v-labeled-input>
                        <v-labeled-input v-model="ratesFilters[editingVariantId].items[editingPartnerId].mesicniNakladyRozpis.poplatekZaVedeni"
                                         :label="$t('credit.discountsAndFeesModal.poplatekZaVedeni')"
                                         :addonString="$t('global.currencySymbol')">
                        </v-labeled-input>
                        <v-labeled-input v-model="ratesFilters[editingVariantId].items[editingPartnerId].mesicniNakladyRozpis.uverovePojisteni"
                                         :label="$t('credit.discountsAndFeesModal.uverovePojisteni')"
                                         :addonString="$t('global.currencySymbol')">
                        </v-labeled-input>
                        <v-labeled-input v-model="ratesFilters[editingVariantId].items[editingPartnerId].mesicniNakladyRozpis.pojisteniNemovitosti"
                                         :label="$t('credit.discountsAndFeesModal.pojisteniNemovitosti')"
                                         :addonString="$t('global.currencySymbol')">
                        </v-labeled-input>
                        <v-labeled-input v-model="ratesFilters[editingVariantId].items[editingPartnerId].mesicniNakladyRozpis.jine"
                                         :label="$t('credit.discountsAndFeesModal.other')"
                                         :addonString="$t('global.currencySymbol')">
                        </v-labeled-input>
                    </div>

                    <div class="modal-footer">
                        <button type="button"
                                class="btn btn-default"
                                @click="resetVariantMonthlyCosts">
                            <img class="pencil-reset left" src="@/assets/icons/update-auto.svg" />{{ $t('global.setDefault') }}
                        </button>
                        <button type="button"
                                class="btn btn-primary"
                                @click="hidefilterModalMesicni">
                            <img class="left" src="@/assets/icons/savesmall.svg" />{{ $t('global.save') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import Input from '@/components/Input.vue'
    import LabeledInput from '@/components/LabeledInput.vue'
    import { DefaultFilterPartners, FilterIsSelected, DefaultMesicniNakladyRozpis, DefaultNakladyNaZpracovaniRozpis } from '../js/HouseholdHelpers.mjs'

    export default {
        name: 'v-rates-filter-modal',
        components: {
            "v-input": Input,
            "v-labeled-input": LabeledInput
        },
        data() {
            return {
                windowWidth: 0,
                activeWindow: 'jednotlive',
                editingVariantId: 0,
                editingPartnerId: 0,
            }
        },
        watch: {
            ratesFilters: {
                handler() {
                    for (var i = 0; i < this.ratesFilters.length; i++) {
                        this.ratesFilters[i].filled = FilterIsSelected(this.ratesFilters[i].items, this.localization);
                        for (var t = 0; t < this.ratesFilters[i].items.length; t++) {
                            this.ratesFilters[i].items[t].monthlyFilled = this.monthlyIsFilled(this.ratesFilters[i].items[t].mesicniNakladyRozpis)
                            this.ratesFilters[i].items[t].processingFilled = this.processingIsFilled(this.ratesFilters[i].items[t].nakladyNaZpracovaniRozpis)
                        }
                    }
                },
                deep: true
            },
        },
        methods: {
            saveChanges() {
                if (this.activeWindow === 'vsechny') {
                    for (var i = 0; i < this.ratesFilters.length; i++) {
                        this.ratesFilters[i] = this.ratesFilters[0]
                    }
                }
                this.$emit('saveChanges');
            },
            resetFilter() {
                var certiDefault = this.localization == 'cs' ? false : true;
                for (var i = 0; i < this.ratesFilters.length; i++) {
                    DefaultFilterPartners(this.ratesFilters[i].items, certiDefault);
                }
            },
            resetFilterVariant(index) {
                var certiDefault = this.localization == 'cs' ? false : true;
                DefaultFilterPartners(this.ratesFilters[index].items, certiDefault);
            },
            resetVariantMonthlyCosts() {
                this.ratesFilters[this.editingVariantId].items[this.editingPartnerId].mesicniNakladyRozpis = DefaultMesicniNakladyRozpis();
            },
            resetVariantProcessingCosts() {
                this.ratesFilters[this.editingVariantId].items[this.editingPartnerId].nakladyNaZpracovaniRozpis = DefaultNakladyNaZpracovaniRozpis();
            },
            windowResized() {
                this.windowWidth = window.innerWidth;
            },
            toggleActiveWindow(index) {
                this.ratesFilters[index].active = !this.ratesFilters[index].active;
            },
            showMonthlyCostsModal(indexVariant, indexPartner) {
                this.editingVariantId = indexVariant;
                this.editingPartnerId = indexPartner;
                $('#filterModalMesicni').modal('show');
            },
            
            showProcessingCostsModal(indexVariant, indexPartner) {
                this.editingVariantId = indexVariant;
                this.editingPartnerId = indexPartner;
                $('#filterModalZpracovani').modal('show');
            },
            hidefilterModalMesicni() {
                $('#filterModalMesicni').modal('hide');
            },
            hidefilterModalZpracovani() {
                $('#filterModalZpracovani').modal('hide');
            },
            changeActiveWindow(windowName, e) {
                this.activeWindow = windowName;
                $('.nav-link').removeClass('active');
                e.target.setAttribute("class", "nav-link active");
            },
            monthlyIsFilled(item) {
                if (item.poplatekZaSpravu != null) return true;
                if (item.poplatekZaVedeni != null) return true;
                if (item.uverovePojisteni != null) return true;
                if (item.pojisteniNemovitosti != null) return true;
                if (item.jine != null) return true;
                return false;
            },
            processingIsFilled(item) {
                if (item.poplatekZaOdhad != null) return true;
                if (item.poplatekZaZpracovani != null) return true;
                if (item.poplatekZaCerpani != null) return true;
                if (item.poplatekZaCerpaniZS != null) return true;
                if (item.jine != null) return true;
                return false;
            }
        },
        computed: {

        },
        props: {
            ratesFilters: Array,
            localization: String
        },
        mounted: function () {
            this.windowWidth = window.innerWidth;
            window.addEventListener("resize", this.windowResized);
        }
    }
</script>
<style scoped>
    .modal-body table tr td {
        border: none;
    }

    @media(min-width: 992px) {
        table tr th:first-child {
            min-width: 250px;
        }
    }

    .nav {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        justify-content: center;
    }

    .nav-tabs .nav-item {
        margin-bottom: -1px;
    }

    .nav-tabs li .nav-link {
        margin-right: 5px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background-color: #fff;
        color: #333;
        font-weight: 500;
        border: 1px solid #dee2e6;
        cursor: pointer;
    }

        .nav-tabs li .nav-link.active {
            background-color: #2a77d1;
            color: #fff;
            font-weight: 500;
            border-bottom: 6px solid #2a77d1;
        }


    .panel-rates-filter {
        padding: 0px;
        margin-top: 5px;
    }

    #filterModalMesicni, #filterModalZpracovani {
        top: auto;
        padding-bottom: 160px;
    }
</style>

