<template>
    <div>
        <div v-if="windowWidth >= 1200">
            <table :class="[ 'table table-hypo rates', { 'border-spaced' : selectedOnly }]">
                <thead :class="[{ collapsed : variantIndex !== 0 }]">
                    <tr>
                        <v-table-header-with-sort :headerValue="$t('credit.resultTable.provider')"
                                                  width="75px"
                                                  :sortASC="sortProperty === sortProperties.partner"
                                                  :selected="sortIsSelected(sortProperties.partner)"
                                                  @headerClicked="changeSortProperty(sortProperties.partner)">
                        </v-table-header-with-sort>
                        <v-table-header-with-sort :headerValue="$t('credit.resultTable.rate')"
                                                  width="50px"
                                                  :sortASC="sortProperty === sortProperties.rate"
                                                  :selected="sortIsSelected(sortProperties.rate)"
                                                  @headerClicked="changeSortProperty(sortProperties.rate)">
                        </v-table-header-with-sort>
                        <v-table-header-with-sort :headerValue="$t('credit.resultTable.fixation')"
                                                  width="40px"
                                                  :sortASC="sortProperty === sortProperties.fixation"
                                                  :selected="sortIsSelected(sortProperties.fixation)"
                                                  @headerClicked="changeSortProperty(sortProperties.fixation)"
                                                  :tooltip="$t('tooltip.credit.resultTable.fixation')">
                        </v-table-header-with-sort>
                        <v-table-header-with-sort :headerValue="$t('credit.resultTable.maturity')"
                                                  width="57px"
                                                  :sortASC="sortProperty === sortProperties.maturity"
                                                  :selected="sortIsSelected(sortProperties.maturity)"
                                                  @headerClicked="changeSortProperty(sortProperties.maturity)">
                        </v-table-header-with-sort>
                        <v-table-header-with-sort :headerValue="$t('credit.resultTable.payment')"
                                                  width="45px"
                                                  :sortASC="sortProperty === sortProperties.installment"
                                                  :selected="sortIsSelected(sortProperties.installment)"
                                                  @headerClicked="changeSortProperty(sortProperties.installment)">
                        </v-table-header-with-sort>
                        <v-table-header-with-sort :headerValue="$t('credit.resultTable.monthlyExpenses')"
                                                  width="47px"
                                                  :sortASC="sortProperty === sortProperties.monthlyExpense"
                                                  :selected="sortIsSelected(sortProperties.monthlyExpense)"
                                                  @headerClicked="changeSortProperty(sortProperties.monthlyExpense)"
                                                  :tooltip="$t('tooltip.credit.resultTable.mothlyExpenses')">
                        </v-table-header-with-sort>
                        <v-table-header-with-sort :headerValue="$t('credit.resultTable.processingCosts')"
                                                  width="70px"
                                                  :sortASC="sortProperty === sortProperties.processingCosts"
                                                  :selected="sortIsSelected(sortProperties.processingCosts)"
                                                  @headerClicked="changeSortProperty(sortProperties.processingCosts)"
                                                  :tooltip="$t('tooltip.credit.resultTable.processingCosts')">
                        </v-table-header-with-sort>
                        <v-table-header-with-sort v-if="localization == 'cs'"
                                                  :headerValue="$t('credit.resultTable.fixCostsPerYear')"
                                                  width="71px"
                                                  :sortASC="sortProperty === sortProperties.fixCostsPerYear"
                                                  :selected="sortIsSelected(sortProperties.fixCostsPerYear)"
                                                  @headerClicked="changeSortProperty(sortProperties.fixCostsPerYear)"
                                                  :tooltip="$t('tooltip.credit.resultTable.financialCostPerYear')">
                        </v-table-header-with-sort>
                        <v-table-header-with-sort :headerValue="$t('credit.resultTable.rpsn')"
                                                  width="35px"
                                                  :sortASC="sortProperty === sortProperties.rpsn"
                                                  @headerClicked="changeSortProperty(sortProperties.rpsn)"
                                                  :selected="sortIsSelected(sortProperties.rpsn)"
                                                  :tooltip="$t('tooltip.credit.resultTable.rpsn')">
                        </v-table-header-with-sort>
                        <v-table-header-with-sort v-if="localization == 'sk'"
                                                  headerValue="Zostatok istiny úveru"
                                                  width="71px"
                                                  :sortASC="sortProperty === sortProperties.balanceAfterFixation"
                                                  :selected="sortIsSelected(sortProperties.balanceAfterFixation)"
                                                  @headerClicked="changeSortProperty(sortProperties.balanceAfterFixation)"
                                                  :tooltip="'Zostatok istiny úveru pri výročí fixácie'">
                        </v-table-header-with-sort>
                        <v-table-header-with-sort :headerValue="$t('credit.resultTable.maxAmountPerBonita')"
                                                  width="78px"
                                                  :sortASC="sortProperty === sortProperties.bonitaMaxAmount"
                                                  :selected="sortIsSelected(sortProperties.bonitaMaxAmount)"
                                                  @headerClicked="changeSortProperty(sortProperties.bonitaMaxAmount)">
                        </v-table-header-with-sort>
                        <v-table-header-with-sort :headerValue="$t('credit.resultTable.properties')"
                                                  width="60px"
                                                  :sortASC="sortProperty === sortProperties.properties"
                                                  :selected="sortIsSelected(sortProperties.properties)"
                                                  @headerClicked="changeSortProperty(sortProperties.properties)"
                                                  :tooltip="$t('tooltip.credit.resultTable.properties')">
                        </v-table-header-with-sort>
                        <th style="padding-left: 0px; padding-right: 15px">
                            <button type="button"
                                    id="btnSelectAll"
                                    class="btn btn-default-gray"
                                    @click="selectAll">
                                <img class="left" src="@/assets/icons/select-document.svg" />{{ $t('credit.resultTable.buttons.chooseAll') }}
                            </button>
                        </th>
                        <th></th>
                    </tr>
                </thead>

                <tbody v-if="showVariant">
                    <tr class="filterListRow">
                        <td class="filterListCellVariant" colspan="13">
                            <span>Varianta {{variantIndex + 1}} <span class="desc">{{$t('credit.app.loanAmount')}}: <vue-numeric :currency="$t('global.currencySymbol')" currency-symbol-position="suffix" separator="space" read-only="true" v-model="loanParams.loanAmount"></vue-numeric> | LTV: {{getHypoLtv(loanParams) * 100}} %</span></span>
                        </td>
                    </tr>
                </tbody>

                <tbody v-if="resultItems.length <= 0 && calcInProgress" class="data-loading">
                    <tr>
                        <td colspan="13"><i class="fas fa-spinner fa-spin"></i> {{ $t('credit.resultTable.dataLoading') }}</td>
                    </tr>

                </tbody>
                <tbody v-if="resultItems.length <= 0 && !calcInProgress" class="no-data">
                    <tr>
                        <td colspan="13">{{ $t('credit.resultTable.noResults') }}</td>
                    </tr>
                </tbody>

                <template v-if="methodologyFilterActive">
                    <template v-if="showYes">
                        <tbody><tr class="filterListRow success"><td class="filterListCell" colspan="13">{{$t('credit.app.validationYes')}}</td></tr></tbody>
                        <tbody v-for="(group, index) in filterItemsYes" :key="index"
                               :class="{ clearBefore : selectedOnly }">
                            <v-result-table-item v-for="(item, index) in group" :key="item.Id"
                                                 :item="item"
                                                 :loanParams="loanParams"
                                                 :isFirstInGroup="index === 0"
                                                 :groupHasAlternatives="group.length > 1"
                                                 :selectedOnly="selectedOnly"
                                                 :expandResults="expandResults"
                                                 :sortProperties="sortProperties"
                                                 :sortProperty="sortProperty"
                                                 :dorisMortgagesAllowed="dorisMortgagesAllowed"
                                                 :dorisLinkTarget="dorisLinkTarget"
                                                 :localization="localization"
                                                 @toggleSelectClicked="toggleSelectClicked(item)"
                                                 @showRateEdit="$emit('showRateEdit')">
                            </v-result-table-item>
                        </tbody>
                    </template>
                    <template v-if="showIndividually">
                        <tbody><tr class="filterListRow individual"><td class="filterListCell" colspan="13">{{$t('credit.app.validationIndividial')}}</td></tr></tbody>
                        <tbody v-for="(group, index) in filterItemsIndividually" :key="index"
                               :class="{ clearBefore : selectedOnly }">
                            <v-result-table-item v-for="(item, index) in group" :key="item.Id"
                                                 :item="item"
                                                 :loanParams="loanParams"
                                                 :isFirstInGroup="index === 0"
                                                 :groupHasAlternatives="group.length > 1"
                                                 :selectedOnly="selectedOnly"
                                                 :expandResults="expandResults"
                                                 :sortProperties="sortProperties"
                                                 :sortProperty="sortProperty"
                                                 :dorisMortgagesAllowed="dorisMortgagesAllowed"
                                                 :dorisLinkTarget="dorisLinkTarget"
                                                 :localization="localization"
                                                 @toggleSelectClicked="toggleSelectClicked(item)"
                                                 @showRateEdit="$emit('showRateEdit')">
                            </v-result-table-item>
                        </tbody>
                    </template>
                    <template v-if="showNo">
                        <tbody><tr class="filterListRow failure"><td class="filterListCell" colspan="13">{{$t('credit.app.validationNo')}}</td></tr></tbody>
                        <tbody v-for="(group, index) in filterItemsNo" :key="index"
                               :class="{ clearBefore : selectedOnly }">
                            <v-result-table-item v-for="(item, index) in group" :key="item.Id"
                                                 :item="item"
                                                 :loanParams="loanParams"
                                                 :isFirstInGroup="index === 0"
                                                 :groupHasAlternatives="group.length > 1"
                                                 :selectedOnly="selectedOnly"
                                                 :expandResults="expandResults"
                                                 :sortProperties="sortProperties"
                                                 :sortProperty="sortProperty"
                                                 :dorisMortgagesAllowed="dorisMortgagesAllowed"
                                                 :dorisLinkTarget="dorisLinkTarget"
                                                 :localization="localization"
                                                 @toggleSelectClicked="toggleSelectClicked(item)"
                                                 @showRateEdit="$emit('showRateEdit')">
                            </v-result-table-item>
                        </tbody>
                    </template>
                </template>

                <tbody v-else v-for="(group, index) in groupedAndSortedResultItems" :key="index"
                       :class="{ clearBefore : selectedOnly }">
                    <v-result-table-item v-for="(item, index) in group" :key="item.Id"
                                         :item="item"
                                         :loanParams="loanParams"
                                         :isFirstInGroup="index === 0"
                                         :groupHasAlternatives="group.length > 1"
                                         :selectedOnly="selectedOnly"
                                         :expandResults="expandResults"
                                         :sortProperties="sortProperties"
                                         :sortProperty="sortProperty"
                                         :dorisMortgagesAllowed="dorisMortgagesAllowed"
                                         :dorisLinkTarget="dorisLinkTarget"
                                         :localization="localization"
                                         @toggleSelectClicked="toggleSelectClicked(item)"
                                         @showRateEdit="$emit('showRateEdit')">
                    </v-result-table-item>
                </tbody>
            </table>

            <div v-if="variantIndex === maxIndex" class="table-info">
                <span class="badge" style="background-color: rgb(181 211 249);">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                {{ $t('credit.resultTable.explanatios.condition') }}
                <span class="badge" style="background-color: rgb(255, 175, 204);">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                {{ $t('credit.resultTable.explanatios.discount') }}
                <span class="badge" style="background-color: rgb(202, 202, 202);">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                {{ $t('credit.resultTable.explanatios.methodology') }}
            </div>
            <div v-if="showSkInfo" class="skInfoText">
                <span> {{ $t('credit.app.skInfoText') }} <a href="https://nbs.sk/financna-stabilita/nastroje-fs/">Nástroje finančnej stability - Národná banka Slovenska (nbs.sk)</a></span>
            </div>
        </div>
        <div v-if="windowWidth < 1200" style="padding-top: 10px;">
            <div v-if="showVariant" class="filterListDivVariant">
                <span>Varianta {{variantIndex + 1}} <span class="desc">{{$t('credit.app.loanAmount')}}: <vue-numeric :currency="$t('global.currencySymbol')" currency-symbol-position="suffix" separator="space" read-only="true" v-model="loanParams.loanAmount"></vue-numeric> | LTV: {{getHypoLtv(loanParams) * 100}} %</span></span>
            </div>

            <div v-if="resultItems.length <= 0 && calcInProgress" class="data-loading">
                <i class="fas fa-spinner fa-spin"></i> {{ $t('credit.resultTable.dataLoading') }}
            </div>
            <div v-if="resultItems.length <= 0 && !calcInProgress" class="no-data">
                {{ $t('credit.resultTable.noResults') }}
            </div>

            <template v-if="methodologyFilterActive">
                <template v-if="showYes">
                    <div class="offer-group">
                        <div class="offer-mobile active filter success">{{$t('credit.app.validationYes')}}</div>
                    </div>
                    <div v-for="(group, index) in filterItemsYes" :key="index" class="offer-group">
                        <v-result-table-item-mobile v-for="(item, index) in group" :key="item.Id"
                                                    :item="item"
                                                    :loanParams="loanParams"
                                                    :isFirstInGroup="index === 0"
                                                    :groupHasAlternatives="group.length > 1"
                                                    :selectedOnly="selectedOnly"
                                                    :expandResults="expandResults"
                                                    :sortProperties="sortProperties"
                                                    :sortProperty="sortProperty"
                                                    :dorisMortgagesAllowed="dorisMortgagesAllowed"
                                                    :dorisLinkTarget="dorisLinkTarget"
                                                    :localization="localization"
                                                    @toggleSelectClicked="toggleSelectClicked(item)"
                                                    @showRateEdit="$emit('showRateEdit')">
                        </v-result-table-item-mobile>
                    </div>
                </template>
                <template v-if="showIndividually">
                    <div class="offer-group">
                        <div class="offer-mobile active filter individual">{{$t('credit.app.validationIndividial')}}</div>
                    </div>
                    <div v-for="(group, index) in filterItemsIndividually" :key="index" class="offer-group">
                        <v-result-table-item-mobile v-for="(item, index) in group" :key="item.Id"
                                                    :item="item"
                                                    :loanParams="loanParams"
                                                    :isFirstInGroup="index === 0"
                                                    :groupHasAlternatives="group.length > 1"
                                                    :selectedOnly="selectedOnly"
                                                    :expandResults="expandResults"
                                                    :sortProperties="sortProperties"
                                                    :sortProperty="sortProperty"
                                                    :dorisMortgagesAllowed="dorisMortgagesAllowed"
                                                    :dorisLinkTarget="dorisLinkTarget"
                                                    :localization="localization"
                                                    @toggleSelectClicked="toggleSelectClicked(item)"
                                                    @showRateEdit="$emit('showRateEdit')">
                        </v-result-table-item-mobile>
                    </div>
                </template>
                <template v-if="showNo">
                    <div class="offer-group">
                        <div class="offer-mobile active filter failure">{{$t('credit.app.validationNo')}}</div>
                    </div>
                    <div v-for="(group, index) in filterItemsNo" :key="index" class="offer-group">
                        <v-result-table-item-mobile v-for="(item, index) in group" :key="item.Id"
                                                    :item="item"
                                                    :loanParams="loanParams"
                                                    :isFirstInGroup="index === 0"
                                                    :groupHasAlternatives="group.length > 1"
                                                    :selectedOnly="selectedOnly"
                                                    :expandResults="expandResults"
                                                    :sortProperties="sortProperties"
                                                    :sortProperty="sortProperty"
                                                    :dorisMortgagesAllowed="dorisMortgagesAllowed"
                                                    :dorisLinkTarget="dorisLinkTarget"
                                                    :localization="localization"
                                                    @toggleSelectClicked="toggleSelectClicked(item)"
                                                    @showRateEdit="$emit('showRateEdit')">
                        </v-result-table-item-mobile>
                    </div>
                </template>
            </template>
            <div v-else v-for="(group, index) in groupedAndSortedResultItems" :key="index" class="offer-group">
                <v-result-table-item-mobile v-for="(item, index) in group" :key="item.Id"
                                            :item="item"
                                            :loanParams="loanParams"
                                            :isFirstInGroup="index === 0"
                                            :groupHasAlternatives="group.length > 1"
                                            :selectedOnly="selectedOnly"
                                            :expandResults="expandResults"
                                            :sortProperties="sortProperties"
                                            :sortProperty="sortProperty"
                                            :dorisMortgagesAllowed="dorisMortgagesAllowed"
                                            :dorisLinkTarget="dorisLinkTarget"
                                            :localization="localization"
                                            @toggleSelectClicked="toggleSelectClicked(item)"
                                            @showRateEdit="$emit('showRateEdit')">
                </v-result-table-item-mobile>

            </div>
            <div v-if="showSkInfo">
                <span> {{ $t('credit.app.skInfoText') }} <a href="https://nbs.sk/financna-stabilita/nastroje-fs/">Nástroje finančnej stability - Národná banka Slovenska (nbs.sk)</a></span>
            </div>
        </div>
    </div>

</template>

<script>

    import ResultTableItem from './ResultTableItem.vue';
    import ResultTableItemMobile from './ResultTableItemMobile.vue';
    import TableHeaderWithSort from '../table/TableHeaderWithSort.vue'

    export default {
        name: 'v-rates-result-table',
        data() {
            return {
                sortProperty: 'Rate',
                sortProperties: {
                    partner: 'PartnerCode',
                    rate: 'Rate',
                    fixation: 'Fixation',
                    maturity: 'Maturity',
                    installment: 'Installment',
                    monthlyExpense: 'MonthlyCosts',
                    processingCosts: 'ProcessingCosts',
                    fixCostsPerYear: 'FixCostsPerYear',
                    balanceAfterFixation: 'BalanceAfterFixation',
                    rpsn: 'RPSN',
                    properties: 'Properties',
                    bonitaMaxAmount: 'MaxAmount'
                },
                windowWidth: 0,
                filterItemsYes: [],
                filterItemsIndividually: [],
                filterItemsNo: []
            }
        },
        props: {
            resultItems: Array,
            loanParams: Object,
            printButtonVisible: Boolean,
            printToUrlButtonVisible: Boolean,
            printToUrlDataExtendedButtonVisible: Boolean,
            calcInProgress: Boolean,
            selectedOnly: Boolean,
            expandResults: Boolean,
            dorisMortgagesAllowed: Boolean,
            dorisLinkTarget: String,
            variantIndex: Number,
            maxIndex: Number,
            localization: String,
            methodologyFilterActive: Boolean,
            showSkInfo: Boolean
        },
        methods: {
            getHypoLtv(params) {
                const ltv = params.loanAmount / params.reinsurance;
                if (ltv > 1) return 1;
                return ltv.toFixed(2);
            },
            selectAll() {
                const shouldSelect = !$('#btnSelectAll').hasClass("selected");
                this.resultItems.forEach(d => {
                    d.Selected = shouldSelect;
                });
                $('#btnSelectAll').toggleClass("selected");
                this.$emit('selectAllShouldSelect', this.$event, shouldSelect);
            },
            toggleSelectClicked(item) {
                item.Selected = !item.Selected;
                $('#btnSelectAll').removeClass("selected");
            },
            selectAllFromSibling(shouldSelect) {
                this.resultItems.forEach(d => {
                    d.Selected = shouldSelect;
                });
            },
            sortIsSelected(prop) {
                return this.sortProperty.endsWith(prop);
            },
            changeSortProperty(property) {
                if (this.sortProperty === property || this.sortProperty.substring(1) === property)
                    this.sortProperty = this.toggleSortPropertySort(this.sortProperty);
                else
                    this.sortProperty = property;

            },
            toggleSortPropertySort(prop) {
                if (prop[0] === "-") return prop.substring(1);
                return '-' + prop;
            },
            toggleLimitlessResultVisibility: function (event) {
                this.axios.post("/WebServices/StoredCalculations.asmx/LimitlessResultVisibility", {
                    headers: { Accept: 'application/json' },
                    show: event.srcElement.checked
                })
                    .then(response => {
                        var parsedResponse = JSON.parse(response.data.d);
                        if (parsedResponse.ResponseStatusCode != 200) {
                            alert(parsedResponse.Data);
                        }
                    })
                    .catch(error => {
                        // eslint-disable-next-line
                        alert('Error ' + error.response.status + ' - ' + $t('error.couldNotConnectToBackend'));
                    });
            },
            dynamicSort: function (property) {
                var sortOrder = 1;
                if (property[0] === "-") {
                    sortOrder = -1;
                    property = property.substr(1);
                }
                return function (a, b) {
                    /* next line works with strings and numbers,
                     * and you may want to customize it to your needs
                     */
                    // Oberbank always last
                    if (a.PartnerCode === 'OB') return 1;
                    if (b.PartnerCode === 'OB') return -1;

                    const getNested = (obj, path) => path.split('.').reduce((d, prop) => {
                        if (!d) return 0;
                        return d[prop]
                    }, obj);
                    const aVal = getNested(a, property);
                    const bVal = getNested(b, property);
                    var result = (aVal < bVal) ? -1 : (aVal > bVal) ? 1 : 0;
                    return result * sortOrder;
                }
            },
            getSortProperty() {
                return this.sortProperty;
            },
            getSelectedOfferIdsForPrint(anyOfferSelected) {
                var ids = [];
                for (var key in this.groupedAndSortedResultItems) {
                    // skip loop if the property is from prototype
                    if (!Object.prototype.hasOwnProperty.call(this.groupedAndSortedResultItems, key)) continue;

                    var selected = this.selectItems(this.groupedAndSortedResultItems, key, anyOfferSelected);
                    selected.forEach(d => {
                        ids.push(d.Id)
                    });
                }
                return ids;
            },
            selectItems: function (obj, key, anyOfferSelected) {
                if (anyOfferSelected) {
                    return obj[key].filter(d => d.Selected);
                }
                else {
                    return new Array(obj[key][0]);
                }
            },
            handleBonita() {
                var self = this;
                let uncompleted = this.resultItems.filter(d => d.BonitaCalcState === "Loading");
                if (uncompleted.length) {
                    let guids = uncompleted.map(d => d.BonitaGuid);
                    $.ajax({
                        type: "POST",
                        dataType: 'json',
                        contentType: "application/json; charset=utf-8",
                        url: "/Default.aspx/RatesResultItemsNext",
                        data: "{ 'guids': " + JSON.stringify(guids) + " }"
                    }
                    ).done(function (data) {
                        var json = JSON.parse(data.d);
                        for (var i = 0; i < json.length; i++) {
                            var item = json[i];
                            if (item.Completed) {
                                let resItem = self.resultItems.find(d => d.BonitaGuid == item.Guid);
                                resItem.MaxAmount = item.MaxAmount;
                                resItem.BonitaCalcState = "Completed";
                                resItem.MaxAmountColor = item.Color;
                                resItem.MaxAmountDescription = item.InfoText;
                            }
                        }
                        setTimeout(function () { self.handleBonita() }, 1000);
                    });
                }
            },
            windowResized() {
                this.windowWidth = window.innerWidth;
            },
            applyFilter() {
                if (this.methodologyFilterActive) {
                    this.loadFilterLists();
                }
            },
            loadFilterLists() {
                this.filterItemsYes = this.groupAndSortItems(this.resultItems.filter((item) => item.PartnerPassedMethodology == 'Yes'));
                this.filterItemsIndividually = this.groupAndSortItems(this.resultItems.filter((item) => item.PartnerPassedMethodology == 'Individually'));
                this.filterItemsNo = this.groupAndSortItems(this.resultItems.filter((item) => item.PartnerPassedMethodology == 'No'));
            },
            groupAndSortItems(items) {
                if (items.length > 0) {
                    var sorted = items.slice().sort(this.dynamicSort(this.sortProperty));
                    if (!this.selectedOnly && !this.expandResults) {
                        var grouped = sorted.reduce((accum, val) => {
                            accum[val.PartnerCode] = accum[val.PartnerCode] || [];
                            accum[val.PartnerCode].push(val);
                            return accum;
                        }, {});
                        return grouped;
                    }
                    else {
                        var groupedOneByOne = sorted.map(function (d) {
                            return new Array(d);
                        });
                        return groupedOneByOne;
                    }
                }
                return undefined;
            },
            anySelected() {
                return this.resultItems.some(d => d.Selected === true);
            },
        },
        computed: {
            groupedAndSortedResultItems() {
                if (this.resultItems.length > 0) {
                    var sorted = this.resultItems.slice().sort(this.dynamicSort(this.sortProperty));
                    if (!this.selectedOnly && !this.expandResults) {
                        var grouped = sorted.reduce((accum, val) => {
                            accum[val.PartnerCode] = accum[val.PartnerCode] || [];
                            accum[val.PartnerCode].push(val);
                            return accum;
                        }, {});
                        return grouped;
                    }
                    else {
                        var groupedOneByOne = sorted.map(function (d) {
                            return new Array(d);
                        });
                        return groupedOneByOne;
                    }
                }
                return null;
            },
            showVariant() {
                if (this.maxIndex > 0) {
                    if (this.selectedOnly) {
                        let a = this.groupedAndSortedResultItems.some(d => d[0].Selected);
                        return a;
                    }
                    return true
                }
                return false;
            },
            showYes() {
                if (this.methodologyFilterActive) {
                    if (this.selectedOnly) {
                        for (const [key, value] of Object.entries(this.filterItemsYes)) {
                            if (key && value.some(d => d.Selected)) {
                                return true;
                            }
                        }
                        return false;
                    }
                    return this.filterItemsYes;
                }
                return false;
            }
            //item.PartnerPassedMethodology
            ,
            showIndividually() {
                if (this.methodologyFilterActive) {
                    if (this.selectedOnly) {
                        for (const [key, value] of Object.entries(this.filterItemsIndividually)) {
                            if (key && value.some(d => d.Selected)) {
                                return true;
                            }
                        }
                        return false;
                    }
                    return this.filterItemsIndividually;
                }
                return false;
            }
            ,
            showNo() {
                if (this.methodologyFilterActive) {
                    if (this.selectedOnly) {
                        for (const [key, value] of Object.entries(this.filterItemsNo)) {
                            if (key && value.some(d => d.Selected)) {
                                return true;
                            }
                        }
                        return false;
                    }
                    return this.filterItemsNo;
                }
                return false;
            }
        },
        watch: {
            resultItems: {
                handler() {
                    this.handleBonita();
                    this.applyFilter();
                },
                immediate: true,
            },
        },
        components: {
            'v-result-table-item': ResultTableItem,
            'v-result-table-item-mobile': ResultTableItemMobile,
            'v-table-header-with-sort': TableHeaderWithSort,
        },
        mounted() {
            this.windowWidth = window.innerWidth;
            window.addEventListener("resize", this.windowResized);

            this.axios.post("/WebServices/StoredCalculations.asmx/CalculationStorageAlowed", {
                headers: { Accept: 'application/json' }
            })
                .then(response => {
                    var parsedResponse = JSON.parse(response.data.d);

                    if (parsedResponse.ResponseStatusCode == 200) {
                        this.calculationStorageAlowed = parsedResponse.Data;
                    }
                });
        },
        unmounted() {
            window.removeEventListener("resize", this.windowResized);
        },
    }

</script>

<style scoped>
    .skInfoText {
        padding-top: 10px;
    }
</style>
