export function DefaultLoanParams(anchor) {
    if (anchor.localization === 'sk') {
        return {
            loanAmount: 80000,
            reinsurance: 100000,
            ratesIncome: 1000,
            maturity: 360,
            fixationRates: 60,
            ratesApplicantsCount: 1
        }
    }
    else {
        return {
            loanAmount: 3000000,
            reinsurance: 5000000,
            ratesIncome: 30000,
            maturity: 360,
            fixationRates: 60,
            ratesApplicantsCount: 1
        }
    }
}
export function InitializeBonitaLoanParamas(anchor) {
    if (anchor.localization == 'sk') {
        anchor.loanParams.rate = 5;
    }
    else {
        anchor.loanParams.rate = 5.5;
    }
    anchor.loanParams.refinMortgageInstallment = 0;
    anchor.loanParams.refinMortgageBalance = 0;
    anchor.loanParams.refinMortgageIncrease = 0;
    return;
}

export function InitializeRatesLoanParams(anchor) {
    anchor.loanParams = [];
    
    if (anchor.localization == 'sk') {
        anchor.loanParams.push({
            loanAmount: 80000,
            reinsurance: 100000,
            ratesIncome: 1000,
        });
    }
    else {
        anchor.loanParams.push({
            loanAmount: 3000000,
            reinsurance: 5000000,
            ratesIncome: 30000,
        });
    }
    anchor.loanParams[0].maturity = 360;
    anchor.loanParams[0].loanPurpose = 'Purchase';
    anchor.loanParams[0].fixationRates = 60;
    anchor.loanParams[0].propertyType = 'Apartment';

    anchor.loanParams[0].age = 30;

    var certiDefault = anchor.localization == 'cs' ? false : true;

    anchor.filter = DefaultFilter(anchor.filter, certiDefault)
    return;
}

export function InitializeHouseholds(anchor) {
    var firstHousehold = NewHousehold(anchor);
    anchor.households = [firstHousehold];
    anchor.currentHousehold = firstHousehold;
    return;
}

export function AddNewHousehold(anchor) {
    if (anchor.households.length >= 2) return;
    var hh = NewHousehold(anchor);
    anchor.households.push(hh);
    return hh;
}

export function RemoveHousehold(anchor, index) {
    if (anchor.households.length <= 1) return;
    anchor.households.splice(index, 1);
    return;
}

export function RemoveApplicant(householdAnchor, index) {
    if (householdAnchor.applicantPersons.length <= 1) return;
    householdAnchor.applicantPersons.splice(index, 1);
    return;
}

export function ResetApplicant(anchor) {
    var i18n = anchor.$i18n;
    anchor.applicant.name = i18n.t('credit.household.zadatel') + ' ' + (+anchor.applicant.id + 1);
    anchor.applicant.surename = '';
    anchor.applicant.vek = 30;
    anchor.applicant.sumOfAllLoanBalances = 0;
    anchor.applicant.incomes = DefaultIncomes(anchor.settings);
    anchor.applicant.expenses = DefaultExpenses(anchor.settings);
    anchor.applicant.activeSections = DefaultActiveSections();
    return;
}

export function ResetTSIncomes(anchor) {
    if (anchor.tsProvider === 'cz') {
        ResetTSIncomesCz(anchor);
    } else if (anchor.tsProvider === 'sk') {
        ResetTSIncomesSk(anchor);
    } else {
        throw "Unknown TS provider"
    }

    return;
}

function ResetTSIncomesCz(anchor) {
    anchor.applicant.incomes.lastTaxSettlementCz = DefaultLastTaxSettlementCz();
    anchor.applicant.incomes.previousTaxSettlementCz = DefaultPreviousTaxSettlementCz();
    anchor.applicant.incomes.prePreviousTaxSettlementCz = DefaultPrePreviousTaxSettlement();
    anchor.applicant.incomes.turnoverIncome = DefaultTurnoverIncome();
    anchor.applicant.activeSections.incomes.incomeTS = DefaultIncomeTSActiveSections();
}

function ResetTSIncomesSk(anchor) {
    anchor.applicant.incomes.lastTaxSettlementSk = DefaultLastTaxSettlementSk();
    anchor.applicant.activeSections.incomes.incomeTS = DefaultIncomeTSActiveSections();
     anchor.applicant.incomes.lastTaxSettlementSk = DefaultLastTaxSettlementSk();
     anchor.applicant.incomes.taxSettlementLegalEntitySk = DefaultTaxSettlementLegalEntitySk();
     anchor.applicant.activeSections.incomes.incomeTS = DefaultIncomeTSActiveSections();
}

export function ResetIncomesOther(anchor) {
    anchor.applicant.incomes.otherIncomesCz = DefaultOtherIncomesCz(anchor.settings);
    return;
}

export function ResetExpenses(anchor) {
    anchor.applicant.expenses = DefaultExpenses(anchor.settings);
    anchor.applicant.sumOfAllLoanBalances = 0;
    return;
}

export function RevertExpenses(anchor) {
    anchor.applicant.expenses = anchor.applicantBackup.expenses;
    anchor.applicant.sumOfAllLoanBalances = anchor.applicantBackup.sumOfAllLoanBalances;
    return;
}

export function ResetTSIncomesBusiness(anchor) {
    if (anchor.tsProvider === 'cz') {
        ResetTSIncomesBusinessCz(anchor);
    } else if (anchor.tsProvider === 'sk') {
        ResetTSIncomesBusinessSk(anchor);
    } else {
        throw "Unknown TS provider"
    }

    return;
}

export function ResetTSIncomesBusinessCz(anchor) {
    anchor.applicant.incomes.lastTaxSettlementCz.taxSettlementBusinessCz = DefaultTaxSettlementBusinessCz();
    anchor.applicant.incomes.previousTaxSettlementCz.taxSettlementBusinessCz = DefaultTaxSettlementBusinessCz();
    anchor.applicant.incomes.prePreviousTaxSettlementCz.taxSettlementBusinessCz = DefaultTaxSettlementBusinessCz();
    anchor.applicant.incomes.turnoverIncome = DefaultTurnoverIncome();
    anchor.applicant.activeSections.incomes.incomeTS.incomeCheckboxes.prijmyObrat = false;
    anchor.applicant.activeSections.incomes.incomeTS.activeTSs.business.previousTaxSettlementHidden = true;
    anchor.applicant.activeSections.incomes.incomeTS.activeTSs.business.prePreviousTaxSettlementHidden = true;
    anchor.applicant.activeSections.incomes.incomeTS.usingFlatRate.business.previousTaxSettlementFlatRate = true;
    anchor.applicant.activeSections.incomes.incomeTS.usingFlatRate.business.prePreviousTaxSettlementFlatRate = true;
    return;
}

export function ResetTSIncomesBusinessSk(anchor) {
    anchor.applicant.incomes.lastTaxSettlementSk.taxSettlementBusinessSk = DefaultTaxSettlementBusinessSk();
    return;
}

export function ResetTSIncomesBusinessLegalEntity(anchor) {
    anchor.applicant.incomes.taxSettlementLegalEntitySk = DefaultTaxSettlementLegalEntitySk();
}

export function ResetTSIncomesLease(anchor) {
    if (anchor.tsProvider === 'cz') {
        ResetTSIncomesLeaseCz(anchor)
    } else if (anchor.tsProvider === 'sk') {
        ResetTSIncomesLeaseSk(anchor)
    } else {
        throw "Unknown TS provider"
    }

    return;
}

function ResetTSIncomesLeaseCz(anchor) {
    anchor.applicant.incomes.lastTaxSettlementCz.taxSettlementLeaseCz = DefaultTaxSettlementLeaseCz();
    anchor.applicant.incomes.previousTaxSettlementCz.taxSettlementLeaseCz = DefaultTaxSettlementLeaseCz();
    anchor.applicant.incomes.prePreviousTaxSettlementCz.taxSettlementLeaseCz = DefaultTaxSettlementLeaseCz();
    anchor.applicant.activeSections.incomes.incomeTS.activeTSs.lease.previousTaxSettlementHidden = true;
    anchor.applicant.activeSections.incomes.incomeTS.activeTSs.lease.prePreviousTaxSettlementHidden = true;
}

function ResetTSIncomesLeaseSk(anchor) {
    anchor.applicant.incomes.lastTaxSettlementSk.taxSettlementLeaseSk = DefaultTaxSettlementLeaseSk();
}

export function ResetFlatRateTaxBusiness(anchor) {
    anchor.applicant.incomes.taxFlatRateCz.taxFlatRateBusinessCz = DefaultTaxFlatRateBusinessCz();
}

export function ResetFlatRateTaxLease(anchor) {
    anchor.applicant.incomes.taxFlatRateCz.taxFlatRateLeaseCz = DefaultTaxFlatRateLeaseCz();
}

export function ResetFlatRateTax(anchor) {
    anchor.applicant.incomes.taxFlatRateCz = DefaultTaxFlatRateCz();
    anchor.applicant.activeSections.incomes.incomeTaxFlatRate = DefaultIncomeTaxFlatRateActiveSections();
}

export function ResetHousehold(anchor, index) {
    anchor.households[index] = NewHousehold(anchor);
    anchor.currentHousehold = anchor.households[index];
    return;
}

function NewHousehold(anchor) {
    var hh = {
        id: 0,
        name: 'Domácnost',
        applicantPersons: [],
        maritalStatus: 'Single',
        region: anchor.regions[0].value,
        dospelyCount: 1,
        dite5minusCount: 0,
        dite6plusCount: 0,
        dite10plusCount: 0,
        dite16plusCount: 0
    };

    while (anchor.households.findIndex(x => x.id === hh.id) !== -1) {
        hh.id++;
    }
    hh.applicantPersons.push(NewApplicant(hh, anchor.vychoziVek, anchor.settings, anchor.$i18n))

    return hh;
}

export function AddNewApplicant(householdAnchor, settings, i18n) {
    if (householdAnchor.applicantPersons.length >= 2) return;
    var appl = NewApplicant(householdAnchor, null, settings, i18n);
    householdAnchor.applicantPersons.push(appl);
    return appl;
}

export function DefaultTaxSettlementLeaseCz() {
    return {
        leaseFlatRate: true,
        leaseIncome: 0,
        leaseTaxBase: 0
    };
}

export function DefaultTaxSettlementLeaseSk() {
    return {
        leaseTaxBase: 0,
        leaseIncome: 0
    };
}

export function DefaultTaxSettlementBusinessCz() {
    return {
        businessIncome: 0,
        businessTaxBase: 0,
        depreciation: 0,
        czNaceCz: 'CZ_Nace_0000',
        flatRateCz: 'FlatRate60',
        occupationCz: 'None',
        outcomeTypeCz: 'FlatRate',
        useDeclaredExpenses: false,
        declaredExpenses: 0
    };
}

export function DefaultTaxSettlementBusinessSk() {
    return {
        outcomeType: 'FlatRate60',
        occupation: 'None',
        businessIncome: 0,
        businessTaxBase: 0
    };
}

export function DefaultLastTaxSettlementCz() {
    return {
        taxSettlementLeaseCz: DefaultTaxSettlementLeaseCz(),
        taxSettlementBusinessCz: DefaultTaxSettlementBusinessCz(),

        taxCredit: 30840,
        nonTaxableIncomeBase: 0,
        childTaxReturn: 0,
        tax: 0
    };
}

export function DefaultLastTaxSettlementSk() {
    return {
        taxSettlementLeaseSk: DefaultTaxSettlementLeaseSk(),
        taxSettlementBusinessSk: DefaultTaxSettlementBusinessSk(),

        leaseIncome: 0,
        tax: 0,
        taxBase: 0
    };
}

export function DefaultPreviousTaxSettlementCz() {
    return {
        taxSettlementBusinessCz: DefaultTaxSettlementBusinessCz(),
        taxSettlementLeaseCz: DefaultTaxSettlementLeaseCz()
    };
}

export function DefaultPrePreviousTaxSettlement() {
    return {
        taxSettlementBusinessCz: DefaultTaxSettlementBusinessCz(),
        taxSettlementLeaseCz: DefaultTaxSettlementLeaseCz()
    };
}

export function DefaultTaxFlatRateCz() {
    return {
        taxFlatRateBusinessCz: DefaultTaxFlatRateBusinessCz(),
        taxFlatRateLeaseCz: DefaultTaxFlatRateLeaseCz()
    };
}

export function DefaultTaxSettlementLegalEntitySk() {
    return {
        taxSettlementBusinessSk: {
            occupation: 'None',
            businessIncome: 0,
            businessTaxBase: 0
        },

        tax: 0
    };
}

export function DefaultTaxFlatRateLeaseCz() {
    return {
        leaseFlatRate: true,
        leaseIncome: 0,
        leaseTaxBase: 0
    };
}

export function DefaultTaxFlatRateBusinessCz() {
    return {
        businessIncome: 0,
        czNaceCz: 'CZ_Nace_0000',
        flatRateCz: 'FlatRate60',
        closedTaxPeriod: 'Months12',
        creditTurnover1Month: 0,
        uncreditedAmount1Month: 0,
        creditTurnover2Month: 0,
        uncreditedAmount2Month: 0,
        creditTurnover3Month: 0,
        uncreditedAmount3Month: 0,
        creditTurnover12Months: 0,
        transactionsOneCustomer: false,
        creditTurnover4Month: 0,
        creditTurnover5Month: 0,
        creditTurnover6Month: 0
    };
}

export function DefaultTurnoverIncome() {
    return {
        creditTurnover1Month: 0,
        uncreditedAmount1Month: 0,
        creditTurnover2Month: 0,
        uncreditedAmount2Month: 0,
        creditTurnover3Month: 0,
        uncreditedAmount3Month: 0,
        creditTurnover4Month: 0,
        creditTurnover5Month: 0,
        creditTurnover6Month: 0
    };
}

export function DefaultIncomes(settings) {
    return {
        monthlyNetIncome: 0,
        lastTaxSettlementCz: DefaultLastTaxSettlementCz(),
        lastTaxSettlementSk: DefaultLastTaxSettlementSk(),
        taxSettlementLegalEntitySk: DefaultTaxSettlementLegalEntitySk(),
        previousTaxSettlementCz: DefaultPreviousTaxSettlementCz(),
        prePreviousTaxSettlementCz: DefaultPrePreviousTaxSettlement(),
        taxFlatRateCz: DefaultTaxFlatRateCz(),
        otherIncomesCz: DefaultOtherIncomesCz(settings),
        turnoverIncome: DefaultTurnoverIncome()
    };
}

export function DefaultOtherIncomesCz(settings) {
    return {
        starobniDuchod: 0,
        rodicovskyPrispevek: 0,
        materska: 0,
        vdovskyDuchod: 0,
        sirotciDuchod: 0,
        odmenaPestouna: 0,
        vysluhaPolicieVojaci: 0,
        vysluhaHornici: 0,
        vyzivne: 0,
        invalidniDuchod12: 0,
        invalidniDuchod: 0,
        dpc: 0,
        najemniSmlouva: 0,
        prispevekNaBydleni: 0,
        budoucniPronajem: 0,
        nahradaZtratyVydelku: 0,
        cestovne: 0,
        nemocenska: 0,
        prijemZKapitalovehoMajetku: 0,
        prispevekNaOsobniAsistenci: 0,
        podporaVNezamestnanosti: 0,
        vysluha: 0,
        urazovaRenta: 0,
        doplnkovyDuchod: 0
    }
}

export function DefaultExpenses(settings) {
    return {
        loans: 0,
        insurance: 0,
        overdraftLimits: 0,
        creditCardLimits: 0,
        liability: 0,
        alimony: 0,
        living: 0,
        monthlyWageDeduction: settings.supportExpensesMonthlyWageDeduction ? 0 : null,
        monthlyInsurance: settings.supportExpensesMonthlyInsurance ? 0 : null,
        costsForOtherHouseholdPersons: settings.supportExpensesCostsForOtherHouseholdPersons ? 0 : null
    };
}

export function DefaultActiveSections() {
    return {
        incomes: {
            incomeType: 'ts',
            incomeTypeSk: 'fo',
            incomeTS: DefaultIncomeTSActiveSections(),
            incomeTaxFlatRate: DefaultIncomeTaxFlatRateActiveSections()
        }
    };
}

function DefaultIncomeTSActiveSections() {
    return {
        incomeCheckboxes: DefaultIncomeCheckBoxes(),
        usingFlatRate: {
            business: {
                previousTaxSettlementFlatRate: true,
                prePreviousTaxSettlementFlatRate: true
            }
        },
        activeTSs: {
            business: {
                previousTaxSettlementHidden: true,
                prePreviousTaxSettlementHidden: true
            },
            lease: {
                previousTaxSettlementHidden: true,
                prePreviousTaxSettlementHidden: true
            }
        },
        disabledInputs: {
            businessTaxBaseInputs: DefaultDisabledBusinessTaxBaseInputs(),
            leaseTaxBaseInputs: DefaultDisabledLeaseTaxBaseInputs()
        }
    };
}

function DefaultIncomeTaxFlatRateActiveSections() {
    return {
        incomeCheckboxes: DefaultIncomeCheckBoxes(),
        disabledInputs: {
            businessTaxBaseInputs: DefaultDisabledBusinessTaxBaseInputs(),
            leaseTaxBaseInputs: DefaultDisabledLeaseTaxBaseInputs()
        }
    };
}

export function DefaultDisabledBusinessTaxBaseInputs() {
    return {
        lastBusiness: true,
        previousBusiness: true,
        prePreviousBusiness: true
    }
}

export function DefaultDisabledLeaseTaxBaseInputs() {
    return {
        lastLease: true,
        previousLease: true,
        prePreviousLease: true
    }
}



export function DefaultIncomeCheckBoxes() {
    return {
        prijmyPodnikani: false,
        prijmyPronajem: false,
        prijmyObrat: false
    }
}

export function DefaultSolidLinks() {
    return {
        incomes: false,
        incomesOther: false,
        expenses: false
    };
}


export function NewApplicant(householdAnchor, vychoziVek, settings, i18n) {
    if (vychoziVek === null) vychoziVek = 30;

    var appl = {
        id: 0,
        vek: vychoziVek,
        incomes: DefaultIncomes(settings),
        expenses: DefaultExpenses(settings),
        sumOfAllLoanBalances: 0,
        citizenship: 'CZ',

        activeSections: DefaultActiveSections(),
        solidLinks: DefaultSolidLinks()
    };

    while (householdAnchor.applicantPersons.findIndex(x => x.id === appl.id) !== -1) {
        appl.id++;
    }

    appl.name = i18n.t('credit.household.zadatel') + ' ' + (+appl.id + 1);

    return appl;
}

export function ObjectsValuesAreNotEmpty(expenses) {
    const notEmpty = (element) => {
        if (element) return true;
        return false;
    };
    return Object.values(expenses).some(notEmpty);
}

export function DefaultFilter(filter, certiDefault) {
    filter.checkboxes.forEach(d => {
        if (d.type === "EkologickyCertifikat") d.isChecked = certiDefault;
        else d.isChecked = true;
    });
    filter.filterPartners.items = DefaultFilterPartners(filter.filterPartners.items, certiDefault);
    return filter;
}

export function DefaultFilterPartners(items, certiDefault) {
    items.forEach(d => {
        d.vybranyPartner = true;
        d.nakladyNaZpracovani = null;
        d.mesicniNaklady = null;
        d.obchodniSleva = 0;

        d.vlastnosti.forEach(e => {
            if (e.typSlevy === "EkologickyCertifikat") e.selected = certiDefault;
            else e.selected = true;
        });

        d.mesicniNakladyRozpis = DefaultMesicniNakladyRozpis();
        d.nakladyNaZpracovaniRozpis = DefaultNakladyNaZpracovaniRozpis();

    });
    return items;
}

export function DefaultMesicniNakladyRozpis() {
    return {
        poplatekZaSpravu: null,
        poplatekZaVedeni: null,
        uverovePojisteni: null,
        pojisteniNemovitosti: null,
        jine: null
    };
}

export function DefaultNakladyNaZpracovaniRozpis() {
    return {
        poplatekZaOdhad: null,
        poplatekZaZpracovani: null,
        poplatekZaCerpani: null,
        poplatekZaCerpaniZS: null,
        jine: null
    };
}

export function FilterIsSelected(items, localization) {
    const propertyEdited = (prop) => {

        if (localization == 'cs') {
            if (prop.typSlevy === "EkologickyCertifikat" && prop.selected) return true;
            if (prop.typSlevy !== "EkologickyCertifikat" && !prop.selected) return true;
        }
        else {
            if (!prop.selected) return true;
        }

        return false;
    };

    const edited = (element) => {
        if (element.vybranyPartner === false) return true;
        if (element.nakladyNaZpracovani) return true;
        if (element.mesicniNaklady) return true;
        if (element.obchodniSleva) return true;

        if (element.mesicniNakladyRozpis.poplatekZaSpravu) return true;
        if (element.mesicniNakladyRozpis.poplatekZaVedeni) return true;
        if (element.mesicniNakladyRozpis.uverovePojisteni) return true;
        if (element.mesicniNakladyRozpis.pojisteniNemovitosti) return true;
        if (element.mesicniNakladyRozpis.jine) return true;

        if (element.nakladyNaZpracovaniRozpis.poplatekZaOdhad) return true;
        if (element.nakladyNaZpracovaniRozpis.poplatekZaZpracovani) return true;
        if (element.nakladyNaZpracovaniRozpis.poplatekZaCerpani) return true;
        if (element.nakladyNaZpracovaniRozpis.poplatekZaCerpaniZS) return true;
        if (element.nakladyNaZpracovaniRozpis.jine) return true;

        if (element.vlastnosti?.some(propertyEdited)) return true;

        return false;
    };
    return items?.some(edited);
}

export function BonitaIsFilled(households) {
    if (households) {
        let applicants = households.flatMap(d => d.applicantPersons);
        if (applicants.some(d => d.incomes.monthlyNetIncome)) return true;
        if (applicants.some(d => d.incomes.lastTaxSettlementCz?.taxSettlementBusinessCz?.businessIncome)) return true;
        if (applicants.some(d => d.incomes.previousTaxSettlementCz?.taxSettlementBusinessCz?.businessIncome)) return true;
        if (applicants.some(d => d.incomes.prePreviousTaxSettlementCz?.taxSettlementBusinessCz?.businessIncome)) return true;

        if (applicants.some(d => d.incomes.lastTaxSettlementCz?.taxSettlementLeaseCz?.leaseIncome)) return true;
        if (applicants.some(d => d.incomes.previousTaxSettlementCz?.taxSettlementLeaseCz?.leaseIncome)) return true;
        if (applicants.some(d => d.incomes.prePreviousTaxSettlementCz?.taxSettlementLeaseCz?.leaseIncome)) return true;

        if (applicants.some(d => d.incomes.taxFlatRateCz?.taxFlatRateBusinessCz?.businessIncome)) return true;
        if (applicants.some(d => d.incomes.taxFlatRateCz?.taxFlatRateBusinessCz?.creditTurnover1Month)) return true;
        if (applicants.some(d => d.incomes.taxFlatRateCz?.taxFlatRateLeaseCz?.leaseIncome)) return true;

        if (applicants.some(d => d.incomes.turnoverIncome?.creditTurnover1Month)) return true;

        if (applicants.some(d => d.incomes.lastTaxSettlementSk?.taxSettlementBusinessSk?.businessIncome)) return true;
        if (applicants.some(d => d.incomes.lastTaxSettlementSk?.taxSettlementBusinessSk?.salesIncomeLine3)) return true;
        if (applicants.some(d => d.incomes.lastTaxSettlementSk?.taxSettlementBusinessSk?.returnsIncomeLine41)) return true;
        if (applicants.some(d => d.incomes.lastTaxSettlementSk?.taxSettlementBusinessSk?.salesIncomeLine61)) return true;

        if (applicants.some(d => d.incomes.lastTaxSettlementSk?.taxSettlementLeaseSk?.leaseIncome)) return true;

        if (applicants.some(d => ObjectsValuesAreNotEmpty(d.incomes.otherIncomesCz))) return true;
    }
    return false;
}

export function ApplyMethodologyFilter(currentItems, filtersToApply) {
    if (!filtersToApply) return;
    function FindAndApplyHereAndChildren(cItems, fToApply) {
        if (!cItems) return;
        if (fToApply.foreignersData) {
            var cizinec = cItems.find(d => d.label === "Cizinec");
            if (cizinec) {
                cizinec.isChecked = true;
                cizinec.country = fToApply.foreignersData.country;
                cizinec.typeOfStay = fToApply.foreignersData.typeOfStay;
                cizinec.loanPurpose = fToApply.foreignersData.loanPurpose;
                cizinec.czechIncome = fToApply.foreignersData.czechIncome;
                cizinec.maxLtv = fToApply.foreignersData.maxLtv;
                cizinec.maxMaturity = fToApply.foreignersData.maxMaturity;
                cizinec.maxLoanAmount = fToApply.foreignersData.maxLoanAmount;
            }
            else {
                cItems.forEach((el) => FindAndApplyHereAndChildren(el.children, fToApply));
            }
        }
        else {
            var parent = cItems.find(d => d.name === fToApply.parent || d.label === fToApply.parent);
            if (parent) {
                var item = parent.children.find(q => q.label === fToApply.item);
                if (item) item.isChecked = true;
            }
            else {
                cItems.forEach((el) => FindAndApplyHereAndChildren(el.children, fToApply));
            }
        }
    };
    filtersToApply.forEach((filter) => FindAndApplyHereAndChildren(currentItems, filter));
}
export function DefaultMethodologyFilter() {
    return [
        {
            label: "Druhy úvěru",
            name: "Druhy úvěrů",
            children: [
                {
                    label: "Klasický hypoteční úvěr"
                },
                {
                    label: "Předhypoteční úvěr"
                },
                {
                    label: "Offset"
                },
                {
                    label: "Hypotéka dopředu"
                }
            ]
        },
        {
            label: "Účely úvěru",
            children: [
                {
                    label: "Koupě",
                    children: [
                        {
                            label: "Koupě nemovitosti - dokončené"
                        },
                        {
                            label: "Koupě  nemovitosti - rozestavěné"
                        },
                        {
                            label: "Družstevní byt"
                        },
                        {
                            label: "Od města / obce"
                        },
                        {
                            label: "V dražbě"
                        },
                        {
                            label: "V insolvenci"
                        },
                        {
                            label: "Developerský projekt"
                        },
                        {
                            label: "V zahraničí"
                        },
                        {
                            label: "Podílu v obchodní korporaci"
                        },
                        {
                            label: "Spoluvlastnického podílu"
                        },
                        {
                            label: "Od rodiny / vlastní společnosti"
                        },

                    ]
                },
                {
                    label: "Refinancování",
                    children: [
                        {
                            label: "Od banky a stavební spořitelny"
                        },
                        {
                            label: "Od nebankovního subjektu a FO"
                        },
                        {
                            label: "Již provedených investic"
                        },
                        {
                            label: "Zjednodušené refinancování"
                        }
                    ]
                },
                {
                    label: "Výstava a rekonstrukce",
                    children: [
                        {
                            label: "Výstavba nemovitosti"
                        },
                        {
                            label: "Výstavba dřevostavby"
                        },
                        {
                            label: "Výstavba svépomocí"
                        },
                        {
                            label: "Na pozemku obce / města"
                        },
                        {
                            label: "Připojení inženýrských sítí"
                        },
                        {
                            label: "Rekonstrukce / modernizace"
                        },
                        {
                            label: "Výroba energie"
                        },
                    ]
                },
                {
                    label: "Ostatní účely",
                    children: [
                        {
                            label: "Vypořádání vlastnických vztahů"
                        },
                        {
                            label: "Příslušenství k nemovitosti"
                        },
                        {
                            label: "Neúčelová část účelové hypotéky"
                        },
                    ]
                },
                {
                    label: "Účely neúčelového úvěru",
                    children: [
                        {
                            label: "Neúčelová hypotéka"
                        },
                        {
                            label: "Konsolidace"
                        },
                    ]
                },
            ]
        },
        {
            label: "Žadatelé",
            children: [
                {
                    label: "Splatnost dle mladšího"
                },
                {
                    label: "Uvěr do 36 let věku"
                },
                {
                    label: "Cizinec",
                    type: "foreigners",
                    country: "",
                    typeOfStay: 0,
                    loanPurpose: 0,
                    czechIncome: true,
                    maxLtv: true,
                    maxMaturity: true,
                    maxLoanAmount: true
                },
            ]
        },
        {
            label: "Příjmy",
            children: [
                {
                    label: "Příjmy ze zaměstnání",
                    children: [
                        {
                            label: "Na dobu neurčitou"
                        },
                        {
                            label: "Na dobu určitou"
                        },
                        {
                            label: "V hotovosti"
                        },
                        {
                            label: "Z vlastní společnosti"
                        },
                        {
                            label: "Z rodinné firmy"
                        },
                        {
                            label: "Přes pracovní agenturu"
                        },
                    ]
                },
                {
                    label: "Příjmy z pronájmu",
                    children: [
                        {
                            label: "Příjem z pronájmu uvedený v DP"
                        },
                        {
                            label: "Příjem z pronájmu neuvedený v DP"
                        },
                        {
                            label: "Příjem z budoucího pronájmu"
                        },

                    ]
                },
                {
                    label: "Příjmy z podnikání",
                    children: [
                        {
                            label: "Příjmy ze základu daně"
                        },
                        {
                            label: "Režim paušální daň"
                        },
                        {
                            label: "Svobodná povolání"
                        },
                        {
                            label: "Alternativní příjmy"
                        },
                        {
                            label: "Výdajový paušál úprava výdajů"
                        },
                        {
                            label: "Příjmy provizním výpisem"
                        },
                        {
                            label: "Příjmy z obratu"
                        },
                        {
                            label: "Příjmy z V.O.S.a K.S."
                        },
                        {
                            label: "Spolupracující osoba"
                        },
                        {
                            label: "Souběh příjmů"
                        },
                    ]
                },
                {
                    label: "Příjmy ze zahraničí",
                    children: [
                        {
                            label: "Příjmy ze zaměstnání"
                        },
                        {
                            label: "Příjmy z podnikání"
                        },
                        {
                            label: "Příjmy z pronájmu"
                        },
                        {
                            label: "Starobní důchod"
                        },

                    ]
                },
                {
                    label: "Důchody",
                    children: [
                        {
                            label: "Invalidní důchod"
                        },
                        {
                            label: "Starobní důchod"
                        },
                        {
                            label: "Vdovský důchod"
                        },
                        {
                            label: "Sirotčí důchod"
                        },

                    ]
                },
                {
                    label: "Příspěvky a dávky",
                    children: [
                        {
                            label: "Rodičovský příspěvek"
                        },
                        {
                            label: "Peněžitá pomoc v mateřství"
                        },
                        {
                            label: "Dávky nemocenské"
                        },
                        {
                            label: "Dávky pro tělesně postižené"
                        },
                        {
                            label: "Podpora v nezaměstnanosti"
                        },
                        {
                            label: "Příspěvek na bydlení pro vojáky"
                        },
                        {
                            label: "Příspěvek na péči"
                        },
                        {
                            label: "Trvané náhrady za ztrátu výdělku"
                        },
                        {
                            label: "Výsluhový příspěvek"
                        },

                    ]
                },
                {
                    label: "Ostatní příjmy",
                    children: [
                        {
                            label: "Brigády / smlouvy o dílo"
                        },
                        {
                            label: "Cestovní náhrady(diety)"
                        },
                        {
                            label: "Dohoda o provedení činnosti"
                        },
                        {
                            label: "Dohoda o provedení práce"
                        },
                        {
                            label: "Nezařazené příjmy"
                        },
                        {
                            label: "Odměna pěstouna"
                        },
                        {
                            label: "Předschválené příjmy"
                        },
                        {
                            label: "Příjmy z kapitálového majetku"
                        },
                        {
                            label: "Stipendium"
                        },
                        {
                            label: "Výživné"
                        },
                        {
                            label: "Zjednodušené příjmy u refinancování"
                        },
                    ]
                },
            ]
        },
        {
            label: "Nemovitosti",
            children: [
                {
                    label: "Objekt úvěru je shodný se zástavou",
                    name: "Zástavy",
                    children: [
                        {
                            label: "Ateliér"
                        },
                        {
                            label: "Bytová jednotka v osobním vlastnictví"
                        },
                        {
                            label: "Bytové domy s nebytovými prostory"
                        },
                        {
                            label: "Bytový dům"
                        },
                        {
                            label: "Nebytový prostor"
                        },
                        {
                            label: "Pozemek bez výstavby"
                        },
                        {
                            label: "Právo stavby"
                        },
                        {
                            label: "Rekreační objekt"
                        },
                        {
                            label: "Rodinný dům"
                        },
                        {
                            label: "Rodinný dům s nebytovými prostory"
                        },
                        {
                            label: "Samostatná vedlejší stavba"
                        },
                        {
                            label: "Stavební pozemek s výstavbou"
                        },
                    ]
                },
                {
                    label: "Objekt úvěru je odlišný od zástavy",
                    children: [
                        {
                            label: 'Zástavy',
                            children: [
                                {
                                    label: "Ateliér"
                                },
                                {
                                    label: "Bytová jednotka v osobním vlastnictví"
                                },
                                {
                                    label: "Bytové domy s nebytovými prostory"
                                },
                                {
                                    label: "Bytový dům"
                                },
                                {
                                    label: "Nebytový prostor"
                                },
                                {
                                    label: "Pozemek bez výstavby"
                                },
                                {
                                    label: "Právo stavby"
                                },
                                {
                                    label: "Rekreační objekt"
                                },
                                {
                                    label: "Rodinný dům"
                                },
                                {
                                    label: "Rodinný dům s nebytovými prostory"
                                },
                                {
                                    label: "Samostatná vedlejší stavba"
                                },
                                {
                                    label: "Stavební pozemek s výstavbou"
                                },
                            ]
                        },
                        {
                            label: 'Objekty úvěru',
                            children: [
                                {
                                    label: "Ateliér"
                                },
                                {
                                    label: "Bytová jednotka v družstevním vlastnictví"
                                },
                                {
                                    label: "Bytová jednotka v osobním vlastnictví"
                                },
                                {
                                    label: "Bytový dům"
                                },
                                {
                                    label: "Bytové domy s nebytovými prostory"
                                },
                                {
                                    label: "Montovaný dům"
                                },
                                {
                                    label: "Nemovitost k pronájmu"
                                },
                                {
                                    label: "Pozemek bez výstavby"
                                },
                                {
                                    label: "Právo stavby"
                                },
                                {
                                    label: "Příslušenství k nemovitosti"
                                },
                                {
                                    label: "Rekreační objekt"
                                },
                                {
                                    label: "Rodinný dům"
                                },
                                {
                                    label: "Rodinný dům s nebytovými prostory"
                                },
                                {
                                    label: "Stavební pozemek s výstavbou"
                                },
                            ]
                        }
                    ]
                },
            ]
        },
    ]
}

export function DefaultMethodologyFilterSk() {
    return [
        {
            label: "Druhy úverov",
            children: [
                {
                    label: "Účelová hypotéka"
                },
                {
                    label: "Bezúčelová hypotéka"
                },
                {
                    label: "Refinančná hypotéka"
                },
                {
                    label: "Konsolidačná hypotéka"
                },
                {
                    label: "Hypotéka bez nehnuteľnosti"
                }
            ]
        },
        {
            label: "Účel úveru",
            children: [
                {
                    label: "Kúpa",
                    children: [
                        {
                            label: "Kúpa nehnuteľnosti v SR"
                        },
                        {
                            label: "Kúpa nehnuteľnosti v EÚ"
                        },
                        {
                            label: "Kúpa pozemku"
                        },
                        {
                            label: "Kúpa developerskej nehnuteľnosti"
                        },
                        {
                            label: "Kúpa nešpecifikovanej nehnuteľnosti"
                        },
                        {
                            label: "Kúpa rekreačnej nehnuteľnosti"
                        },
                        {
                            label: "Kúpa v dražbe"
                        },
                    ]
                },
                {
                    label: "Rekonštrukcia",
                    children: [
                        {
                            label: "Rekonštrukcia s ohlasovacou povinnosťou"
                        },
                        {
                            label: "Rekonštrukcia so stavebným povolením"
                        },
                        {
                            label: "Rekonštrukcia rekreačnej nehnuteľnosti"
                        },
                        {
                            label: "Rekonštrukcia nebytového priestoru"
                        }
                    ]
                },
                {
                    label: "Vysporiadanie",
                    children: [
                        {
                            label: "Vysporiadanie BSM"
                        },
                        {
                            label: "Vysporiadanie dedičstva"
                        },
                    ]
                },
                {
                    label: "Výstavba",
                    children: [
                        {
                            label: "Výstavba rodinného domu"
                        },
                        {
                            label: "Výstavba rozostavanej nehnuteľnosti"
                        },
                        {
                            label: "Výstavba rekreačnej nehnuteľnosti"
                        },
                    ]
                },
                {
                    label: "Bezúčelové úvery",
                    children: [
                        {
                            label: "Bezúčelový úvěr"
                        },
                        {
                            label: "Konsolidácia"
                        },
                    ]
                },
                {
                    label: "Ostatné Účely",
                    children: [
                        {
                            label: "Refinancovanie"
                        },
                        {
                            label: "Refundácia nákladov"
                        },
                        {
                            label: "Dofinancovanie SÚ"
                        },
                    ]
                },
            ]
        },
        {
            label: "Žiadatelia",
            children: [
                {
                    label: "Občan SR, trvalý pobyt SR"
                },
                {
                    label: "Občan mimo SR, trvalý pobyt SR, SK RČ"
                },
                {
                    label: "Občan ČR, pracuje SR, bez prechodného pobytu SR"
                },
                {
                    label: "Občan EU, prechodný pobytom na SR"
                },
                {
                    label: "Občan mimo EU, trvalý pobyt SR"
                },
            ]
        },
        {
            label: "Príjmy",
            children: [
                {
                    label: "Príjmy zo závislej činnosti v SR",
                    children: [
                        {
                            label: "Príjem doba určitá na BÚ"
                        },
                        {
                            label: "Príjem doba určitá v hotovosti"
                        },
                        {
                            label: "Príjem doba neurčitá na BÚ"
                        },
                        {
                            label: "Príjem doba neurčitá v hotovosti"
                        },
                        {
                            label: "Príjem cez agentúru"
                        },
                        {
                            label: "Príjem z vlastnej spoločnosti"
                        },
                        {
                            label: "Príjem ak zamestnávateľ zmenil IČO"
                        },
                        {
                            label: "Príjem z dohody o pracovnej činnosti"
                        },
                    ]
                },
                {
                    label: "Príjmy zo závislej činnosti mimo SR",
                    children: [
                        {
                            label: "Príjem občan SR, doba určitá"
                        },
                        {
                            label: "Príjem z ČR, sociálna dávka"
                        },
                        {
                            label: "Príjem mimo EUR, dobu neurčitú, trvalý pobyt v SR"
                        },
                        {
                            label: "Príjem v EUR, doba určitá, občana SR"
                        },

                    ]
                },
                {
                    label: "Príjmy z podnikania",
                    children: [
                        {
                            label: "Príjem konateľa formou odmeny"
                        },
                        {
                            label: "Príjem spoločníka zo zisku"
                        },
                        {
                            label: "Príjem zo slobodného povolania"
                        },
                        {
                            label: "Príjem zo slobodného povolania makléra"
                        },
                        {
                            label: "Príjem zo SZČO"
                        },
                        {
                            label: "Príjem z vlastnej spoločnosti"
                        },
                        {
                            label: "Príjem z prenájmu nehnuteľnosti"
                        },
                        {
                            label: "Príjem opatrovateľky v Rakúsku"
                        },
                        {
                            label: "Živnostník z donútenia"
                        },
                    ]
                },
                {
                    label: "Príspevky a dávky",
                    children: [
                        {
                            label: "Daňový bonus na dieťa"
                        },
                        {
                            label: "Náhrady na strate zárobku po skončení PN"
                        },
                        {
                            label: "Príspevok za osobnú asistenciu"
                        },
                        {
                            label: "Rodičovský príspevok / Materská"
                        },
                        {
                            label: "Rodinné prídavky"
                        },
                        {
                            label: "Výživné"
                        },
                    ]
                },
                {
                    label: "Dôchodky",
                    children: [
                        {
                            label: "Dôchodok Invalidný menej ako 70%"
                        },
                        {
                            label: "Dôchodok Invalidný viac ako 70% "
                        },
                        {
                            label: "Dôchodok Sirotský"
                        },
                        {
                            label: "Dôchodok Starobný"
                        },
                        {
                            label: "Dôchodok Vdovský/Vdovecký"
                        },
                        {
                            label: "Dôchodok Výsluhový"
                        },
                    ]
                },
                {
                    label: "Ostatné príjmy",
                    children: [
                        {
                            label: "Diéty"
                        },
                        {
                            label: "Doktorandské štipendium"
                        },
                        {
                            label: "Odmena pestúna"
                        },
                        {
                            label: "Príjmy z divident akcionára"
                        },
                        {
                            label: "Príspevok vojaka na bývanie"
                        },
                        {
                            label: "Sociálne transfery z ČR"
                        },
                        {
                            label: "Úrazová renta"
                        },
                        {
                            label: "Výživné"
                        },
                    ]
                },
                {
                    label: "Príjem bez preukazovania",
                    children: [
                        {
                            label: "Bez preukazovania príjmu"
                        },
                    ]
                },
            ]
        },
        {
            label: "Výdavky",
            children: [
                {
                    label: "IČO KK a PP"
                },
                {
                    label: "IČO leasing"
                },
                {
                    label: "Ručiteľske záväzky"
                },
                {
                    label: "Výživne"
                },
                {
                    label: "Zrážky zo mzdy"
                },
            ]
        },
        {
            label: "Zabezpečenia",
            children: [
                {
                    label: "Byt"
                },
                {
                    label: "Rodinný dom"
                },
                {
                    label: "Pozemok"
                },
                {
                    label: "Rozostavaná stavba"
                },
                {
                    label: "Rekreačný objekt"
                },
                {
                    label: "Nebytový priestor určený na bývanie"
                },
                {
                    label: "Iný nebytový priestor"
                },
                {
                    label: "Samostatne stojaca garáž"
                },
            ]
        },
        {
            label: "Spôsob čerpania",
            children: [
                {
                    label: "Jednorázové"
                },
                {
                    label: "Postupné"
                },
                {
                    label: "Zrýchlené: výstavbu / rekonštrukcia"
                },
                {
                    label: "Na základe plomby na LV"
                },
            ]
        },
        {
            label: "Poplatky",
            children: [
                {
                    label: "Poskytnutie úveru"
                },
                {
                    label: "Nečerpanie / nedočerpanie úveru"
                },
                {
                    label: "Mimoriadna splátka"
                },
                {
                    label: "Interné ohodnotenie nehnuteľnosti bankou"
                },
                {
                    label: "Ostatné zmeny (vinkulácia, údaje, účet)"
                },
                {
                    label: "Zmena zmluvných podmienok"
                },
                {
                    label: "Poistenie úveru"
                },
            ]
        },
    ]
}
