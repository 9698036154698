<template>
    <th @click="$emit('headerClicked')">
        <div>
            <span :style="spanWidth"
                  v-bTooltip="tooltip">
                {{headerValue}}
            </span>
            <img v-if="sortASC" src="@/assets/icons/upFill.svg" :class="{ active : selected }" />
            <img v-else src="@/assets/icons/downFill.svg" :class="{ active : selected }" />
        </div>
    </th>
</template>

<script>
    export default {
        name: 'v-table-header-with-sort',
        data() {
            return {
                
            }
        },
        methods: {
        },
        computed: {
            spanWidth: function () {
                if (this.width) {
                    return {
                        width: this.width
                    }
                }
                return null;
            }
        },
        emits: ['headerClicked'],
        props: {
            headerValue: String,
            selected: Boolean,
            sortASC: Boolean,
            width: String,
            tooltip: String
        }
    }
</script>

<style scoped>
    th > div {
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: center;
    }

    th span {
        text-align: right;
    }

    th img {
        width: 16px;
        filter: brightness(0) saturate(100%) invert(72%) sepia(26%) saturate(0%) hue-rotate(188deg) brightness(112%) contrast(87%);
    }

    th img.active {
        filter: brightness(0) saturate(100%) invert(18%) sepia(0%) saturate(1637%) hue-rotate(152deg) brightness(106%) contrast(98%);
    }
</style>