<template>
    <div class="modal" id="methodologyfilterModal" tabindex="-1">
        <div class="modal-dialog modal-lg modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ $t('credit.methodologyFilterModal.title') }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="panel hypo-panel panel-default panel-filter">
                        <div class="panel-heading" @click="activeWindows.banks = !activeWindows.banks">
                            <img class="heading-icon" src="/CreditWorthiness/img/banka.svg"> {{ $t('credit.methodologyFilterModal.banks') }} <!---->
                            <img v-if="activeWindows.banks" class="heading-arrow" src="/CreditWorthiness/img/upFill.svg">
                            <img v-else class="heading-arrow" src="/CreditWorthiness/img/downFill.svg">
                        </div>
                        <div :class="[ 'panel-body collapse', { in : activeWindows.banks }]">
                            <div class="partner-checkbox-row">
                                <div class="partner-checkbox-pill" v-for="partner in dummyPartners" :key="partner.code">
                                    <input type="checkbox" v-model="partner.selected" />
                                    <img v-bTooltip="partner.name" :src="'/ImageProxy.aspx?f=' + partner.code + '-big.png'" @click="$emit('partnerClicked', partner.code, partner.selected)" />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="panel hypo-panel panel-default panel-filter">
                        <div class="panel-heading" @click="activeWindows.parameters = !activeWindows.parameters">
                            <img class="heading-icon" src="/CreditWorthiness/img/parameters.svg"> {{ $t('credit.methodologyFilterModal.parameters') }} <!---->
                            <img v-if="activeWindows.parameters" class="heading-arrow" src="/CreditWorthiness/img/upFill.svg">
                            <img v-else class="heading-arrow" src="/CreditWorthiness/img/downFill.svg">
                        </div>
                        <div :class="[ 'panel-body collapse', { in : activeWindows.parameters }]">

                            <div v-for="item in methodologyFilterItems" class="panel hypo-panel panel-default panel-filter panel-no-offset" :key="item.label">
                                <div class="panel-heading" @click="item.unwraped = !item.unwraped">
                                    {{item.label}} <!---->
                                    <img v-if="item.unwraped" class="heading-arrow" src="/CreditWorthiness/img/upFill.svg">
                                    <img v-else class="heading-arrow" src="/CreditWorthiness/img/downFill.svg">
                                </div>
                                <div :class="[ 'panel-body collapse', { in : item.unwraped }]">
                                    <v-tree-items-checkboxes :items="item.children" :methodologyForeignersSettings="methodologyForeignersSettings"/>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button"
                            class="btn btn-default pull-left"
                            data-dismiss="modal">
                        <img class="left" src="@/assets/icons/X.svg" />{{ $t('global.cancel') }}
                    </button>
                    <button type="button"
                            class="btn btn-default"
                            @click="resetFilter">
                        <img class="pencil-reset left" src="@/assets/icons/update-auto.svg" />{{ $t('global.setDefault') }}
                    </button>
                    <button type="button"
                            class="btn btn-primary"
                            @click="parseFilterAndRedirect">
                        <img class="left" src="@/assets/icons/knihovna.svg" /> {{ $t('credit.methodologyFilterModal.buttons.showInMethodology') }}
                    </button>
                    <button type="button"
                            class="btn btn-primary"
                            data-dismiss="modal"
                            @click="parseFilterAndSend">
                        <img class="left" src="@/assets/icons/savesmall.svg" />{{ $t('global.saveAndCount') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TreeItemsCheckboxes from '@/components/MethodologyFilter/TreeItemsCheckboxes.vue'

    export default {
        name: 'v-methodology-filter-modal',
        components: {
            "v-tree-items-checkboxes": TreeItemsCheckboxes,
        },
        data() {
            return {
                windowWidth: 0,
                activeWindows: {
                    banks: true,
                    parameters: true
                },
                filterItems: this.methodologyFilterItems
            }
        },
        watch: {
        },
        methods: {
            resetFilter() {
                this.$emit('resetMethodologyFilter');
                this.activeWindows.banks = false;
                this.activeWindows.parameters = true;
            },
            windowResized() {
                this.windowWidth = window.innerWidth;
            },
            parseFilterAndSend() {
                this.$emit('saveChanges', this.parseFilter());
            },
            parseFilter() {
                let filterResult = [];
                function treeSearch(item, parentLabel) {
                    var name = item.name ? item.name : item.label;
                    if (item.isChecked) {
                        if (item.type === 'foreigners') {
                            filterResult.push({
                                item: item.country,
                                parent: parentLabel,
                                foreignersData: {
                                    country: item.country,
                                    typeOfStay: item.typeOfStay,
                                    loanPurpose: item.loanPurpose,
                                    czechIncome: item.czechIncome,
                                    maxLtv: item.maxLtv,
                                    maxMaturity: item.maxMaturity,
                                    maxLoanAmount: item.maxLoanAmount
                                }
                            });
                        }
                        else {
                            filterResult.push({
                                item: name,
                                parent: parentLabel
                            });
                        }
                        return;
                    }
                    if (item.children) {
                        item.children.forEach((el) => treeSearch(el, name));
                    }
                }
                this.methodologyFilterItems.forEach((el) => treeSearch(el, 'Root'));
                return filterResult;
            },
            parseFilterAndRedirect() {
                this.getMethodologyRedirectLink(this.parseFilter());
            },
            getMethodologyRedirectLink(items) {
                this.axios({
                    url: '/Default.aspx/MethodologyFilterRedirect',
                    method: 'POST',
                    responseType: 'application/json',
                    data:
                    {
                        request: {
                            filter: items
                        }
                    }
                }).then((response) => {
                    window.open("/Doris?login=true&redirectTo=" + JSON.parse(response.data.d), "_blank");
                });
            }

        },
        computed: {
        },
        props: {
            filterPartners: Array,
            localization: String,
            methodologyForeignersSettings: Object,
            methodologyFilterItems: Array,
            dummyPartners: Array
        },
        mounted: function () {
            this.windowWidth = window.innerWidth;
            window.addEventListener("resize", this.windowResized);
        }
    }
</script>

<style scoped>
    
</style>

