<template>
    <div :class="{ 'input-group': hasAddon }">
        <vue-numeric v-if="number" :id="inputId" :class="[ 'form-control', { 'is-invalid': anyError }, { 'has-icon': iconDef }]" v-model="inputValue" :disabled="disabledInput" @update:modelValue="onInput" @blur="onBlur" :min="numberMin" :minus="minus" separator="space" :precision="precision"/>
        <input v-else :id="inputId" v-model="inputValue" @input="onInput" :class="[ 'form-control', { 'is-invalid': anyError }]" :disabled="disabledInput" @blur="onBlur" :placeholder="placeholder" :maxlength="maxLength" :type="inputType" />
        <i v-if="iconDef" v-tooltip :class="[ iconDef, { 'addon-active': hasAddon } ]" @click="iconClicked" data-toggle="tooltip" :title="iconTooltip"></i>
        <i v-if="anyError" :class="[ 'fas', { 'addon-active': hasAddon }, { 'is-second': iconDef } ]" v-bPopover="errors">
            <img class="error-exclamation" src="@/assets/icons/alert.svg"/>
        </i>
        <span v-if="hasAddon" class="input-group-addon">{{addonString}}</span>
    </div>
</template>

<script>


    export default {
        name: 'v-input',
        data() {
            return {
                inputValue: this.modelValue,
            }
        },
        methods: {
            onInput() {
                this.$emit('update:modelValue', this.inputValue);
            },
            onBlur: function () {
                this.$emit('blured');
                if (this.number) {
                    if (+this.inputValue > +this.numberMax) {
                        this.inputValue = this.numberMax;
                    }
                    else if (+this.inputValue < +this.numberMin) {
                        this.inputValue = this.numberMin;
                    }
                    this.onInput();
                }
            },
            iconClicked() {
                this.$emit('overrideClicked');
                this.overrideVal();
            },
            overrideVal: function () {
                if (typeof this.overrideValue !== 'undefined') {
                    this.inputValue = this.overrideValue;
                    this.onInput();
                }
            }
        },
        watch: {
            modelValue() {
                this.inputValue = this.modelValue;
            }
        },
        computed: {
            hasAddon: function () {
                if (this.addonString !== undefined) return true;
                return false;
            },
            anyError: function () {
                if (this.errors == undefined) return false;
                return this.errors.length;
            }
        },
        emits: ['update:modelValue'],
        props: {
            modelValue: String,
            addonString: String,
            inputId: {
                type: String,
                required: true
            },
            number: {
                type: Boolean,
                default: false
            },
            numberMin: Number,
            numberMax: Number,
            maxLength: Number,
            iconDef: String,
            iconTarget: String,
            iconToggle: {
                type: String,
                default: 'modal'
            },
            precision: Number,
            placeholder: String,
            overrideValue: String,
            iconTooltip: String,
            minus: {
                type: Boolean,
                default: false
            },
            errors: Array,
            disabledInput: {
                type: Boolean,
                default: false
            },
            inputType: String
        },
        created() {
            this.$emit('overrideHandler', this.overrideVal);
        }
    }
</script>

<style scoped>
    i {
        position: absolute;
        top: 0;
        right: 10px;
        z-index: 2;
        display: block;
        line-height: 34px;
        text-align: center;
        cursor: pointer;
        font-size: 20px;
    }

    i.addon-active {
        right: 53px;
    }

    .form-control.is-invalid{
        border-color: #dc3545;
    }

    .fa-exclamation-circle {
        color: #dc3545;
        right: 20px;
    }

    i.addon-active.fas {
        right: 43px;
    }

    i.addon-active.fas.is-second {
        right: 75px;
    }

    input.has-icon{
        padding-right: 35px;
    }

    i img.error-exclamation {
        width: 22px;
        filter: invert(37%) sepia(48%) saturate(4305%) hue-rotate(332deg) brightness(86%) contrast(99%);
    }
</style>