<template>
    <tr class="active">
        <td class="lh18">
            <span v-if="item.BankCode === 'SK_UNI'" class="uni-logo-label">
                Univerzálna bonita klienta
            </span>
            <div v-else class="smallImgWrapper">
                <img v-bTooltip="item.ProductName" :src="'/ImageProxy.aspx?f=' + item.BankCode + '.png'" />
            </div>
        </td>
        <td colspan="70">
            <template v-if="calcInProgress">
                <i class="fas fa-spinner fa-spin"></i> {{ $t('credit.resultTableItemLoading.counting') }}
            </template>
            <template v-else>
                <i class="fas fa-times"></i> {{ $t('credit.resultTableItemLoading.canceled') }}
            </template>
        </td>
    </tr>
</template>

<script>
    export default {
        name: 'v-result-table-item',
        props: {
            item: Object,
            calcInProgress: Boolean
        }
    }
</script>

<style scoped>
</style>

