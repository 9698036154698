import axios from "axios";

const getIconDefinition = async (iconName) => {
  try {
    var resp = await axios.post(
      "/WebServices/IconService.asmx/GetIconDefinition",
      {
        name: iconName,
        headers: { Accept: "application/json" },
      }
    );
    return resp.data.d;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getIcon = async (iconName) => {
  try {
    const definition = await getIconDefinition(iconName);
    if (definition.ImgUrl)
      return `<img src="${definition.ImgUrl}" style="width: ${definition.Width}px; height: ${definition.Height}px"/>`;
    else
      return `<i class="${definition.CssClass}"/>`;
  } catch (error) {
    throw new Error(error);
  }
};
