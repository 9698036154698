<template>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <v-labeled-input :label="$t('credit.expensesModal.expensesLiving')" :labelTooltip="$t('tooltip.credit.expensesModal.expensesLiving')" v-model="applicant.expenses.living" number="true" :addonString="$t('global.currencySymbol')" inputId="houseHoldExpenses"></v-labeled-input>
            </div>
            <div class="col-md-6">
                <v-labeled-input :label="$t('credit.expensesModal.expensesInsurance')" :labelTooltip="$t('tooltip.credit.expensesModal.expensesInsurance')" v-model="applicant.expenses.insurance" number="true" :addonString="$t('global.currencySymbol')" inputId="insurance"></v-labeled-input>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.expensesModal.expensesLoans')" :labelTooltip="$t('tooltip.credit.expensesModal.expensesLoans')" v-model="applicant.expenses.loans" number="true" :addonString="$t('global.currencySymbol')" inputId="loanRepayment" methodologyItem="Půjčky"></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.expensesModal.sumOfAllBalances')" :labelTooltip="$t('tooltip.credit.expensesModal.sumOfAllBalances')" v-model="applicant.sumOfAllLoanBalances" number="true" :addonString="$t('global.currencySymbol')" inputId="loanSum" methodologyItem="Půjčky"></v-labeled-input-methodology-link>
            </div>
            <!--Následující dvě pole ponechávám zakomentované z důvodu možného návratu funkčnosti-->
            <!--<div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.expensesModal.mortgageLoans')" :labelTooltip="$t('tooltip.credit.expensesModal.mortgageLoans')" v-model="applicant.expenses.mortgageLoans" number="true" :addonString="$t('global.currencySymbol')" inputId="mortgageLoanRepayment" methodologyItem="Půjčky"></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.expensesModal.sumOfAllMortgagesLoanBalances')" :labelTooltip="$t('tooltip.credit.expensesModal.sumOfAllMortgagesLoanBalances')" v-model="applicant.expenses.sumOfAllMortgageLoanBalances" number="true" :addonString="$t('global.currencySymbol')" inputId="mortgageLoanSum" methodologyItem="Půjčky"></v-labeled-input-methodology-link>
            </div>-->
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.expensesModal.expensesOverdraftLimits')" :labelTooltip="$t('tooltip.credit.expensesModal.expensesOverdraftLimits')" v-model="applicant.expenses.overdraftLimits" number="true" :addonString="$t('global.currencySymbol')" inputId="overdraftLimit" methodologyItem="Kontokorent"></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.expensesModal.expensesCreditCardLimits')" :labelTooltip="$t('tooltip.credit.expensesModal.expensesCreditCardLimits')" v-model="applicant.expenses.creditCardLimits" number="true" :addonString="$t('global.currencySymbol')" inputId="creditCardLimit" methodologyItem="Kreditní karty"></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input :label="$t('credit.expensesModal.expensesAlimony')" :labelTooltip="$t('tooltip.credit.expensesModal.expensesAlimony')" v-model="applicant.expenses.alimony" number="true" :addonString="$t('global.currencySymbol')" inputId="alimonyExpense"></v-labeled-input>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.expensesModal.expensesLiability')" :labelTooltip="$t('tooltip.credit.expensesModal.expensesLiability')" v-model="applicant.expenses.liability" number="true" :addonString="$t('global.currencySymbol')" inputId="liability" methodologyItem="Ručitelské závazky"></v-labeled-input-methodology-link>
            </div>
        </div>
    </div>
</template>

<script>
    import LabeledInput from '../LabeledInput.vue'
    import LabeledInputMethodologyLink from '../LabeledInputMethodologyLink.vue'

    export default {
        name: 'v-expenses-body',
        components: {
            'v-labeled-input': LabeledInput,
            'v-labeled-input-methodology-link': LabeledInputMethodologyLink
        },
        watch: {
            'applicant.expenses': {
                handler() {
                    this.$emit('update:applicant', this.applicant);
                },
                deep: true
            },
            'applicant.sumOfAllLoanBalances': {
                handler() {
                    this.$emit('update:applicant', this.applicant);
                },
                deep: true
            },
        },
        props: {
            applicant: Object
        }
    }
</script>

<style scoped>
    p.star-comment {
        color: red;
        margin: 0;
        margin-bottom: -26px;
        margin-top: 26px;
        height: 0;
    }
</style>