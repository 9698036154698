<template>
    <div :class="['hypo-form-group']">
        <label :for="inputId"
               :class="[labelClass, { required : isRequired }]">
            {{ label }} <img v-if="labelTooltip"
                             class="label-help"
                             src="@/assets/icons/help.svg"
                             v-bPopover="labelTooltip" />
        </label>
        <v-button-select :selectId="selectId" 
                         :items="items" 
                         :selectedValue="value"
                         @update:selectedValue="val => $emit('update:value', val)"
                         @change="valueChanged" />
    </div>
</template>

<script>
    import ButtonSelect from './ButtonSelect.vue'

    export default {
        name: 'v-labeled-button-select',
        components: {
            'v-button-select': ButtonSelect
        },
        methods: {
            valueChanged(value) {
                this.$emit('change', value);
            }
        },
        props: {
            items: Array,
            value: String,
            selectId: String,
            label: String,
            labelTooltip: String,
            labelClass: String
        }
    }
</script>

<style scoped>
    label {
        white-space: pre-line;
    }

        label.required::after {
            content: "*";
        }

    .hypo-form-group {
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
    }

        .hypo-form-group > label {
            color: #333;
            font-weight: normal;
            padding-bottom: 4px
        }

    img.label-help {
        filter: brightness(0) saturate(100%) invert(15%) sepia(0%) saturate(0%) hue-rotate(243deg) brightness(99%) contrast(86%);
    }

        img.label-help:hover {
            filter: brightness(0) saturate(100%) invert(50%) sepia(8%) saturate(7287%) hue-rotate(186deg) brightness(81%) contrast(102%);
        }
</style>