<template>
    <tr class="msg active">
        <td colspan="70" style="padding: 0 !important">
            <div v-for="msg in messages" :key="msg.Message">
                <div :class="{'alert-danger' : msg.Type == 'Fatal', 'alert-info' : msg.Type == 'Info', 'alert-warning' : msg.Type == 'Warning'}" class="alert">
                    <p v-html="msg.PublicMessage"/>
                </div>
            </div>
            <div v-if="uiDebug">
                <div style="border: 5px solid yellow; margin: 10px 0px; padding: 5px; font-size: 10px;">
                    <b>debug all messages:</b>
                    <div v-for="msg in item.MessagesDebug" :key="msg.Message">
                        <div>
                            <p style="margin: 0px;">  PublicMessage: {{msg.PublicMessage}}</p>
                            <p style="margin: 0px;">  Message: {{msg.Message}}</p>
                            <p style="margin: 0px;">  Type: {{msg.Type}}</p>
                            <p style="margin: 0px;">  Scope: {{msg.Scope}}</p>
                            <p style="margin: 0px;">  Code: {{msg.Code}}</p>
                            <p style="margin: 0px;">  Target: {{msg.Target}}</p>
                        </div>
                        <hr />
                    </div>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
    export default {
        name: 'v-result-table-item',
        props: {
            item: Object,
            uiDebug: Boolean,
            limitlessResult: Boolean
        },
        computed: {
            messages() {
                function UniquePublicMessage(value, index, self) {
                    return self.find(d => d.PublicMessage === value.PublicMessage) === value;
                }
                const uniqueMessages = this.item.Messages.filter(UniquePublicMessage);
                return uniqueMessages.filter(d => !(this.limitlessResult && d.Code === 'StressTest'));
            }
        }
    }
</script>

<style scoped>
    tr.msg > td {
        padding-top: 0px;
        border-top: 0px;
    }

    .alert {
        margin: 0px;
        padding: 2px;
        border-radius: 0px;
    }
</style>