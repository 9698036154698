<template>
    <div class="hypo-form-group" :class="groupClass">
        <label :for="inputId" 
               :class="[labelClass, { required : isRequired }]">
            {{ label }} <img v-if="labelTooltip" 
                                class="label-help" 
                                src="@/assets/icons/help.svg"
                                v-bPopover="labelTooltip" />
        </label>
        <v-input class="hypo-input"
                 :inputId="inputId"
                 v-model="localValue"
                 @update:modelValue="onInput"
                 :addonString="addonString"
                 :number="number"
                 :numberMin="numberMin"
                 :numberMax="numberMax"
                 :maxLength="maxLength"
                 :iconDef="iconDef"
                 :iconTarget="iconTarget"
                 :iconToggle="iconToggle"
                 :precision="precision"
                 :errors="errors"
                 :overrideValue="overrideValue"
                 :iconTooltip="iconTooltip"
                 :minus="minus"
                 :disabledInput="disabledInput"
                 :data-toggle="inputTooltip ? 'tooltip' : null" :title="inputTooltip" data-placement="auto" data-container="body" :data-trigger="inputTooltipTrigger"
                 @overrideClicked="$emit('overrideClicked')"
                 @overrideHandler="emitHandler"
                 @blured="$emit('blured')" 
                 :inputType="inputType"
                 />
    </div>
</template>

<script>
    import Input from './Input.vue'

    export default {
        name: 'v-labeled-input',
        components: {
            'v-input': Input
        },
        data() {
            return {
                localValue: this.modelValue
            }
        },
        watch: {
            modelValue() {
                this.localValue = this.modelValue;
            }
        },
        methods: {
            onInput(val) {
                this.$emit('update:modelValue', val);
            },
            emitHandler(fn) {
                this.$emit('overrideHandler', fn);
            }
        },
        emits: ['update:modelValue'],
        props: {
            label: String,
            groupClass: String,
            modelValue: String,
            addonString: String,
            inputId: {
                type: String,
                required: true
            },
            number: Boolean,
            numberMin: Number,
            numberMax: Number,
            maxLength: Number,
            iconDef: String,
            iconTarget: String,
            iconToggle: String,
            precision: Number,
            errors: Array,
            inputSize: {
                type: Number,
                default: 40
            },
            overrideValue: String,
            iconTooltip: String,
            labelTooltip: String,
            inputTooltip: String,
            inputTooltipTrigger: {
                type: String,
                default: 'hover'
            },
            labelClass: String,
            minus: Boolean,
            isRequired: Boolean,
            disabledInput: Boolean,
            inputType: String
        }
    }
</script>

<style scoped>
    label {
        white-space: nowrap;
    }

    label.required::after {
        content: "*";
    }

    .hypo-form-group {
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
    }
    .hypo-form-group > label {
        color: #333;
        font-weight: normal;
        padding-bottom: 4px
    }

    img.label-help {
        filter: brightness(0) saturate(100%) invert(15%) sepia(0%) saturate(0%) hue-rotate(243deg) brightness(99%) contrast(86%);
    }

    img.label-help:hover {
        filter: brightness(0) saturate(100%) invert(50%) sepia(8%) saturate(7287%) hue-rotate(186deg) brightness(81%) contrast(102%);
    }
        
</style>

<style>
    
    .label-red {
        color: red;
    }

    label[data-toggle="tooltip"] {
        text-decoration: underline;
        cursor: help;
    }
</style>