<template>
    <!-- Modal -->
    <div v-modal class="modal fade" id="savePopUpModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="vuemodal">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 style="margin-top: 0" class="modal-title">{{ $t('credit.resultTablePopUp.saveTitle') }}</h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <template v-if="stepName === 'newOrExisting'">
                    <div class="modal-body">
                        <span style="font-weight: bold;">{{ $t('credit.resultTablePopUp.saveInfo') }}</span>

                        <div class="button-placeholder">
                            <button type="button" :class="['btn btn-default']" @click="stepName = 'newApplicant'">{{ $t('credit.resultTablePopUp.buttons.newClient') }}</button>
                            <button type="button" :class="['btn btn-default']" @click="stepName = 'existingApplicant'">{{ $t('credit.resultTablePopUp.buttons.existingClient') }}</button>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button"
                                class="btn btn-default pull-left"
                                data-dismiss="modal">
                            <img class="left" src="@/assets/icons/X.svg" />{{ $t('credit.resultTablePopUp.buttons.cancel') }}
                        </button>
                    </div>
                </template>

                <v-new-applicant v-if="stepName === 'newApplicant'"
                                 :applicantName="applicantName"
                                 @update:applicantName="val => applicantName = val"
                                 :calculationName="calculationName"
                                 @update:calculationName="val => calculationName = val"
                                 @update:stepName="val => stepName = val"
                                 @set-saved-calculation-info="setSavedCalculationInfo" />
                <v-applicant-selection v-if="stepName === 'existingApplicant'"
                                       @update:applicantName="val => applicantName = val"
                                       :calculationName="calculationName"
                                       @update:calculationName="val => calculationName = val"
                                       @update:stepName="val => stepName = val"
                                       @set-saved-calculation-info="setSavedCalculationInfo" />
                <v-save-completed v-if="stepName === 'completed'" 
                                  :applicantName="applicantName" 
                                  :calculationName="calculationName" 
                                  :savedCalculationInfo="savedCalculationInfo" />

            </div>
        </div>
    </div>
</template>

<script>
    import NewApplicant from './components/SavePopup/NewApplicant.vue'
    import ApplicantSelection from './components/SavePopup/ApplicantSelection.vue'
    import SaveCompleted from './components/SavePopup/SaveCompleted.vue'
    
    export default {
        name: 'app',
        components: {
            'v-new-applicant' : NewApplicant,
            'v-applicant-selection': ApplicantSelection,
            'v-save-completed': SaveCompleted
        },
        data() {
            return {
                stepName: "newOrExisting",
                calculationName: '',
                applicantId: 0,
                applicantName: '',
                savedCalculationInfo: {}
            }
        },
        methods: {
            resetSteps: function () {
                this.stepName = "newOrExisting";
                this.calculationName = '';
                this.applicantName = '';
            },
            setSavedCalculationInfo: function (val) {
                this.savedCalculationInfo = val;
            }
        },
        computed: {
        },
        mounted: function () {
            // eslint-disable-next-line
            $(this.$refs.vuemodal).on("hidden.bs.modal", this.resetSteps);
            
        }
    }
</script>

<style scoped>

</style>

<style>
    .button-placeholder {
        display: inline-flex;
        justify-content: center;
        width: 100%;
    }
        .button-placeholder .btn {
            font-size: 16px;
            padding: 10px 20px;
            margin: 30px;
        }

            /*.button-placeholder .btn.selected {
                background-color: lightgray;
                border: 2px solid gray;
            }

            .button-placeholder .btn:hover {
                background-color: lightgray;
                border: 2px solid darkgray;
            }*/
</style>
