<template>
    <div class="modal fade" id="vydajeModal" tabindex="-1" data-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ $t('credit.expensesModal.expenses') }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="$t('credit.expensesModal.closeWindow')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <component :applicant="applicant"
                           @update:applicant="val => applicant = val"
                           v-bind:is="'v-expenses-body-' + settings.expensesProvider"
                           :maturities="maturities"
                           :maturitiesMonths="maturitiesMonths"></component>

                <div class="modal-footer">
                    <button type="button"
                            class="btn btn-default pull-left"
                            data-dismiss="modal">
                        <img class="left" src="@/assets/icons/X.svg" />{{ $t('global.cancel') }}
                    </button>
                    <button type="button"
                            class="btn btn-default"
                            @click="resetModal">
                        <img class="pencil-reset left" src="@/assets/icons/update-auto.svg" />{{ $t('global.setDefault') }}
                    </button>
                    <button type="button"
                            class="btn btn-primary"
                            data-dismiss="modal"
                            @click="$emit('saveChanges')">
                        <img class="left" src="@/assets/icons/savesmall.svg" />{{ $t('global.save') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ExpensesBodyCz from './ExpensesBody/ExpensesBodyCz.vue'
    import ExpensesBodySk from './ExpensesBody/ExpensesBodySk.vue'

    import { ResetExpenses } from '../js/HouseholdHelpers.mjs'

    export default {
        name: 'v-expenses-modal',
        components: {
            'v-expenses-body-cz': ExpensesBodyCz,
            'v-expenses-body-sk': ExpensesBodySk,
        },
        watch: {
            'applicant.expenses': {
                handler() {
                    this.$emit('update:applicant', this.applicant);
                },
                deep: true
            },
            'applicant.sumOfAllLoanBalances': {
                handler() {
                    this.$emit('update:applicant', this.applicant);
                },
                deep: true
            },
        },
        methods: {
            resetModal: function () {
                ResetExpenses(this);
            }
        },
        props: {
            applicant: Object,
            settings: Object,
            maturities: Array,
            maturitiesMonths: Array
        }
    }
</script>

