<template>
    <div>
        <div class="modal-body">
            <div class="center-block">
                <i class="fas fa-check big"></i> <h4 style="font-weight:bold">{{ $t('credit.resultTablePopUp.completedHeader1') }} <span class="special-word">{{calculationName}}</span> {{ $t('credit.resultTablePopUp.completedHeader2') }} <span class="special-word">{{applicantName}}</span></h4>
            </div>
            <br />
            <p><strong>{{ $t('credit.resultTablePopUp.moreInfo') }}</strong></p>
            <ul class="modelationInfo">
                <li> <i :class="['fas', { 'fa-check' : savedCalculationInfo.RatesRequestSaved }, { 'fa-times' : !savedCalculationInfo.RatesRequestSaved }]"></i> <strong>{{ $t('credit.resultTablePopUp.rates') }}</strong></li>
                <li> <i :class="['fas', { 'fa-check' : savedCalculationInfo.RatesResponseSaved }, { 'fa-times' : !savedCalculationInfo.RatesResponseSaved }]"></i> <strong>{{ $t('credit.resultTablePopUp.ratesPrint') }}</strong></li>
                <li v-if="showBonita"> <i :class="['fas', { 'fa-check' : savedCalculationInfo.CreditWorthinessRequestSaved }, { 'fa-times' : !savedCalculationInfo.CreditWorthinessRequestSaved }]"></i> <strong>{{ $t('credit.resultTablePopUp.credit') }}</strong></li>
                <li v-if="showBonita"> <i :class="['fas', { 'fa-check' : savedCalculationInfo.CreditWorthinessResponseSaved }, { 'fa-times' : !savedCalculationInfo.CreditWorthinessResponseSaved }]"></i> <strong>{{ $t('credit.resultTablePopUp.creditPrint') }}</strong></li>
            </ul>
        </div>
        <div class="modal-footer">
            <button type="button"
                    class="btn btn-default pull-left"
                    data-dismiss="modal">
                <img class="left" src="@/assets/icons/X.svg" />{{ $t('credit.resultTablePopUp.buttons.cancel') }}
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SaveCompleted',
        data() {
            return {
                showBonita: false
            }
        },
        props: {
            applicantName: String,
            calculationName: String,
            savedCalculationInfo: {}
        },
        mounted() {
            this.axios.post("/WebServices/StoredCalculations.asmx/ShowBonita", {
                headers: { Accept: 'application/json' }
            })
                .then(response => {
                    var parsedResponse = JSON.parse(response.data.d);

                    if (parsedResponse.ResponseStatusCode == 200) {
                        this.showBonita = parsedResponse.Data;
                    }
                });
        }
    }
</script>

<style>
    .special-word {
        color: darkgrey;
        overflow-wrap: break-word;
    }

    .fa-check.big {
        color: forestgreen;
        font-size: 30px;
        float: left;
        padding-right: 10px;
        margin-top: -8px;
    }

    ul.modelationInfo {
        list-style-type: none;
    }

        ul.modelationInfo > li > i {
            padding-right: 8px;
            font-size: 18px;
            width: 30px;
        }

            ul.modelationInfo > li > i.fa-check {
                color: #228b22;
            }

            ul.modelationInfo > li > i.fa-times {
                color: red;
                padding-left: 2px;
            }
</style>