<template>
    <div :id="selectId" class="btn-group btn-group-justified btn-group-bordered" role="group">
        <div class="btn-group" role="group" v-for="(value, index) in items" v-bind:key="index">
            <button type="button" 
                    class="btn" 
                    v-on:click="onChange(index, value)" 
                    :style="{ 'font-size': fontSize }">
                {{value.name}}
            </button>
        </div>
    </div>
</template>

<script>


    export default {
        name: 'v-button-select', data() {
            return {
                buttonValue: this.selectedValue
            }
        },
        methods: {
            onChange: function (index, value) {
                //set and send value
                this.buttonValue = value.value;
                this.$emit('update:selectedValue', this.buttonValue);
                this.$emit('change', this.buttonValue);
            },
            newSelectedValue: function () {
                var select = document.getElementById(this.selectId);
                if (select) {
                    var buttons = select.getElementsByTagName('button');
                    if (!buttons.length) return;
                    [].forEach.call(buttons, function (el) {
                        el.classList.remove("btn-primary");
                    });
                    buttons[this.selectedIndex].classList.add('btn-primary');
                }
            }
        },
        computed: {
            selectedIndex: function () {
                for (var i = 0; i < this.items.length; i++) {
                    if (this.items[i].value == this.buttonValue)
                        return i;
                }
                return 0;
            }
        },
        watch: {
            buttonValue: function () {
                this.newSelectedValue();
            },
            selectedValue: function (val) {
                this.buttonValue = val;
                this.newSelectedValue();
            }
        },
        props: {
            items: Array,
            selectedValue: String,
            selectId: {
                type: String,
                default: 'button-select-' + Math.floor(Math.random() * 101)
            },
            fontSize: {
                type: String,
                default: '14px'
            }
        },
        mounted: function () {
            this.newSelectedValue();
        }
    }
</script>

<style scoped>
    .btn-group-bordered .btn{
        border: 1px solid silver;
    }

    .btn-group{
        height: 100%;
    }

    .btn {
        height: 100%;
        white-space: wrap;
    }
</style>