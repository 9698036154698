<template>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.prijemZKapitalovehoMajetku')" methodologyItem='"Príjem z divident akcionára"' methodologyLocalization="sk" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.prijemZKapitalovehoMajetku')" v-model="applicant.incomes.otherIncomesCz.prijemZKapitalovehoMajetku" number="true" :addonString="$t('global.currencySymbol')" inputId="capitalAssets"></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.starobniDuchod')" methodologyItem='"Dôchodok Starobný"' methodologyLocalization="sk" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.starobniDuchod')" v-model="applicant.incomes.otherIncomesCz.starobniDuchod" number="true" :addonString="$t('global.currencySymbol')" inputId="pension"></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.cestovne')" methodologyItem='"Diéty"' methodologyLocalization="sk" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.cestovne')" v-model="applicant.incomes.otherIncomesCz.cestovne" number="true" :addonString="$t('global.currencySymbol')" inputId="travelExpenses"></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.vysluha')" methodologyItem='"Dôchodok Výsluhový"' methodologyLocalization="sk" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.vysluha')" v-model="applicant.incomes.otherIncomesCz.vysluha" number="true" :addonString="$t('global.currencySymbol')" inputId="retairementRent"></v-labeled-input-methodology-link>
            </div>            
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.rodicovskyPrispevek')" methodologyItem='"Rodičovský príspevok / Materská"' methodologyLocalization="sk" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.rodicovskyPrispevek')" v-model="applicant.incomes.otherIncomesCz.rodicovskyPrispevek" number="true" :addonString="$t('global.currencySymbol')" inputId="parentalAllowance"></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.invalidniDuchod')" methodologyItem='"Dôchodok Invalidný"' methodologyLocalization="sk" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.invalidniDuchod')" v-model="applicant.incomes.otherIncomesCz.invalidniDuchod" number="true" :addonString="$t('global.currencySymbol')" inputId="thirdGradeInvalidPension"></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.vyzivne')" methodologyItem='"Výživné"' methodologyLocalization="sk" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.vyzivne')" v-model="applicant.incomes.otherIncomesCz.vyzivne" number="true" :addonString="$t('global.currencySymbol')" inputId="alimonyIncome"></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.invalidniDuchod12')" methodologyItem='"Dôchodok Invalidný"' methodologyLocalization="sk" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.invalidniDuchod12')" v-model="applicant.incomes.otherIncomesCz.invalidniDuchod12" number="true" :addonString="$t('global.currencySymbol')" inputId="firstAndSecondGradeInvalidPension"></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.odmenaPestouna')" methodologyItem='"Odmena pestúna"' methodologyLocalization="sk" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.odmenaPestouna')" v-model="applicant.incomes.otherIncomesCz.odmenaPestouna" number="true" :addonString="$t('global.currencySymbol')" inputId="fosterParentReward"></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.vdovskyDuchod')" methodologyItem='"Dôchodok Vdovský/Vdovecký"' methodologyLocalization="sk" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.vdovskyDuchod')" v-model="applicant.incomes.otherIncomesCz.vdovskyDuchod" number="true" :addonString="$t('global.currencySymbol')" inputId="widowPension"></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.prispevekNaOsobniAsistenci')" methodologyItem='"Príspevok za osobnú asistenciu"' methodologyLocalization="sk" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.prispevekNaOsobniAsistenci')" v-model="applicant.incomes.otherIncomesCz.prispevekNaOsobniAsistenci" number="true" :addonString="$t('global.currencySymbol')" inputId="personalAsistance"></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.sirotciDuchod')" methodologyItem='"Dôchodok Sirotský"' methodologyLocalization="sk" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.sirotciDuchod')" v-model="applicant.incomes.otherIncomesCz.sirotciDuchod" number="true" :addonString="$t('global.currencySymbol')" inputId="orphanPension"></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.prispevekNaBydleni')" methodologyItem='"Príspevok vojaka na bývanie"' methodologyLocalization="sk" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.prispevekNaBydleni')" v-model="applicant.incomes.otherIncomesCz.prispevekNaBydleni" number="true" :addonString="$t('global.currencySymbol')" inputId="housingAllowance"></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.urazovaRenta')" methodologyItem='"Úrazová renta"' methodologyLocalization="sk" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.urazovaRenta')" v-model="applicant.incomes.otherIncomesCz.urazovaRenta" number="true" :addonString="$t('global.currencySymbol')" inputId="injuryPension"></v-labeled-input-methodology-link>
            </div>
        </div>
    </div>
</template>

<script>
    //import LabeledInput from '../LabeledInput.vue'
    import LabeledInputMethodologyLink from '../LabeledInputMethodologyLink.vue'
    

    export default {
        name: 'v-incomes-other-modal',
        components: {
            //'v-labeled-input': LabeledInput,
            'v-labeled-input-methodology-link': LabeledInputMethodologyLink
        },
        watch: {
            'applicant.incomes.otherIncomesCz': {
                handler() {
                    {
                        this.$emit('update:applicant', this.applicant);
                    }
                },
                deep: true
            }
        },
        methods: {
            checkChildIncome() {
                //SK nekontroluji
                this.$emit('saveChanges');
            }
        },
        props: {
            applicant: Object
        }
    }
</script>

