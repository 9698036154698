import * as Vue from 'vue'
import { createI18n } from 'vue-i18n'
import { localeMessages } from './resources.js'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueNumeric from '@handcrafted-market/vue3-numeric';
import vSelect from 'vue-select'
import { createGtm } from '@gtm-support/vue-gtm';
import App from './App.vue'
import AppStoredCalc from './AppStoredCalc.vue'
import AppSavePopup from './AppSavePopup.vue'
import AppSendPdfDataExtended from './AppSendPdfDataExtended.vue'
import AppMenu from './AppMenu.vue'

import 'vue-select/dist/vue-select.css';

const i18n = createI18n({
    locale: document.documentElement.lang,
    messages: localeMessages,
})

const vueappMixin = {
    methods: {
        gtmTrackClickUI: function (eventName, data = {}) {
            var trackObject = {
                event: "clickUI", // Event type [default = 'interaction'] (Optional)
                eventLocation: "Hypo",
                eventName: eventName,
                category: 'Hypocalculator',
                action: 'click',
                label: 'UI clicked',
            }

            if (data.amount !== undefined) trackObject.amount = data.amount;
            if (data.loanPurpose !== undefined) trackObject.loanPurpose = data.loanPurpose;
            if (data.propertyType !== undefined) trackObject.propertyType = data.propertyType;
            if (data.partner !== undefined) trackObject.partner = data.partner;
            if (data.payment !== undefined) trackObject.payment = data.payment;
            if (data.product !== undefined) trackObject.product = data.product;
            if (data.rate !== undefined) trackObject.rate = data.rate;
            if (data.rpsn !== undefined) trackObject.rpsn = data.rpsn;
            if (data.applicantAge !== undefined) trackObject.applicantAge = data.applicantAge;
            if (data.applicantsCount !== undefined) trackObject.applicantsCount = data.applicantsCount;
            if (data.fixation !== undefined) trackObject.fixation = data.fixation;
            if (data.maturity !== undefined) trackObject.maturity = data.maturity;
            if (data.fixationYears !== undefined) trackObject.fixationYears = data.fixationYears;
            if (data.installment !== undefined) trackObject.installment = data.installment;

            if (this.$gtm) {
                this.$gtm.trackEvent(trackObject);
            }
        },
        gtmTrackResult: function (data = {}) {
            var trackObject = {
                event: "clickUI", // Event type [default = 'interaction'] (Optional)
                eventLocation: "Hypo",
                eventName: "Nabídka banky",
                category: 'Hypocalculator',
                action: 'result',
                label: 'Nabídka banky',
                noninteraction: true,
            }

            if (data.partner !== undefined) trackObject.partner = data.partner;
            if (data.rate !== undefined) trackObject.rate = data.rate;
            if (data.rpsn !== undefined) trackObject.rpsn = data.rpsn;
            if (data.installment !== undefined) trackObject.installment = data.installment;
            if (this.$gtm) {
                this.$gtm.trackEvent(trackObject);
            }
        }
    }
}

const vueapp = Vue.createApp(App);
vueapp.use(i18n);
vueapp.use(VueAxios, axios);
vueapp.component("vue-numeric", VueNumeric);
vueapp.component('v-select', vSelect)
vueapp.directive('bPopover', (el, binding) => {
    const options = {
        container: 'body', // Appends the popover to the body to avoid any z-index issues
        content: binding.value,
        trigger: 'hover',
        placement: 'auto'
    };
    $(el).popover(options);
});
vueapp.directive('bTooltip', (el, binding) => {
    const options = {
        container: 'body', // Appends the popover to the body to avoid any z-index issues
        title: binding.value,
        html: true,
        placement: 'auto left'
    };
    $(el).tooltip(options);
});
vueapp.use(
    createGtm({
        id: 'GTM-PQXT3FF', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
        queryParams: {
            // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
            gtm_auth: 'yeQzAGmuIxMeI01GMfDTnA',
            gtm_preview: 'env-5',
            gtm_cookies_win: 'x',
        },
        enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: false, // Whether or not display console logs debugs (optional)
        loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    }),
);
vueapp.mixin(vueappMixin);
vueapp.mount('#app');
global.vueapp = vueapp;

const vueappPopup = Vue.createApp(AppSavePopup);
vueappPopup.use(i18n);
vueappPopup.use(VueAxios, axios);
vueappPopup.component('v-select', vSelect)
vueappPopup.mount('#store-calculation-popup-app');

const vueappCalculations = Vue.createApp(AppStoredCalc);
vueappCalculations.use(i18n);
vueappCalculations.use(VueAxios, axios);
vueappCalculations.component("vue-numeric", VueNumeric);
vueappCalculations.directive('bTooltip', (el, binding) => {
    const options = {
        container: 'body', // Appends the popover to the body to avoid any z-index issues
        title: binding.value,
        placement: 'auto top'
    };
    $(el).tooltip(options);
});
vueappCalculations.mount('#stored-calculations-app');

const vueappSendPdf = Vue.createApp(AppSendPdfDataExtended);
vueappSendPdf.use(i18n);
vueappSendPdf.mount('#send-pdf-popup-app');

const vueappMenu = Vue.createApp(AppMenu);
vueappMenu.use(i18n);
vueappMenu.use(VueAxios, axios);
vueappMenu.mount('#menu-app');