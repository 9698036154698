<template>
    <div id="applicantSelection">
        <div class="modal-body">
            <div class="center-block">
                <div class="info-panel">
                    <h5 style="font-weight: bold">{{ $t('credit.resultTablePopUp.applicantSelectionTitle') }}</h5>
                    <p>{{ $t('credit.resultTablePopUp.applicantSelectionSubtitle') }}</p>
                    <p>{{ $t('credit.resultTablePopUp.applicantSelectionSubtitle2') }}</p>
                </div>

                <div v-for="alert in alerts" v-bind:key="alert" :class="['alert', alert.class ]" role="alert">
                    {{ alert.message }}
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <v-labeled-select-list selectName="searchhjhbj"
                                               label="Jméno klienta"
                                               v-model="applicantId"
                                               :items="applicantsNames" />
                    </div>
                </div>

                <table class="table table-bordered applicantSelection">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{{ $t('credit.resultTablePopUp.applicantSelectionTableColumnName') }}</th>
                            <th>{{ $t('credit.resultTablePopUp.applicantSelectionTableColumnDate') }}</th>
                            <th>{{ $t('credit.resultTablePopUp.applicantSelectionTableColumnNote') }}</th>
                        </tr>
                    </thead>
                    <tbody v-if="loaded && applicants?.length">
                        <template v-for="applicant in applicants" v-bind:key="applicant.id">
                            <tr @click="markRow(applicant.Id, applicant.Name, $event)" class="cursor-pointer applicant">
                                <td><i v-if="applicant.Calculations?.length !== 0" class="fas fa-plus" @click.stop.prevent="toggleCalculations($event)"></i></td>
                                <td>{{ applicant.Name }}</td>
                                <td>{{ date(applicant.Date) }} {{ time(applicant.Date) }}</td>
                                <td>-</td>
                            </tr>
                            <tr v-for="calculation in applicant.Calculations"
                                v-bind:key="calculation.id"
                                class="calculation"
                                style="display: none;"
                                @click="markCalcRow(applicant.Id, applicant.Name, calculation.Id, calculation.Name, calculation.Note, $event)">
                                <td></td>
                                <td>{{ calculation.Name }}</td>
                                <td>{{ date(calculation.Date) }} {{ time(calculation.Date) }}</td>
                                <td>{{ calculation.Note?.length > 32? calculation.Note.substring(0,32) + "..." : calculation.Note }}</td>
                            </tr>
                        </template>
                    </tbody>
                    <tbody v-else>
                        <tr v-if="!loaded && applicantId">
                            <td colspan="5" style="text-align:center"><i class="fas fa-spinner fa-spin"></i> {{ $t('credit.resultTablePopUp.applicantSelectionDataLoading') }}</td>
                        </tr>
                        <tr v-if="!loaded && !applicantId">
                            <td colspan="5" style="text-align:center">{{ $t('credit.resultTablePopUp.useSearch') }}</td>
                        </tr>
                        <tr v-if="loaded">
                            <td colspan="5" style="text-align:center">{{ $t('credit.resultTablePopUp.applicantSelectionNoData') }}</td>
                        </tr>
                    </tbody>
                </table>

                <div class="input-box">
                    <v-labeled-input :label="$t('credit.resultTablePopUp.modelationNameInputLabel')"
                                     v-model="localCalculationName"
                                     inputSize="70"
                                     isRequired="true"
                                     :maxLength="32"
                                     :errors="calculationNameErrors" />
                    <span style="color:red">{{calculationNameErrors}}</span>
                    <v-labeled-input :label="$t('credit.resultTablePopUp.modelationNoteInputLabel')"
                                     v-model="localCalculationNote"
                                     inputSize="70" />
                </div>
            </div>
        </div>

        <div class="modal-footer">
            <button type="button"
                    class="btn btn-default pull-left"
                    data-dismiss="modal">
                <img class="left" src="@/assets/icons/X.svg" />{{ $t('credit.resultTablePopUp.buttons.cancel') }}
            </button>
            <span v-if="namesAreValid"
                  style="margin-left: 5px;"
                  v-bTooltip="$t('credit.resultTablePopUp.buttons.nameRequirements')">
                <button type="button"
                        class="btn btn-primary"
                        disabled="true">
                    <img class="left" src="@/assets/icons/savesmall.svg" />{{ $t('global.save') }}
                </button>
            </span>
            <button v-else
                    type="button"
                    class="btn btn-primary"
                    id="selSaveBtn"
                    @click="saveCalcCheck">
                <img class="left" src="@/assets/icons/savesmall.svg" />{{ $t('global.save') }}
            </button>
        </div>

        <div class="modal fade" id="confirmOverrideModal" tabindex="-1" role="dialog" aria-labelledby="confirmModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-sm" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title"> {{ $t('credit.resultTablePopUp.confirmTitle') }} </h4>
                        <button type="button" class="close" data-dismiss="modal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <span>{{ $t('credit.resultTablePopUp.confirmText') }}</span>

                        <div class="button-placeholder">
                            <button type="button" :class="['btn btn-default']" data-dismiss="modal">Zpět</button>
                            <button type="button" :class="['btn btn-default']" @click="saveCalc">Ano</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import LabeledInput from '@/components/LabeledInput.vue'
    import LabeledSelectList from '@/components/LabeledSelectList.vue'

    export default {
        name: 'ApplicantSelection',
        data() {
            return {
                applicants: [],
                markedApplicantId: 0,
                markedCalculationId: 0,
                alerts: [],
                loaded: false,
                localCalculationName: this.calculationName,
                localCalculationNote: '',
                calculationNameErrors: '',
                applicantId: '',
                applicantsNames: []
            }
        },
        components: {
            'v-labeled-input': LabeledInput,
            'v-labeled-select-list': LabeledSelectList
        },
        computed: {
            namesAreValid: function () {
                return (!this.localCalculationName.trim() || !this.markedApplicantId != 0);
            }
        },
        methods: {
            date: function (date) {
                var ass = new Date(date);
                return ass.toLocaleDateString();
            },
            time: function (date) {
                var ass = new Date(date);
                return ass.toLocaleTimeString();
            },
            markRow: function (applicantId, applicantName, event) {
                this.markedApplicantId = applicantId;
                this.markedCalculationId = 0;
                this.calculationNameErrors = '';
                this.$emit('update:applicantName', applicantName);

                $('.table tr.bg-primary').removeClass('bg-primary');
                $(event.target.parentElement).addClass('bg-primary');
            },
            markCalcRow: function (applicantId, applicantName, calculationId, calculationName, calculationNote, event) {
                this.markedApplicantId = applicantId;
                this.markedCalculationId = calculationId;
                this.calculationNameErrors = '';
                this.$emit('update:applicantName', applicantName);

                this.localCalculationName = calculationName;
                this.localCalculationNote = calculationNote;

                $('.table tr.bg-primary').removeClass('bg-primary');
                $(event.target.parentElement).addClass('bg-primary');
            },
            toggleCalculations: function (event) {
                var target = event.target;
                $(target).toggleClass('fa-plus').toggleClass('fa-minus');
                $(target.parentElement.parentElement).nextUntil('.applicant').toggle();
            },
            saveCalcCheck() {
                $('#selSaveBtn').attr("disabled", true);
                $('#selSaveBtn').html('<i class="fas fa-spinner fa-spin"></i>');
                if (this.markedCalculationId != 0) $('#confirmOverrideModal').modal();
                else this.saveCalc();
            },
            saveCalc: function () {
                $('#confirmOverrideModal').modal('hide');
                $('#selSaveBtn').attr("disabled", true);
                $('#selSaveBtn').html('<i class="fas fa-spinner fa-spin"></i>');

                if (this.markedCalculationId != 0) {
                    this.removeCalcAndSaveNew();
                }
                else {
                    this.saveCalcRequest();
                }
            },
            saveCalcRequest() {
                this.axios.post("/WebServices/StoredCalculations.asmx/SaveCalculation", {
                    headers: { Accept: 'application/json' },
                    applicantId: this.markedApplicantId,
                    calculationName: this.calculationName,
                    calculationNote: this.localCalculationNote,
                    userInfo: null
                })
                    .then(response => {
                        var parsedResponse = JSON.parse(response.data.d);
                        if (parsedResponse.ResponseStatusCode == 200) {
                            this.$emit('set-saved-calculation-info', parsedResponse.Data);
                            this.$emit('update:stepName', 'completed');
                        }
                        if (parsedResponse.ResponseStatusCode == 404)
                            this.alerts.push({ class: "alert-danger", message: parsedResponse.Data });
                        if (parsedResponse.ResponseStatusCode == 500)
                            this.alerts.push({ class: "alert-danger", message: parsedResponse.Data });
                        if (parsedResponse.ResponseStatusCode == 406)
                            this.alerts.push({ class: "alert-danger", message: parsedResponse.Data });
                        if (parsedResponse.ResponseStatusCode == 409)
                            this.calculationNameErrors = this.$t('credit.resultTablePopUp.calculationNameError');

                        $('#selSaveBtn').removeAttr("disabled");
                        $('#selSaveBtn').text('Uložit');
                    })
            },
            removeCalcAndSaveNew() {
                this.axios.post("/WebServices/StoredCalculations.asmx/RemoveCalculation", {
                    headers: { Accept: 'application/json' },
                    calculationId: this.markedCalculationId,
                })
                    .then(response => {
                        var parsedResponse = JSON.parse(response.data.d);

                        if (parsedResponse.ResponseStatusCode == 200)
                            this.saveCalcRequest();
                        
                        if (parsedResponse.ResponseStatusCode == 403)
                            this.alerts.push({ class: "alert-danger", message: parsedResponse.Data });
                        if (parsedResponse.ResponseStatusCode == 404)
                            this.alerts.push({ class: "alert-danger", message: parsedResponse.Data });
                        if (parsedResponse.ResponseStatusCode == 500) {
                            this.alerts.push({ class: "alert-danger", message: parsedResponse.Data });
                        }
                    })
            }
        },
        watch: {
            localCalculationName: function () {
                this.$emit('update:calculationName', this.localCalculationName);
                this.calculationNameErrors = '';
            },
            applicantId: function () {
                this.loaded = false;
                this.axios.post("/WebServices/StoredCalculations.asmx/GetApplicant", {
                    headers: { Accept: 'application/json' },
                    applicantId: this.applicantId,
                })
                    .then(response => {
                        var parsedResponse = JSON.parse(response.data.d);
                        if (parsedResponse.ResponseStatusCode == 200)
                            this.applicants = [parsedResponse.Data];
                        else {
                            this.alerts.push({ class: "alert-danger", message: parsedResponse.Data });
                        }
                        this.loaded = true;
                        this.markedApplicantId = this.applicantId;
                    })
            }
        },
        props: {
            calculationName: String
        },
        mounted() {
            this.axios.post("/WebServices/StoredCalculations.asmx/GetApplicantsNames", {
                headers: { Accept: 'application/json' }
            })
                .then(response => {
                    var parsedResponse = JSON.parse(response.data.d);
                    if (parsedResponse.ResponseStatusCode == 200)
                        this.applicantsNames = parsedResponse.Data;
                    else {
                        this.alerts.push({ class: "alert-danger", message: parsedResponse.Data });
                    }
                })
        }
    }
</script>

<style>
    .table.applicantSelection th {
        padding: 8px !important;
    }

        .table.applicantSelection th:first-child, .table.applicantSelection td:first-child {
            width: 30px;
        }

    .cursor-pointer {
        cursor: pointer;
    }

    tr.applicant > td:nth-child(2) {
        font-weight: bold;
    }

    tr.calculation > td:nth-child(2) {
        padding-left: 20px;
    }

    #confirmOverrideModal button.btn-default {
        margin: 30px 30px 10px;
    }
</style>
