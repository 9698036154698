<template>
    <div class="modal-body">
        <div class="row" style="padding-bottom: 10px;">
            <div class="col-md-6">
                <v-labeled-input :label="$t('credit.expensesModal.expensesLoans')"
                                 :labelTooltip="$t('tooltip.credit.applicant.expenses.loans')"
                                 v-model="applicant.expenses.loans"
                                 number="true"
                                 :addonString="$t('global.currencySymbol')"
                                 inputId="loanRepayment" />
            </div>
            <div class="col-md-6">
                <v-labeled-input :label="$t('credit.expensesModal.sumOfAllBalances')"
                                 :labelTooltip="$t('tooltip.credit.expensesModal.sumOfAllBalances')"
                                 v-model="applicant.sumOfAllLoanBalances"
                                 number="true"
                                 :addonString="$t('global.currencySymbol')"
                                 inputId="loanSum" />
            </div>

            <div class="col-md-6">
                <v-labeled-input :label="$t('credit.expensesModal.mortgageLoans')"
                                 :labelTooltip="$t('tooltip.credit.expensesModal.mortgageLoans')"
                                 v-model="applicant.expenses.mortgageLoans"
                                 number="true"
                                 :addonString="$t('global.currencySymbol')"
                                 inputId="mortgageLoanRepayment" />
                <a href="#" data-toggle="modal" data-target="#stressModal" style="">Stresovat splátku</a>
            </div>
            <div class="col-md-6">
                <v-labeled-input :label="$t('credit.expensesModal.sumOfAllMortgagesLoanBalances')"
                                 :labelTooltip="$t('tooltip.credit.expensesModal.sumOfAllMortgagesLoanBalances')"
                                 v-model="applicant.expenses.sumOfAllMortgageLoanBalances"
                                 number="true"
                                 :addonString="$t('global.currencySymbol')"
                                 inputId="mortgageLoanSum" />
            </div>

        </div>
        <div class="row">
            <div class="col-md-6">
                <v-labeled-input :label="$t('credit.expensesModal.expensesCreditCardLimits')"
                                 :labelTooltip="$t('tooltip.credit.expensesModal.expensesCreditCardLimits')"
                                 v-model="applicant.expenses.creditCardLimits"
                                 number="true"
                                 :addonString="$t('global.currencySymbol')"
                                 inputId="creditCardLimit" />
            </div>
            <div class="col-md-6">
                <v-labeled-input :label="$t('credit.expensesModal.expensesOverdraftLimits')"
                                 :labelTooltip="$t('tooltip.credit.expensesModal.expensesOverdraftLimits')"
                                 v-model="applicant.expenses.overdraftLimits"
                                 number="true"
                                 :addonString="$t('global.currencySymbol')"
                                 inputId="overdraftLimit" />
            </div>
            <div class="col-md-6">
                <v-labeled-input :label="$t('credit.expensesModal.expensesAlimony')"
                                 :labelTooltip="$t('tooltip.credit.expensesModal.expensesAlimony')"
                                 v-model="applicant.expenses.alimony"
                                 number="true"
                                 :addonString="$t('global.currencySymbol')"
                                 inputId="alimonyExpense" />
            </div>
            <div class="col-md-6">
                <v-labeled-input :label="$t('credit.expensesModal.expensesLiability')"
                                 :labelTooltip="$t('tooltip.credit.expensesModal.expensesLiability')"
                                 v-model="applicant.expenses.liability"
                                 number="true"
                                 :addonString="$t('global.currencySymbol')"
                                 inputId="liability" />
            </div>
            <div class="col-md-6">
                <v-labeled-input :label="$t('credit.expensesModal.monthlyWageDeduction')"
                                 :labelTooltip="$t('tooltip.credit.expensesModal.monthlyWageDeduction')"
                                 v-model="applicant.expenses.monthlyWageDeduction"
                                 number="true"
                                 :addonString="$t('global.currencySymbol')"
                                 inputId="monthlyWageDeduction" />
            </div>
            <div class="col-md-6 empty-form-group"></div>
        </div>

        <div class="modal fade modal-stress" id="stressModal" tabindex="-1" role="dialog" aria-labelledby="stressModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" id="stressModalLabel">Výpočet stresovej splátky HU</h4>
                        <button type="button" class="close" @click="hideStressModal" aria-label="$t('credit.incomesTSModal.closeWindow')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <v-labeled-input label="Aktuálny zostatok"
                                                 v-model="zustatek"
                                                 number="true"
                                                 :addonString="$t('global.currencySymbol')"
                                                 inputId="aktualnyZostatok" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <v-labeled-input label="Aktuálna úroková sadzba"
                                                 v-model="urokovaSazba"
                                                 number="true"
                                                 addonString="%"
                                                 precision="2"
                                                 inputId="aktualnaUrokovaSadza" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <v-labeled-maturity-select-list selectName="dobaSplatnostiRoky"
                                                                selectName2="dobaSplatnostiMesice"
                                                                label="Zostávajúca doba splatnosti HÚ"
                                                                v-model="splatnost"
                                                                :items="maturities"
                                                                :items2="maturitiesMonths" />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button"
                                class="btn btn-default pull-left"
                                @click="hideStressModal">
                            <img class="left" src="@/assets/icons/X.svg" />{{ $t('global.cancel') }}
                        </button>
                        <button type="button" 
                                class="btn btn-primary"
                                @click="countStressSplatky" 
                                id="btnCountStressPayment">
                            Prepočítať
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LabeledInput from '../LabeledInput.vue'
    import LabeledMaturitySelectList from '../LabeledMaturitySelectList.vue'

    export default {
        name: 'v-expenses-body',
        data() {
            return {
                zustatek: this.applicant.expenses.sumOfAllMortgageLoanBalances,
                urokovaSazba: 0,
                splatnost: 360
            };
        },
        components: {
            'v-labeled-input': LabeledInput,
            'v-labeled-maturity-select-list': LabeledMaturitySelectList,
        },
        methods: {
            countStressSplatky() {
                let mSplatnost = this.splatnost;
                let sazba = (this.urokovaSazba + 2) / 100; // +2% zvýšení úrokové sazby pro stressování splátky
                if (!sazba) {
                    return this.zustatek / mSplatnost;
                }
                let num2 = 1.0 + sazba / 12.0;
                let stressSplatka = this.zustatek * Math.pow(num2, mSplatnost) * (num2 - 1.0) / (Math.pow(num2, mSplatnost) - 1.0);
                this.applicant.expenses.mortgageLoans = Math.round(stressSplatka);
                this.applicant.expenses.sumOfAllMortgageLoanBalances = this.zustatek;
                this.hideStressModal();
            },
            hideStressModal() {
                $('#stressModal').modal('hide');
            }
        },
        watch: {
            'applicant.expenses': {
                handler() {
                    this.$emit('update:applicant', this.applicant);
                },
                deep: true
            },
            'applicant.sumOfAllLoanBalances': {
                handler() {
                    this.$emit('update:applicant', this.applicant);
                },
                deep: true
            },
        },
        props: {
            applicant: Object,
            maturities: Array,
            maturitiesMonths: Array
        }
    }
</script>
