<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div class="panelZadatel">
        <span v-if="showDelete" class="action-icon-circle">
            <img class="action-icon"
                 src="@/assets/icons/X.svg"
                 @click="deleteApplicant"
                 v-bTooltip="$t('global.delete')" />
        </span>
        <span :class="['action-icon-circle', { 'moved': showDelete }]">
            <img class="action-icon"
                 src="@/assets/icons/update-auto.svg"
                 @click="resetApplicant"
                 v-bTooltip="$t('global.defaultValues')" />
        </span>

        <!--<i v-if="showDelete" class="fas fa-times fa-in-circle" @click="deleteApplicant" v-bTooltip="$t('global.delete')"></i>
        <i :class="['fas fa-undo-alt fa-in-circle panelZadatelRevert',{ 'first': !showDelete}]" @click="resetApplicant" v-bTooltip="$t('global.defaultValues')"></i>-->
        <img class="applicant-person" src="@/assets/icons/expert.svg" />

        <div class="row">
            <div class="col-md-5">
                <v-labeled-input :label="$t('credit.applicant.name')" v-model="applicant.name"></v-labeled-input>
            </div>
            <div class="col-md-5">
                <v-labeled-input :label="$t('credit.applicant.lastname')" v-model="applicant.surename"></v-labeled-input>
            </div>
            <div class="col-md-2">
                <v-labeled-input :label="$t('credit.app.age')" 
                                 v-model="applicant.vek" 
                                 :addonString="$t('global.years')" 
                                 number="true" 
                                 numberMin="18" 
                                 numberMax="99"
                                 @input="applicantAgeChanged">
                </v-labeled-input>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <v-labeled-input :label="$t('credit.applicant.incomes.monthlyNetIncome')" :labelTooltip="$t('tooltip.credit.applicant.incomes.monthlyNetIncome')" v-model="applicant.incomes.monthlyNetIncome" :addonString="$t('global.currencySymbol')" number="true" inputId="monthlyNetIncome"></v-labeled-input>
            </div>
            <template v-if="settings.expensesProvider != 'sk'">
                <div class="col-md-4">
                    <v-labeled-input :label="$t('credit.expensesModal.expensesLoans')" :labelTooltip="$t('tooltip.credit.expensesModal.expensesLoans')" v-model="applicant.expenses.loans" :addonString="$t('global.currencySymbol')" number="true" inputId="loanInstallments" class="elipsisLabel"></v-labeled-input>
                </div>
                <div class="col-md-4">
                    <v-labeled-input :label="$t('credit.expensesModal.sumOfAllBalances')" :labelTooltip="$t('tooltip.credit.expensesModal.sumOfAllBalances')" v-model="applicant.sumOfAllLoanBalances" :addonString="$t('global.currencySymbol')" number="true" inputId="loansSum" class="elipsisLabel"></v-labeled-input>
                </div>
            </template>
        </div>

        <div class="row" @click="applicantModalLinkClicked">
            <div class="col-md-12">
                <div class="applicant-modal-buttons">
                    <button type="button"
                            :class="['btn btn-default', { 'bold': solidLinkIncomes }]"
                            id="prijmyModalBtn"
                            data-toggle="modal"
                            data-target="#prijmyDPModal"
                            v-if="settings.taxIncomesProvider">
                        {{ $t('credit.applicant.incomes.taxIncomes') }} <img class="mr-6" src="@/assets/icons/plus2.svg" />
                    </button>
                    <button type="button"
                            :class="['btn btn-default', { 'bold': solidLinkIncomesOtherModal }]"
                            id="prijmyOstatniModalBtn"
                            data-toggle="modal"
                            data-target="#prijmyOstatniModal">
                        {{ $t('credit.applicant.incomes.otherIncomes') }} <img class="mr-6" src="@/assets/icons/plus2.svg" />
                    </button>
                    <button type="button"
                            :class="['btn btn-default', { 'bold': solidLinkExpensesModal }]"
                            id="vydajeModalBtn"
                            data-toggle="modal"
                            data-target="#vydajeModal">
                        {{ $t('credit.applicant.expenses.otherExpenses') }} <img class="mr-6" src="@/assets/icons/plus2.svg" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LabeledInput from './LabeledInput.vue'

    import { ResetApplicant, ObjectsValuesAreNotEmpty } from '../js/HouseholdHelpers.mjs'



    export default {
        name: 'v-applicant',
        components: {
            'v-labeled-input': LabeledInput,
        },
        methods: {
            applicantModalLinkClicked: function () {
                this.$emit('applicantLinkClicked', this.index);
            },
            deleteApplicant: function () {
                $(".tooltip").tooltip("hide");
                this.$emit('deleteApplicant');
            },
            resetApplicant: function () {
                ResetApplicant(this);
            },
            applicantAgeChanged: function (event) {
                this.$emit('applicantAgeChanged', event.target.value, this.index);
            }
        },
        watch: {
            applicant: function () {
                this.$emit('update:applicant', this.applicant);
            }
        },
        computed: {
            solidLinkExpensesModal: function () {
                return (ObjectsValuesAreNotEmpty(this.applicant.expenses) || this.applicant.sumOfAllLoanBalances);
            },
            solidLinkIncomesOtherModal: function () {
                return ObjectsValuesAreNotEmpty(this.applicant.incomes.otherIncomesCz);
            },
            solidLinkIncomes: function () {
                return (this.applicant.activeSections.incomes.incomeType == 'ts' &&
                    (this.applicant.activeSections.incomes.incomeTS.incomeCheckboxes.prijmyPodnikani || this.applicant.activeSections.incomes.incomeTS.incomeCheckboxes.prijmyPronajem || this.applicant.incomes.taxSettlementLegalEntitySk?.taxSettlementBusinessSk?.businessIncome > 0))
                    || (this.applicant.activeSections.incomes.incomeType == 'flatRate' &&
                        (this.applicant.activeSections.incomes.incomeTaxFlatRate.incomeCheckboxes.prijmyPodnikani || this.applicant.activeSections.incomes.incomeTaxFlatRate.incomeCheckboxes.prijmyPronajem))
            }
        },
        props: {
            applicant: Object,
            index: Number,
            showDelete: Boolean,
            settings: Object,
            iconsLibrary: Object
        }
    }
</script>

<style>
    .panelZadatel i.fa-undo-alt {
        background-color: dimgray;
    }

        .panelZadatel i.fa-undo-alt:hover {
            background-color: black;
        }
</style>

<style scoped>
    .panelZadatel .row > *{
        padding: 0 8px;
    }

    h4 {
        margin-top: 0;
    }

    .applicant-label {
        font-size: 16px;
        color: rgb(85, 85, 85);
        font-weight: 500;
    }

    img.applicant-person {
        position: absolute;
        left: 30px;
        top: 20px;
        background-color: #fff;
        border-radius: 50%;
        padding: 10px;
    }

    @media (max-width: 600px) {
        img.applicant-person {
            position: unset;
            display: block;
            margin: 0 auto;
        }
    }

    .modal-content {
        background-color: #f5f5f5;
    }

        .modal-content > div {
            border: none;
        }




    .panelZadatelAddNew {
        color: gray;
    }

    .btn-primary {
        color: #337ab7;
        border-color: #337ab7;
        background-color: rgb(221, 221, 221);
        width: 115px;
        flex-shrink: 0;
    }

        .btn-primary:hover {
            background-color: rgb(51, 122, 183);
            color: #fff;
        }

    .inputRow {
        display: inline-flex;
        width: 100%;
        margin: 10px 0;
    }

        .inputRow .ageInput {
            width: 135px;
            padding-left: 10px;
        }

        .inputRow div:first-child {
            flex-grow: 2;
        }

    .inputButtonRow {
        display: inline-flex;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
    }

    .panelZadatel img.action-icon {
        width: 20px;
    }

    

    .panelZadatel span.action-icon-circle {
        background-color: #fff;
        cursor: pointer;
        position: absolute;
        right: 4px;
        top: -10px;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        padding-left: 4px;
    }

        .panelZadatel span.action-icon-circle:hover img.action-icon {
            filter: brightness(0) saturate(100%) invert(50%) sepia(8%) saturate(7287%) hue-rotate(186deg) brightness(81%) contrast(102%);
        }

        .panelZadatel span.action-icon-circle.moved {
            right: 34px;
        }

    .panelZadatel > i.fa-times {
        position: absolute;
        right: 6px;
        top: -10px;
        font-size: 12px;
        cursor: pointer;
        color: white;
        background-color: dimgray;
        border-radius: 10px;
    }

        .panelZadatel > i.fa-times:hover {
            background-color: black;
        }

    .panelZadatel > i.panelZadatelRevert {
        position: absolute;
        right: 30px;
        top: -10px;
    }

        .panelZadatel > i.panelZadatelRevert.first {
            right: 6px;
        }

    button.btn.btn-link {
        width: 120px;
    }

    button.bold {
        font-weight: 800;
    }

    .applicant-modal-buttons {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 5px 14px;
    }
</style>
