<template>
    <tr :class="{ 'active': rowActive  }">
        <td>
            <a target="_blank"
               @click="gtmTrackClickUI('Detail partnera', {partner: item.PartnerCode});"
               :href="'/Poskytovatel/' + item.PartnerCode">
                <div class="smallImgWrapper"
                     v-bTooltip="item.ProductName">
                    <img :src="item.PartnerLogoUrl" />
                </div>
            </a>
        </td>
        <template v-if="item.PartnerCode === 'OB'">
            <td colspan="12" style="text-align: center; height: 69px;">Úrokové sazby jsou stanoveny individuálně, pro aktuální sazbu kontaktujte pobočky banky.</td>
        </template>
        <template v-else>
            <td>
                <vue-numeric currency="%"
                             precision="2"
                             currency-symbol-position="suffix"
                             separator="space"
                             read-only="true"
                             v-model="item.Rate"
                             :class="[{ 'has-tooltip' : item.RateDescription }, { bold: sortProperty === sortProperties.rate || sortProperty.substring(1) === sortProperties.rate }] "
                             v-bTooltip="item.RateDescription"
                             @click="$emit('showRateEdit')"
                             class="clickable" />
            </td>

            <td>
                <vue-numeric :currency="$t('global.years')"
                             currency-symbol-position="suffix"
                             separator="space"
                             read-only="true"
                             v-model="fixace"
                             :class="{ bold: sortProperty === sortProperties.fixation || sortProperty.substring(1) === sortProperties.fixation }"></vue-numeric>
            </td>

            <td>
                <span :class="[{ 'has-tooltip' : item.RateDescription }, { bold: sortProperty === sortProperties.maturity || sortProperty.substring(1) === sortProperties.maturity }]"
                      v-bTooltip="item.MaturityDescription"
                      v-html="item.MaturityString">
                </span>
            </td>

            <td>
                <vue-numeric :currency="$t('global.currencySymbol')"
                             currency-symbol-position="suffix"
                             separator="space"
                             read-only="true"
                             v-model="item.Installment"
                             :class="[{ 'has-tooltip' : item.InstallmentDescription }, { bold: sortProperty === sortProperties.installment || sortProperty.substring(1) === sortProperties.installment }]"
                             v-bTooltip="item.InstallmentDescription" />
            </td>

            <td>
                <vue-numeric :currency="$t('global.currencySymbol')"
                             currency-symbol-position="suffix"
                             separator="space"
                             read-only="true"
                             v-model="item.MonthlyCosts"
                             :class="[{ 'has-tooltip' : item.MonthlyCostsDescription }, { bold: sortProperty === sortProperties.monthlyExpense || sortProperty.substring(1) === sortProperties.monthlyExpense || item.MonthlyCostsBold }]"
                             v-bTooltip="item.MonthlyCostsDescription" />
            </td>

            <td>
                <vue-numeric :currency="$t('global.currencySymbol')"
                             currency-symbol-position="suffix"
                             separator="space"
                             read-only="true"
                             v-model="item.ProcessingCosts"
                             :class="[{ 'has-tooltip' : item.ProcessingCostsDescription }, { bold: sortProperty === sortProperties.processingCosts || sortProperty.substring(1) === sortProperties.processingCosts || item.ProcessingCostsBold }]"
                             v-bTooltip="item.ProcessingCostsDescription" />
            </td>

            <td v-if="localization == 'cs'">
                <vue-numeric :currency="$t('global.currencySymbol')"
                             currency-symbol-position="suffix"
                             separator="space"
                             read-only="true"
                             v-model="item.FixCostsPerYear"
                             :class="[{ 'has-tooltip' : item.FixCostsPerYearDescription }, { bold: sortProperty === sortProperties.fixCostsPerYear || sortProperty.substring(1) === sortProperties.fixCostsPerYear }]"
                             v-bTooltip="item.FixCostsPerYearDescription" />
            </td>

            <td>
                <vue-numeric currency="%"
                             precision="2"
                             currency-symbol-position="suffix"
                             separator="space"
                             read-only="true"
                             v-model="rpsn"
                             :class="{ bold: sortProperty === sortProperties.rpsn || sortProperty.substring(1) === sortProperties.rpsn }"></vue-numeric>
            </td>

            <td v-if="localization == 'sk'">
                <vue-numeric :currency="$t('global.currencySymbol')"
                             currency-symbol-position="suffix"
                             separator="space"
                             read-only="true"
                             v-model="item.BalanceAfterFixation"
                             :class="[{ bold: sortProperty === sortProperties.balanceAfterFixation || sortProperty.substring(1) === sortProperties.balanceAfterFixation }]" />
            </td>

            <td>
                <b>
                    <template v-if="item.BonitaCalcState === 'Completed'">
                        <vue-numeric v-if="item.MaxAmount > 0"
                                     :currency="$t('global.currencySymbol')"
                                     currency-symbol-position="suffix"
                                     separator="space"
                                     read-only="true"
                                     v-model="item.MaxAmount"
                                     :style="{color: item.MaxAmountColor}"
                                     class="has-tooltip"
                                     v-bTooltip="item.MaxAmountDescription"></vue-numeric>
                        <span v-else
                              v-bTooltip="item.MaxAmountDescription">-</span>
                    </template>
                    <template v-else-if="item.BonitaCalcState === 'Loading'">
                        <i class="fas fa-spinner fa-spin"></i>
                    </template>
                    <template v-else>
                        <span v-bTooltip="item.MaxAmountDescription">-</span>
                    </template>
                </b>
            </td>

            <td>
                <div class="badge-holder">
                    <span v-for="prop in item.Properties"
                          :key="prop.Zkratka"
                          class="badge"
                          v-bTooltip="prop.CelyNazev"
                          :style="getPropColor(prop)">
                        {{ prop.Zkratka }}
                    </span>
                </div>
            </td>

            <td>
                <div class="button-cell">
                    <div>
                        <button type="button"
                                :class="['btn btn-default-gray', { selected: item.Selected }]"
                                @click="$emit('toggleSelectClicked')">
                            <img class="left" src="@/assets/icons/select-document.svg" />{{ $t('credit.resultTable.buttons.select') }}
                        </button>
                        <button type="button"
                                class="btn btn-default-gray"
                                @click="linkToChart">
                            <img class="chart left" src="@/assets/icons/splatky-graf.svg" />{{ $t('credit.resultTable.buttons.chart') }}
                        </button>
                    </div>
                </div>
            </td>
            <td>
                <div class="button-cell">
                    <div>
                        <div class="dropdown" v-if="dorisMortgagesAllowed && item.PartnerCode == 'HB'">
                            <button type="button"
                                    class="btn btn-primary btn-arrange dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-expanded="false">
                                {{ $t('credit.resultTable.buttons.arrange') }} <img class="mr-6" src="@/assets/icons/downFill.svg" />
                            </button>
                            <ul class="dropdown-menu dropdown-menu-right">
                                <li>
                                    <a :href="pricingOfferLink" :target="dorisLinkTarget"
                                       @click="gtmTrackClickUI('Sjednání cenové nabídky', {partner: item.PartnerCode});">Garance úrokové sazby</a>
                                </li>
                                <li>
                                    <a href="/Doris?login=true&redirectTo=/Mortgages/cz/Application/CreateNewApplication/HB" :target="dorisLinkTarget"
                                       @click="gtmTrackClickUI('Sjednání online žádosti', {partner: item.PartnerCode});">Online žádost</a>
                                </li>
                            </ul>
                        </div>

                        <a v-if="item.ShowOnlineContractLink" :href="item.OnlineContractLink" target="_blank" type="button" class="btn btn-primary btn-arrange"
                           @click="gtmTrackClickUI('Sjednání', {partner: item.PartnerCode});">{{ $t('credit.resultTable.buttons.arrange') }}</a>

                        <a v-if="loanParams.loanPurpose == 'Refinancing'"
                           :href="refinanceHrefLink"
                           target="_blank"
                           type="button"
                           class="btn btn-primary btn-arrange">
                            {{ $t('credit.resultTable.buttons.refinance') }}
                        </a>

                    </div>
                    <div class="row-arrow" v-if="!selectedOnly && !expandResults && isFirstInGroup && groupHasAlternatives">
                        <img v-if="groupVisible" src="@/assets/icons/upFill.svg" @click="toggleGroupRows" />
                        <img v-else src="@/assets/icons/downFill.svg" @click="toggleGroupRows" />
                    </div>
                </div>
            </td>


        </template>
    </tr>
</template>

<script>
    export default {
        name: 'v-result-table-item',
        props: {
            item: Object,
            loanParams: Object,
            isFirstInGroup: Boolean,
            groupHasAlternatives: Boolean,
            selectedOnly: Boolean,
            expandResults: Boolean,
            sortProperty: String,
            sortProperties: Array,
            dorisMortgagesAllowed: Boolean,
            dorisLinkTarget: String,
            localization: String
        },
        data() {
            return {
                groupVisible: false,
            }
        },
        computed: {
            calendarHrefLink: function () {
                return '/GuidRedirect.aspx?module=payments&params=' + encodeURIComponent(this.calendarParams);
            },
            refinanceHrefLink: function () {
                return '/GuidRedirect.aspx?module=refinance&params=' + encodeURIComponent(this.calendarParams);
            },
            calendarParams: function () {
                return '?product=' + this.item.PartnerCode
                    + '&amount=' + this.loanParams.loanAmount
                    + '&rate=' + this.item.Rate / 100
                    + '&maturity=' + this.item.Maturity
                    + '&fixation=' + this.item.Fixation
                ;
            },
            pricingOfferLink() {
                return '/Doris.aspx?action=newPricingOffer&code=HB&rate=' + this.item.Rate.toString().replace('.', ',')
                    + '&fixation=' + this.item.Fixation
                    + '&maturity=' + this.item.Maturity;
            },
            itemMaturityYears() {
                return Math.floor(parseInt(this.item.Maturity / 12));
            },
            itemMaturityMonths() {
                return parseInt(this.item.Maturity) % 12;
            },
            fixace() {
                return this.item.Fixation / 12;
            },
            rpsn() {
                return this.item.RPSN * 100;
            },
            rowActive() {
                if (this.selectedOnly) return this.item.Selected;
                if (this.expandResults) return true;
                return this.isFirstInGroup;
            }
        },
        methods: {
            formattedTooltip(items) {
                var val = '<table><tbody>';
                for (const el of items) {
                    val += '<tr><td class="text-right">' + el[0] + '</td><td class="text-right">' + el[1] + '</td></tr>';
                }
                val += '</tbody></table>';
                return val;
            },
            toggleGroupRows(event) {
                this.groupVisible = !this.groupVisible;
                $(event.target).closest('tr').siblings().toggleClass('active');
            },
            getPropColor(prop) {
                var color = 'rgb(181 211 249)';
                if (prop.TypSlevy == 'Akce') color = 'rgb(255, 175, 204)';
                if (prop.TypSlevy == 'Metodika') color = 'rgb(202, 202, 202)';
                //Ponecháno pro případ navrácení funkčnosti
                //var color = prop.ColorOfBubble.includes(',') ? 'rgba(' + prop.ColorOfBubble + ')' : prop.ColorOfBubble
                return 'background-color: ' + color;

            },
            linkToChart() {
                this.gtmTrackClickUI('Splátkový kalendář', { partner: this.item.PartnerCode });
                window.open(this.calendarHrefLink, '_blank');
            }
        }
    }
</script>

<style scoped>
    .badge-holder {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .bonitaMaturityColumn {
        padding: 1px 8px;
    }

    .button-cell {
        display: flex;
        gap: 0px 5px;
    }

        .button-cell > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

    .row-arrow {
        margin-left: auto;
    }

        .row-arrow > img {
            cursor: pointer;
            filter: brightness(0) saturate(100%) invert(42%) sepia(9%) saturate(5890%) hue-rotate(178deg) brightness(97%) contrast(94%);
        }

    .badge {
        float: right;
        margin: 1px;
        font-size: 11px;
        color: #333;
    }
</style>