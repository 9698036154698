<template>
    <div>
        <div class="modal fade" id="prijmyDPModal" tabindex="-1" ref="incomesTSModal" data-backdrop="static">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div v-if="settings.taxIncomesProvider == 'cz'" class="modal-header-select">
                        <v-button-select selectId="businessIncomeType"
                                         :items="[{name: 'Příjem z daňového přiznání', value: 'ts'}, {name: 'OSVČ - Paušální režim daně', value: 'flatRate'}]"
                                         :selectedValue="applicant.activeSections.incomes.incomeType"
                                         @update:selectedValue="val => applicant.activeSections.incomes.incomeType = val"
                                         fontSize="18px">
                        </v-button-select>
                        <button type="button" class="close" data-dismiss="modal" aria-label="$t('credit.incomesTSModal.closeWindow')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div v-else class="modal-header">
                        <h4 class="modal-title"> {{ $t('credit.incomesTSModal.incomesDP') }} </h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="$t('credit.incomesTSModal.closeWindow')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <component :applicant="applicant"
                               :occupations="occupations"
                               :czNaces="czNaces"
                               :flatRates="flatRates"
                               :childTaxReturns="childTaxReturns"
                               :tsProvider="settings.taxIncomesProvider"
                               v-bind:is="'v-incomes-ts-body-' + settings.taxIncomesProvider"
                               @saveChanges="saveChanges"
                               ref="incomesBody"
                               id="tsComponent" />

                    <v-incomes-flat-rate-tax-body :applicant="applicant"
                                                  :czNaces="czNaces"
                                                  :flatRates="flatRates"
                                                  :tsProvider="settings.taxIncomesProvider"
                                                  :closedTaxPeriods="closedTaxPeriods"
                                                  ref="flatRateTaxBody"
                                                  id="flatRateComponent" />

                    <div class="modal-footer">
                        <button type="button"
                                class="btn btn-default pull-left"
                                data-dismiss="modal">
                            <img src="@/assets/icons/X.svg" />{{ $t('global.cancel') }}
                        </button>
                        <button type="button"
                                class="btn btn-default"
                                @click="resetModal"
                                v-bTooltip="$t('global.setDefaultTitle')">
                            <img src="@/assets/icons/update-auto.svg" />{{ $t('global.setDefault') }}
                        </button>
                        <button type="button"
                                class="btn btn-primary"
                                @click="checkTax">
                            <img src="@/assets/icons/savesmall.svg" />{{ $t('global.save') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import IncomesTSBodyCz from './IncomesTSBody/IncomesTSBodyCz.vue'
    import IncomesTSBodySk from './IncomesTSBody/IncomesTSBodySk.vue'
    import IncomesFlatRateTaxBody from './IncomesTSBody/IncomesFlatRateTax.vue'
    import ButtonSelect from './ButtonSelect.vue'

    export default {
        name: 'v-incomes-ts-modal',
        components: {
            'v-incomes-ts-body-cz': IncomesTSBodyCz,
            'v-incomes-ts-body-sk': IncomesTSBodySk,
            'v-incomes-flat-rate-tax-body': IncomesFlatRateTaxBody,
            'v-button-select': ButtonSelect
        },
        watch: {
            'applicant.incomes.otherIncomesCz': {
                handler() {
                    {
                        this.$emit('update:applicant', this.applicant);
                    }
                },
                deep: true
            },
            'applicant.activeSections.incomes.incomeType'(val){
                if (val == 'ts') {
                    // eslint-disable-next-line
                    $('#tsComponent').show();
                    // eslint-disable-next-line
                    $('#flatRateComponent').hide();
                }
                if (val == 'flatRate') {
                    // eslint-disable-next-line
                    $('#flatRateComponent').show();
                    // eslint-disable-next-line
                    $('#tsComponent').hide();
                }
            }
        },
        computed: {
            tsIncomeBody() {
                return this.$refs.incomesBody;
            },
            incomeflatRateTaxBody() {
                return this.$refs.flatRateTaxBody;
            }
        },
        methods: {
            resetModal: function () {
                if (this.applicant.activeSections.incomes.incomeType == 'ts') {
                    this.tsIncomeBody.resetModal();
                    return;
                }
                    
                if (this.applicant.activeSections.incomes.incomeType == 'flatRate') {
                    this.incomeflatRateTaxBody.resetModal();
                    return;
                }
            },
            overrideTax() {
                this.tsIncomeBody.overrideTax();
            },
            saveChanges() {
                this.$emit('saveChanges');
            },
            numberWithCommas(x) {
                if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                return x;
            },
            checkTax() {
                if (this.applicant.activeSections.incomes.incomeType == 'ts') {
                    this.tsIncomeBody.checkTax();
                }
                else {
                    this.$emit('saveChanges');
                    // eslint-disable-next-line
                    $('#prijmyDPModal').modal('hide');
                }
            }
        },
        props: {
            applicant: Object,
            settings: Object,
            occupations: Array,
            czNaces: Array,
            flatRates: Array,
            childTaxReturns: Array,
            closedTaxPeriods: Array
        },
    }
</script>

<style scoped>
    #flatRateComponent{
        display:none;
    }
</style>



