<template>
    <div class="modal" id="pdfSendExtendedDataModal" tabindex="-1">
        <div class="modal-dialog modal-lg">
            <div v-if="!sendCompleted" class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Export modelace</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="center-block">
                        <div class="info-panel">
                            <h4 style="font-weight: bold">Odeslání modelace do Analytiky PFS (<a href="https://www.analytikapfs.cz/">www.analytikapfs.cz</a>)</h4>
                            <p>Modelace jsou identifikovány pomocí Vašeho emailu a jména klienta.</p>
                        </div>

                        <div v-for="alert in alerts" v-bind:key="alert" :class="['alert', alert.class ]" role="alert">
                            {{ alert.message }}
                        </div>

                        <div class="input-box">
                            <v-labeled-input label="Název modelace" v-model="pdfSendData.label" inputSize="70" :maxLength="32"></v-labeled-input>
                            <v-labeled-input label="Jméno klienta" v-model="pdfSendData.clientName" inputSize="70" isRequired="true" :maxLength="32"></v-labeled-input>
                            <v-labeled-input label="Příjmení klienta" v-model="pdfSendData.clientSurname" inputSize="70" isRequired="true" :maxLength="32"></v-labeled-input>
                            <v-labeled-input label="Datum narození" v-model="pdfSendData.clientBirthDate" inputSize="70" isRequired="true" inputType="date"></v-labeled-input>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('credit.resultTablePopUp.buttons.cancel') }}</button>
                    <span v-if="inputsAreValid" v-tooltip data-toggle="tooltip" title="Povinná pole musí být vyplněna" style="margin-left: 5px;">
                        <button type="button" class="btn btn-primary" disabled="true">{{ $t('credit.resultTablePopUp.buttons.save') }}</button>
                    </span>
                    <button v-else type="button" class="btn btn-primary" @click="sendPrintToUrlDataExtendedRequest">{{ $t('credit.resultTablePopUp.buttons.save') }}</button>
                </div>
            </div>
            <div v-else class="modal-content">
                <div class="modal-header text-center">
                    <h3>Ještě chviličku</h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="center-block">
                        <h4 style="font-weight:bold">{{sendStateMessage}}</h4>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('global.cancel') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LabeledInput from '@/components/LabeledInput.vue'

    export default {
        name: 'v-pdf-send-extended-data-modal',
        components: {
            "v-labeled-input": LabeledInput
        },
        data() {
            return {
                alerts: []
            }
        },
        methods: {
            sendPrintToUrlDataExtendedRequest: function () {
                this.$emit('sendPrintToUrlDataExtendedRequest');
            }
        },
        computed: {
            inputsAreValid: function () {
                return (!this.pdfSendData.clientName.trim() || !this.pdfSendData.clientSurname.trim() || !this.pdfSendData.clientBirthDate.trim());
            }
        },
        props: {
            pdfSendData: Object,
            sendCompleted: Boolean,
            sendStateMessage: String
        },
        mounted: function () {
          
        }
    }
</script>

<style>
    .fa-check.big {
        color: forestgreen;
        font-size: 30px;
        float: left;
        padding-right: 10px;
        margin-top: -8px;
    }

    .fa-circle-exclamation.big {
        color: red;
        font-size: 30px;
        float: left;
        padding-right: 10px;
        margin-top: -8px;
    }
</style>

