<template>
    <div v-if="household" class="household-panel">
        <div class="row householdHeaderRow">
            <span>{{householdId + 1}}. {{ $t('credit.household.domacnost') }}</span>
            <button v-if="allowRemove"
                    class="btn btn-primary"
                    type="button"
                    @click="$emit('removeHousehold')">
                {{ $t('credit.household.buttons.remove') }} <img class="mr-6" src="@/assets/icons/X.svg" />
            </button>
        </div>

        <div class="row">
            <div class="col-md-3">
                <v-labeled-select-list 
                    selectName="rodinnyStav" 
                    :label="$t('credit.household.familyStatus')" 
                    :labelTooltip="$t('tooltip.credit.household.familyStatus')" 
                    v-model="household.maritalStatus" 
                    :items="maritalStatusesItemList">
                </v-labeled-select-list>
            </div>
            <div class="col-md-3">
                <v-labeled-select-list 
                    selectName="kraj" 
                    :label="$t('credit.household.county')" 
                    :labelTooltip="$t('tooltip.credit.household.county')" 
                    v-model="household.region" 
                    :items="regions" 
                    :searchable="true">
                </v-labeled-select-list>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-3">
                        <v-labeled-input inputId="dite5-" :label="$t('credit.household.child0_6')" v-model="household.dite5minusCount" number="true"></v-labeled-input>
                    </div>
                    <div v-if="settings.taxIncomesProvider == 'cz'" class="col-md-3">
                        <v-labeled-input inputId="dite6+" :label="$t('credit.household.child6_10')" v-model="household.dite6plusCount" number="true"></v-labeled-input>
                    </div>
                    <div v-if="settings.taxIncomesProvider == 'cz'" class="col-md-3">
                        <v-labeled-input inputId="dite10+" :label="$t('credit.household.child10_15')" v-model="household.dite10plusCount" number="true"></v-labeled-input>
                    </div>
                    <div v-if="settings.taxIncomesProvider == 'cz'" class="col-md-3">
                        <v-labeled-input inputId="dite15+" :label="$t('credit.household.child15_26')" v-model="household.dite16plusCount" number="true"></v-labeled-input>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <hr />
        </div>

        <div class="row applicant-container">
            <div v-for="(applicant, index) in household.applicantPersons" v-bind:key="applicant.id" class="col-md-12 applicant">
                <v-applicant 
                    :applicant="applicant" 
                    :index="index" 
                    :settings="settings" 
                    @applicantLinkClicked="emitApplicantLinkClicked" 
                    v-on:deleteApplicant="deleteApplicant(index)" 
                    :showDelete="showApplicantDelete" 
                    :iconsLibrary="iconsLibrary"
                    @applicantAgeChanged="applicantAgeChanged">
                </v-applicant>
            </div>
            <div v-if="showAddApplicant" style="width: 100%; padding-right: 15px;">
                <a href="javascript:void(0)"
                   v-on:click="addNewApplicant"
                   id="addNewApplicant"
                   class="btn btn-default"
                   style="float:right">
                    <img src="@/assets/icons/person_plus.svg" /> {{ $t('credit.household.addNewApplicant') }}
                </a>
            </div>
            <!--Očekávám návrat k tomuto řešení, proto ponechávám-->
            <!--<div v-if="showAddApplicant" class="col-md-12 col-lg-6 applicant">
                <div class="panelZadatel empty-applicant">
                    <a href="javascript:void(0)" 
                        v-on:click="addNewApplicant" 
                        id="addNewApplicant"
                        class="btn btn-default">
                        <img src="@/assets/icons/person_plus.svg" /> {{ $t('credit.household.addNewApplicant') }}
                    </a>
                </div>
            </div>-->
        </div>
    </div>
</template>

<script>
    import LabeledSelectList from './LabeledSelectList.vue'
    import LabeledInput from './LabeledInput.vue'
    import Applicant from './Applicant.vue'

    import { AddNewApplicant, RemoveApplicant } from '../js/HouseholdHelpers.mjs'

    export default {
        name: 'v-household',
        components: {
            'v-labeled-select-list': LabeledSelectList,
            'v-labeled-input': LabeledInput,
            'v-applicant' : Applicant
        },
        computed: {
            showApplicantDelete: function () {
                return this.household.applicantPersons.length > 1;
            },
            showAddApplicant: function () {
                return this.household.applicantPersons.length < 2;
            },
            maritalStatus: function () {
                return this.household.maritalStatus;
            },
            maritalStatusesItemList: function () {
                if (this.household.applicantPersons.length >= 2) {
                    return this.maritalStatuses.slice(3); //vrátí pouze ženatý/vdaná nebo druh/družka
                }
                else {
                    return this.maritalStatuses;
                }
            }
        },
        methods: {
            addNewApplicant: function () {
                AddNewApplicant(this.household, this.settings, this.$i18n);
                this.household.maritalStatus = 'Married'; // ženatý
            },
            deleteApplicant: function (index) {
                RemoveApplicant(this.household, index);
                this.household.maritalStatus = 'Single'; // svobodný
                this.$emit('applicantDeleted');
            },
            emitApplicantLinkClicked: function (index) {
                this.$emit('ApplicantLinkClicked', { hh: this.householdId, app: index });
            },
            applicantAgeChanged: function (val, indexApplicant) {
                this.$emit('applicantAgeChanged', val, indexApplicant, this.householdId);
            }
        },
        watch: {
            household: function () {
                this.$emit('update:household', this.household);
            },
            maritalStatus: function (val) {
                if (val == 'LivingTogether' || val == 'Married') { // 3 -> druh/družka, 4 -> ženatý/vdaná
                    AddNewApplicant(this.household, this.settings, this.$i18n);
                } 
            }
        },
        props: {
            householdId: {
                type: String,
                default: '999'
            },
            household: Object,
            maritalStatuses: Array,
            regions: Array,
            personCounts: Array,
            settings: Object,
            iconsLibrary: Object,
            allowRemove: Boolean
        }
    }
</script>

<style scoped>

        .tab-content {
            padding: 10px;
            border: 1px solid #ddd;
            border-top: none;
        }

    label.persons-label {
        margin-top: 35px;
        float: right;
        white-space: nowrap;
    }

    hr {
        margin: 5px 0 15px 0;
    }

    .applicant-container {
        display: flex;
        flex-wrap: wrap;
        row-gap: 15px;
    }

    .applicant{
        flex-grow: 1;
    }

    .panelZadatel {
        padding: 15px;
        padding-left: 100px;
        background-color: WhiteSmoke;
        border: 1px solid lightgray;
        height: 100%;
    }

    @media (max-width: 600px) {
        .panelZadatel {
            padding-left: 15px;
        }
    }

    .empty-applicant {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
    }

    .household-panel {
        padding: 15px 30px;
        background-color: whitesmoke;
        margin-top: 15px;
        border-radius: 7px;
    }

    .householdHeaderRow {
        padding-bottom: 25px;
    }

    .householdHeaderRow > span {
        font-size: 18px;
        font-weight: bold;
        float: left;
        line-height: 36px;
    }

    .householdHeaderRow > button {
        float: right;
    }

    @media (min-width: 991px) and (max-width: 1199px) {
        .col-md-3 {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    
</style>