<template>
    <div id="app">
        <div class="panel hypo-panel panel-default">
            <div class="panel-heading" @click="activePanels.rates ? hideRates() : activePanels.rates = !activePanels.rates">
                <img class="heading-icon" src="@/assets/icons/sazby.svg" />
                {{ $t('credit.app.mortgageApplication') }}
                <span v-if="!activePanels.rates"
                      class="heading-summation">
                    {{ $t('credit.app.loanAmount') }}: <vue-numeric :currency="$t('global.currencySymbol')" currency-symbol-position="suffix" separator="space" read-only="true" v-model="loanParams[0].loanAmount"></vue-numeric> |
                    {{ $t('credit.app.loanPurpose') }}: {{ loanPurposeTranslated }} |
                    {{ $t('credit.app.ltv') }}: {{ Math.round(loanParams[0].ltv * 100) }} %
                </span>
                <img v-if="activePanels.rates" class="heading-arrow" src="@/assets/icons/upFill.svg" />
                <img v-else class="heading-arrow" src="@/assets/icons/downFill.svg" />
            </div>
            <div class="panel-body collapse" :class="{ 'in' : activePanels.rates }">

                <div class="partner-checkbox-main-row">
                    <div class="partner-checkbox-pill" v-for="partner in dummyPartners" :key="partner.code">
                        <input type="checkbox" v-model="partner.selected" @click="partnerSelected(partner.code, partner.selected)"/>
                        <img v-bTooltip="partner.name" :src="'/ImageProxy.aspx?f=' + partner.code + '-big.png'" @click="partnerSelected(partner.code, partner.selected)" />
                    </div>
                </div>

                <template v-for="(lp, index) in loanParams" :key="lp">
                    <span v-if="loanParams.length > 1" style="margin-left:-8px;"><b>Varianta {{index + 1}}</b></span>
                    <div class="row">
                        <div class="col-md-3">
                            <v-labeled-input inputId="vyseUveru"
                                             :addonString="$t('global.currencySymbol')"
                                             :label="$t('credit.app.loanAmount')"
                                             :labelTooltip="$t('tooltip.credit.app.loanAmount')"
                                             v-model="lp.loanAmount"
                                             number="true"
                                             :errors="loanAmountError"
                                             class="form-group-pb20">
                            </v-labeled-input>
                            <span class="amount-LTVs">
                                {{ $t('credit.app.ltv') }}:
                                <a @click="countLoanAmount(0.5, index)">50%</a>
                                <a @click="countLoanAmount(0.6, index)">60%</a>
                                <a @click="countLoanAmount(0.7, index)">70%</a>
                                <a @click="countLoanAmount(0.8, index)">80%</a>
                                <a @click="countLoanAmount(0.85, index)">85%</a>
                                <a @click="countLoanAmount(0.9, index)">90%</a>
                            </span>
                        </div>
                        <div class="col-md-3">
                            <v-labeled-input inputId="reinsurance"
                                             :addonString="$t('global.currencySymbol')"
                                             :label="$t('credit.app.reinsurance')"
                                             :labelTooltip="$t('tooltip.credit.app.reinsurance')"
                                             v-model="lp.reinsurance"
                                             number="true"
                                             class="form-group-pb20">
                            </v-labeled-input>
                            <span class="amount-LTVs">
                                {{ $t('credit.app.ltv') }}:
                                <a @click="countReinsurance(0.5, index)">50%</a>
                                <a @click="countReinsurance(0.6, index)">60%</a>
                                <a @click="countReinsurance(0.7, index)">70%</a>
                                <a @click="countReinsurance(0.8, index)">80%</a>
                                <a @click="countReinsurance(0.85, index)">85%</a>
                                <a @click="countReinsurance(0.9, index)">90%</a>
                            </span>
                        </div>
                        <div class="col-md-3">
                            <v-labeled-maturity-select-list selectName="splatnostRoky"
                                                            selectName2="splatnostMesice"
                                                            :label="$t('credit.app.maturity')"
                                                            :labelTooltip="$t('tooltip.credit.app.maturity')"
                                                            v-model="lp.maturity"
                                                            :items="maturities"
                                                            :items2="maturitiesMonths">
                            </v-labeled-maturity-select-list>
                        </div>
                        <div v-if="allowRatesVariants" class="col-md-3 fixation-add-row-column">
                            <v-labeled-select-list selectName="fixace"
                                                   :label="$t('credit.app.fixation')"
                                                   :labelTooltip="$t('tooltip.credit.app.fixation')"
                                                   v-model="lp.fixationRates"
                                                   :items="fixations">
                            </v-labeled-select-list>
                            <button v-if="index === 0" class="btn btn-default"
                                    type="button"
                                    style="margin-top: 24px; margin-bottom: 10px;"
                                    @click="addLoanParams">
                                {{$t('credit.app.buttons.addVarinat')}}
                            </button>
                            <button v-else class="btn btn-default"
                                    type="button"
                                    style="margin-top: 24px; margin-bottom: 10px;"
                                    @click="removeLoanParams(index)">
                                {{$t('credit.app.buttons.removeVariant')}}
                            </button>
                        </div>
                        <div v-else class="col-md-3">
                            <v-labeled-select-list selectName="fixace"
                                                   :label="$t('credit.app.fixation')"
                                                   :labelTooltip="$t('tooltip.credit.app.fixation')"
                                                   v-model="lp.fixationRates"
                                                   :items="fixations">
                            </v-labeled-select-list>
                        </div>
                    </div>
                </template>

                <span v-if="loanParams.length > 1" style="margin-left:-8px;"><b>{{$t('credit.app.forAllVariants')}}</b></span>
                <div v-if="loanParams.length > 0" class="row">
                    <div class="col-md-3">
                        <v-labeled-select-list selectName="typNemovitosti"
                                               :label="$t('credit.app.propertyType')"
                                               :labelTooltip="$t('tooltip.credit.app.propertyType')"
                                               v-model="loanParams[0].propertyType"
                                               :items="propertyTypes" />
                    </div>
                    <div class="col-md-3">
                        <v-labeled-select-list selectName="ucelUveru"
                                               :label="$t('credit.app.loanPurpose')"
                                               :labelTooltip="$t('tooltip.credit.app.loanPurpose')"
                                               v-model="loanParams[0].loanPurpose"
                                               :items="loanPurposes">
                        </v-labeled-select-list>
                    </div>
                    <template v-if="localization === 'sk'">
                        <div class="col-md-6">
                            <div class="row" style="margin-right: -8px; margin-left: -8px;">
                                <div class="col-md-4">
                                    <v-labeled-input inputId="age"
                                                     :addonString="$t('global.years')"
                                                     :label="$t('credit.app.age')"
                                                     :labelTooltip="$t('tooltip.credit.app.age')"
                                                     v-model="loanParams[0].age"
                                                     number="true"
                                                     numberMin="18" 
                                                     numberMax="99"
                                                     @input="ageChanged">
                                    </v-labeled-input>
                                </div>
                                <div class="col-md-4">
                                    <v-labeled-input inputId="sazbyPrijmy"
                                                     :addonString="$t('global.currencySymbol')"
                                                     :label="$t('credit.app.ratesIncomes')"
                                                     :labelTooltip="$t('tooltip.credit.app.ratesIncomes')"
                                                     v-model="loanParams[0].ratesIncome"
                                                     number="true">
                                    </v-labeled-input>
                                </div>
                                <div class="col-md-4">
                                    <v-labeled-select-list selectName="sazbyPocetZadatelu"
                                                           label="Počet žiadateľov"
                                                           v-model="loanParams[0].ratesApplicantsCount"
                                                           :items="[{name: '1', value: 1}, {name: 'viac', value: 9}]">
                                    </v-labeled-select-list>
                                </div>
                            </div>
                        </div>
                        
                    </template>
                    <template v-else>
                        <div class="col-md-3">
                            <v-labeled-input inputId="age"
                                             :addonString="$t('global.years')"
                                             :label="$t('credit.app.age')"
                                             :labelTooltip="$t('tooltip.credit.app.age')"
                                             v-model="loanParams[0].age"
                                             number="true" 
                                             numberMin="18" 
                                             numberMax="99"
                                             @input="ageChanged">
                            </v-labeled-input>
                        </div>
                        <div class="col-md-3">
                            <v-labeled-input inputId="sazbyPrijmy"
                                             :addonString="$t('global.currencySymbol')"
                                             :label="$t('credit.app.ratesIncomes')"
                                             :labelTooltip="$t('tooltip.credit.app.ratesIncomes')"
                                             v-model="loanParams[0].ratesIncome"
                                             number="true">
                            </v-labeled-input>
                        </div>
                    </template>
                    
                </div>

                <div class="row">
                    <div class="col-md-12 checkbox-container-custom">
                        <label v-for="(item, index) in filter.checkboxes"
                               :key="index">
                            <input type="checkbox"
                                   v-model="item.isChecked"
                                   @input="filterCheckboxChanged(!item.isChecked, item.type)" />
                            {{item.name}}
                            <img v-if="item.tooltip"
                                 class="label-help"
                                 src="@/assets/icons/help.svg"
                                 v-bPopover="item.tooltip" />
                        </label>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="pull-right credit-worthiness-action-buttons">
                            <button class="btn btn-default"
                                    type="button"
                                    @click="resetRatesForm"
                                    data-toggle="tooltip"
                                    :title="$t('credit.app.buttons.setDefaultTitle')"
                                    id="btnResetForm">
                                <img class="pencil-reset left" src="@/assets/icons/update-auto.svg" />{{ $t('credit.app.buttons.setDefault') }}
                            </button>
                            <button class="btn btn-default"
                                    type="button"
                                    id="btnShowCreditWorthness"
                                    @click="showBonitaClicked">
                                {{ $t('credit.app.buttons.bonita') }} <img class="mr-6" src="@/assets/icons/arrows-down.svg" />
                            </button>
                            <button class="btn btn-primary"
                                    type="button"
                                    id="btnCountRates"
                                    @click="countRatesOnly"
                                    :disabled="!readyToCount">
                                {{ $t('credit.app.buttons.countRates') }} <img class="mr-6" src="@/assets/icons/arrows-right.svg" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="panel hypo-panel panel-default">
            <div class="panel-heading" @click="activePanels.creditWorthiness = !activePanels.creditWorthiness">
                <img class="heading-icon" src="@/assets/icons/penize.svg" />
                {{ $t('credit.app.buttons.bonita') }}
                <span v-if="!activePanels.creditWorthiness && incomesFilled"
                      class="heading-summation">
                    {{ $t('credit.app.appliacantCount') }}: {{ applicantsCount }} |
                    {{ $t('credit.app.oldestApplicant')}}: {{ oldestApplicant }}
                </span>
                <span v-if="!activePanels.creditWorthiness && !incomesFilled" class="heading-summation">
                    {{ $t('credit.app.bonitaUnfilled')}}
                </span>

                <img v-if="activePanels.creditWorthiness" class="heading-arrow" src="@/assets/icons/downFill.svg" />
                <img v-else class="heading-arrow" src="@/assets/icons/downFill.svg" />
            </div>
            <div v-if="loanParams.length > 0" class="panel-body collapse" :class="{ 'in' : activePanels.creditWorthiness }">
                <div class="row">
                    <div class="col-md-2">
                        <v-labeled-input inputId="sazba"
                                         addonString="%"
                                         :label="$t('credit.app.rate')"
                                         :labelTooltip="$t('tooltip.credit.app.rate')"
                                         v-model="loanParams[0].rate"
                                         precision="2"
                                         number="true"
                                         labelClass="bold" />
                    </div>
                </div>

                <!-- PONEHÁVÁM PRO MOŽNÝ NÁVRAT MONETY -->
                <!-- cs localization only - see refinancingFields method-->
                <!--<div class="row" v-if="refinancingFields">
                    <hr style="margin-bottom:10px;" />
                    <div class="col-md-4">
                        <div class="MMBFocused">
                            <p class="image-label">Pro přesný výpočet u</p>
                            <div class="imgWrapper">
                                <img src="/ImageProxy.aspx?f=MMB-big.png" />
                            </div>
                        </div>-->
                        <!--<v-labeled-input inputSize="60" inputId="splatkaRefUveru" :addonString="$t('global.currencySymbol')" label="Splátka ref. úvěru" :value.sync="loanParams[0].refinMortgageInstallment" number="true"></v-labeled-input>-->
                    <!--</div>
                    <div class="col-md-3">
                        <v-labeled-input inputId="objemRefUveru"
                                         :addonString="$t('global.currencySymbol')"
                                         label="Objem ref. úvěru"
                                         :inputTooltip="$t('tooltip.credit.app.refinMortgageBalance')"
                                         v-model="loanParams[0].refinMortgageBalance"
                                         number="true" />
                    </div>
                    <div class="col-md-3">
                        <v-labeled-input inputId="navyseniUveru"
                                         :addonString="$t('global.currencySymbol')"
                                         label="Navýšení úvěru"
                                         :inputTooltip="$t('tooltip.credit.app.refinMortgageIncrease')"
                                         v-model="loanParams[0].refinMortgageIncrease"
                                         number="true" />
                    </div>
                </div>-->

                <div class="row">
                    <div v-for="(household, index) in households" v-bind:key="household.id" class="col-md-12">
                        <v-household :settings="settings"
                                     :household="household"
                                     :maritalStatuses="maritalStatuses"
                                     :regions="regions"
                                     :personCounts="personCounts"
                                     :outcomeTypes="outcomeTypes"
                                     :occupations="occupations"
                                     :iconsLibrary="iconsLibrary"
                                     :allowRemove="secondHouseholdExists"
                                     @ApplicantLinkClicked="handleApplicantLinkClicked"
                                     :householdId="index"
                                     @removeHousehold="removeHousehold(index)"
                                     @applicantAgeChanged="applicantAgeChanged"
                                     @applicantDeleted="applicantDeleted">
                        </v-household>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div v-if="showAddHousehold" class="pull-left credit-worthiness-action-buttons">
                            <button class="btn btn-default"
                                    data-toggle="tooltip"
                                    :title="$t('credit.app.buttons.householdTitle')"
                                    type="button"
                                    id="btnAddHousehold"
                                    @click="addNewHousehold">
                                {{ $t('credit.app.addHousehold') }} <img class="mr-6" src="@/assets/icons/plus2.svg" />
                            </button>
                        </div>
                        <div class="pull-right credit-worthiness-action-buttons">
                            <button class="btn btn-default"
                                    type="button"
                                    @click="resetForm"
                                    data-toggle="tooltip"
                                    :title="$t('credit.app.buttons.setDefaultTitle')"
                                    id="btnResetForm">
                                <img class="pencil-reset left" src="@/assets/icons/update-auto.svg" />{{ $t('credit.app.buttons.setDefault') }}
                            </button>
                            <button class="btn btn-primary"
                                    type="button"
                                    id="btnCountBonita"
                                    @click="countBonita">
                                {{ $t('credit.app.buttons.countCredit') }} <img class="mr-6" src="@/assets/icons/arrows-right.svg" />
                            </button>
                            <button class="btn btn-primary"
                                    type="button"
                                    id="btnCountRates"
                                    @click="countRates"
                                    :disabled="!readyToCount">
                                {{ $t('credit.app.buttons.countRates') }} <img class="mr-6" src="@/assets/icons/arrows-right.svg" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div>
            <div class="table-top">
                <ul class="table-top-group nav nav-result-tables">
                    <li id="ratesPanel" style="display:none" @click="resultNavClicked" data-result="rates">{{ $t('credit.app.buttons.rates') }} <img src="@/assets/icons/sazby.svg" /></li>
                    <li id="creditWorthinessPanel" style="display:none" @click="resultNavClicked" data-result="creditWorthiness">{{ $t('credit.app.buttons.bonita') }} <img src="@/assets/icons/penize.svg" /></li>
                </ul>


                <div class="table-top-group table-top-group-middle"
                     v-if="activePanels.resultsType == 'creditWorthiness' && localization === 'cs'">
                    <div class="checkbox-container-custom">
                        <label>
                            <input type="checkbox"
                                   class="check-switch"
                                   v-model="withoutDtiDstiLimits" />
                            {{ $t('credit.resultTable.checkboxes.withoutDSTI') }}
                        </label>
                    </div>
                </div>
                <div class="table-top-group table-top-group-middle"
                     v-if="activePanels.resultsType == 'rates'">
                    <div class="checkbox-container-custom">
                        <label>
                            <input type="checkbox"
                                   class="check-switch"
                                   v-model="ratesSelectedOnly" />
                            {{ $t('credit.resultTable.checkboxes.selectedOnly') }}
                        </label>
                        <label>
                            <input type="checkbox"
                                   class="check-switch"
                                   v-model="ratesExpandResults" />
                            {{ $t('credit.resultTable.checkboxes.showDetail') }}
                        </label>
                    </div>
                </div>
                <div class="table-top-group table-top-group-buttons"
                     v-if="activePanels.resultsType == 'rates'">
                    <div v-if="usersClientData?.entityId && usersClientData?.entityTypeId">
                        <button type="button"
                                :class="[ 'btn btn-default' ]"
                                @click="sendUsersClientData">
                            Záznam z jednání
                        </button>
                    </div>
                    <div>
                        <button type="button"
                                :class="[ 'btn btn-default', { selected : methodologyFilterActive }]"
                                @click="openMethodologyFilterModal">
                            {{ $t('credit.resultTable.buttons.methodologyFilter') }}
                        </button>
                    </div>
                    <div>
                        <button type="button"
                                :class="[ 'btn btn-default', { selected : ratesFilterIsSelected }]"
                                @click="openFilterModal">
                            {{ $t('credit.resultTable.buttons.discountsAndFees') }}
                        </button>
                    </div>
                    <div class="dropdown">
                        <button type="button"
                                class="btn btn-default dropdown-toggle"
                                data-toggle="dropdown"
                                aria-expanded="false">
                            {{ $t('credit.resultTable.buttons.printSelected') }} <img class="mr-6" src="@/assets/icons/downFill.svg" />
                        </button>
                        <ul class="dropdown-menu dropdown-menu-right">
                            <li v-for="file in ratesExportFiles" :key="file.id">
                                <a href="javascript:void(0)" @click="printRatesRequest(file.id)">{{file.buttonName}}</a>
                            </li>
                            <li v-if="printToUrlButtonVisible">
                                <a href="javascript:void(0)" @click="printRatesToUrlRequest">Uložit do Apolla</a>
                            </li>
                            <li v-if="printToUrlDataExtendedButtonVisible">
                                <a href="javascript:void(0)" @click="printToUrlDataExtendedRequest()">Exportovat do Analytiky</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="table-top-group table-top-group-buttons"
                     v-if="activePanels.resultsType == 'creditWorthiness'"
                     style="margin-left: auto">
                    <div class="dropdown">
                        <button type="button"
                                class="btn btn-default dropdown-toggle"
                                :class="{ 'disabled' : calcInProgress }"
                                data-toggle="dropdown"
                                aria-expanded="false">
                            {{ $t('credit.resultTable.buttons.printCalculations') }} <img class="mr-6" src="@/assets/icons/downFill.svg" />
                        </button>
                        <ul v-if="printButtonVisible" class="dropdown-menu dropdown-menu-right">
                            <li>
                                <a href="javascript:void(0)" @click="printRequest(false)">{{ $t('credit.resultTable.buttons.exportToPdf') }}</a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" @click="printRequest(true)">{{ $t('credit.resultTable.buttons.exportToPdfBankIncluding') }}</a>
                            </li>

                            <li v-if="printToUrlButtonVisible">
                                <a href="javascript:void(0)" @click="printToUrlRequest()">Uložit do Apolla</a>
                            </li>
                            <li v-if="printToUrlDataExtendedButtonVisible">
                                <a href="javascript:void(0)" @click="printToUrlDataExtendedRequest()">Exportovat do Analytiky</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>


            <template v-if="activePanels.resultsType == 'rates'">
                <div v-if="loanParamsLastCalculation.length > ratesResultItems.length" class="table-loading">
                    <i class="fas fa-spinner fa-spin"></i> 
                    <i>{{$t('credit.app.inProgress')}}</i>
                </div>
                <v-rates-result-table 
                                      v-for="(items, index) in ratesResultItems"
                                      :key="index"
                                      ref="ratesResultTable"
                                      :resultItems="items"
                                      :loanParams="loanParamsLastCalculation[index]"
                                      :calcInProgress="ratesCalcInProgress"
                                      :printButtonVisible="printButtonVisible"
                                      :printToUrlButtonVisible="printToUrlButtonVisible"
                                      :printToUrlDataExtendedButtonVisible="printToUrlDataExtendedButtonVisible"
                                      :selectedOnly="ratesSelectedOnly"
                                      :expandResults="ratesExpandResults"
                                      :dorisMortgagesAllowed="dorisMortgagesAllowed"
                                      :dorisLinkTarget="dorisLinkTarget"
                                      :localization="localization"
                                      :methodologyFilterActive="methodologyFilterIsSet"
                                      :variantIndex="index"
                                      :maxIndex="ratesResultItems.length - 1"
                                      :showSkInfo="showSkInfo"
                                      @printMessage="newPrintMessage(val)"
                                      @showRateEdit="openFilterModalSpecific(index)"
                                      @selectAllShouldSelect="selectAllClicked">
                </v-rates-result-table>
            </template>
            

            <v-result-table v-if="activePanels.resultsType == 'creditWorthiness'"
                            :uiDebug="uiDebug"
                            :showLoanScheduleLink="showLoanScheduleLink"
                            :resultItems="sortedResultItems"
                            :loanParams="loanParamsLastCalculation[0]"
                            :calcInProgress="calcInProgress"
                            :printButtonVisible="printButtonVisible"
                            :printToUrlButtonVisible="printToUrlButtonVisible"
                            :printToUrlDataExtendedButtonVisible="printToUrlDataExtendedButtonVisible"
                            :showReportCalculationError="settings.showReportCalculationError"
                            :withoutDtiDstiLimits="withoutDtiDstiLimits"
                            :dorisMortgagesAllowed="dorisMortgagesAllowed"
                            :dorisLinkTarget="dorisLinkTarget"
                            v-on:printRequest="printRequest($event)"
                            v-on:printToUrlRequest="printToUrlRequest()"
                            v-on:printToUrlDataExtendedRequest="printToUrlDataExtendedRequest()">
            </v-result-table>
        </div>

        <v-incomes-ts-modal v-if="settings.taxIncomesProvider"
                            :settings="settings"
                            :applicant.sync="applicantToModalEdit"
                            @saveChanges="saveChangesIncomes"
                            data-backdrop="static"
                            :occupations="occupations"
                            :czNaces="czNaces"
                            :flatRates="flatRates"
                            :businessTypesSk="businessTypesSk"
                            :childTaxReturns="childTaxReturns"
                            :closedTaxPeriods="closedTaxPeriods" />

        <v-expenses-modal :settings="settings"
                          :applicant="applicantToModalEdit"
                          @saveChanges="saveChangesExpenses" 
                          :maturities="maturities"
                          :maturitiesMonths="maturitiesMonths"/>

        <v-incomes-other-modal :settings="settings"
                               :applicant="applicantToModalEdit"
                               :personCounts="personCounts"
                               :household="currentHousehold"
                               @saveChanges="saveChangesIncomesOther" />

        <v-pdf-send-extended-data-modal :pdfSendData="pdfSendData"
                                        v-on:sendPrintToUrlDataExtendedRequest="sendPrintToUrlDataExtendedRequestSwitch()"
                                        :sendCompleted="pdfSendCompleted"
                                        :sendStateMessage="pdfSendStateMessage" />

        <v-rates-filter-modal :ratesFilters="filterPartnersToEdit"
                              :localization="localization"
                              @saveChanges="saveFilter" />

        <v-methodology-filter-modal :filterPartners="filterPartnersToEdit.items"
                                    :localization="localization"
                                    :methodologyForeignersSettings="methodologyForeignersSettings"
                                    :methodologyFilterItems="methodologyFilterItems"
                                    :dummyPartners="dummyPartners"
                                    @saveChanges="saveMethodologyFilter"
                                    @resetMethodologyFilter="resetMethodologyFilter"
                                    @partnerClicked="partnerSelected"/>
        <v-alert-modal :title="alertTitle" :message="alertMessage" />
        <v-video-modal :localization="localization"/>

    </div>
</template>

<script>
    import LabeledInput from './components/LabeledInput.vue'
    import LabeledMaturitySelectList from './components/LabeledMaturitySelectList.vue'
    import LabeledSelectList from './components/LabeledSelectList.vue'
    import Household from './components/Household.vue'

    import ExpensesModal from './components/ExpensesModal.vue'
    import IncomesOtherModal from './components/IncomesOtherModal.vue'
    import IncomesTSModal from './components/IncomesTSModal.vue'
    import PdfSendExtendedDataModal from './components/PdfSendExtendedDataModal.vue'
    import RatesFilterModal from './components/RatesFilterModal.vue'
    import MethodologyFilterModal from './components/MethodologyFilterModal.vue'
    import AlertModal from './components/AlertModal.vue'
    import VideoModal from './components/VideoModal.vue'
    import { getIcon } from "./js/IconsHelpers.mjs"
    import ResultTable from './components/result/ResultTable.vue'
    import RatesResultTable from './components/ratesResult/RatesResultTable.vue'
    import { LoadEnumTypes, LoadPlaceholderItems } from './js/ListLoaders.mjs'
    import {
        InitializeHouseholds,
        AddNewHousehold,
        RemoveHousehold,
        InitializeBonitaLoanParamas,
        InitializeRatesLoanParams,
        ResetHousehold,
        FilterIsSelected,
        BonitaIsFilled,
        DefaultMethodologyFilter,
        DefaultMethodologyFilterSk,
    } from './js/HouseholdHelpers.mjs'

    export default {
        data() {
            var applicant = {
                incomes: {
                    lastTaxSettlementCz: {
                        taxSettlementLeaseCz: {},
                        taxSettlementBusinessCz: {}
                    },
                    lastTaxSettlementSk: {
                        taxSettlementLeaseSk: {},
                        taxSettlementBusinessSk: {},
                    },
                    previousTaxSettlementCz: {
                        taxSettlementBusinessCz: {},
                        taxSettlementLeaseCz: {}
                    },
                    prePreviousTaxSettlementCz: {
                        taxSettlementBusinessCz: {},
                        taxSettlementLeaseCz: {}
                    },
                    taxFlatRateCz: {
                        taxFlatRateBusinessCz: {},
                        taxFlatRateLeaseCz: {}
                    },
                    otherIncomesCz: {}
                },
                expenses: {},
                activeSections: {
                    incomes: {
                        incomeTS: {
                            incomeCheckboxes: {},
                            usingFlatRate: {
                                business: {}
                            },
                            activeTSs: {
                                business: {},
                                lease: {}
                            },
                            disabledInputs: {
                                businessTaxBaseInputs: {},
                                leaseTaxBaseInputs: {}
                            }
                        },
                        incomeTaxFlatRate: {
                            incomeCheckboxes: {},
                            disabledInputs: {
                                businessTaxBaseInputs: {},
                                leaseTaxBaseInputs: {}
                            }
                        }
                    }
                },
                solidLinks: {}
            };
            return {
                iconsLibrary: {},
                applicantToModalEdit: applicant,
                loanParams: [],
                filter: {
                    filterPartners: []
                },
                filterPartnersToEdit: [
                    {
                        active: false,
                        filled: false,
                        items: [
                            {
                                obchodniSleva: 0.0,
                                nakladyNaZpracovaniRozpis: {},
                                mesicniNakladyRozpis: {}
                            }
                        ]
                    }
                ],
                dummyPartners: [],
                methodologyFilter: [],
                households: [],
                //default empty object
                currentHousehold: {
                    applicantPersons: [
                        applicant,
                    ]
                },
                activeApplicantIndex: 0,
                printButtonVisible: true,
                printToUrlButtonVisible: false,
                printToUrlDataExtendedButtonVisible: false,
                allowRatesVariants: true,

                generateHiddenFieldPdfPrint: true,
                uiDebug: false,
                selectVisible: true,
                lastHouseholdIndex: null,
                refinancingFields: false,
                componentKey: 0,
                usersClientData: {},

                loanPurposes: [],
                propertyTypes: [],
                LTVs: [],
                fixations: [],
                maturities: [],
                maturitiesMonths: [],
                maritalStatuses: [],
                regions: [],
                personCounts: [],
                occupations: [],
                businessTypesSk: [],
                czNaces: [],
                flatRates: [],
                childTaxReturns: [],
                closedTaxPeriods: [],
                calcInProgress: false,
                ratesCalcInProgress: false,
                resultItems: [],
                ratesResultItems: [],
                loanParamsLastCalculation: {},
                houseHoldsLastCalculation: {},
                showLoanScheduleLink: false,
                hypoLtv: 1,
                hypoFixace: 60,
                localization: 'cs',
                loadCompleted: false,
                vychoziVek: 30,
                settings: {
                    showReportCalculationError: false
                },
                withoutDtiDstiLimits: false,
                ratesSelectedOnly: false,
                ratesExpandResults: false,
                ratesExportFiles: [],
                methodologyForeignersSettings: {},
                methodologyFilterItems: [],
                methodologyMenuItems: [],
                pdfSendData: {
                    clientName: '',
                    clientSurname: '',
                    clientBirthDate: '',
                    label: ''
                },
                pdfSendCompleted: false,
                pdfSendStateMessage: '',
                activePanels: {
                    rates: true,
                    creditWorthiness: false,
                    result: {
                        rates: false,
                        creditWorthiness: false
                    },
                    resultsType: ''
                },
                arrowUpSrc: '@/assets/icons/downFill.svg',
                arrowDownSrc: '@/assets/icons/downFill.svg',
                dorisMortgagesAllowed: false,
                dorisPersonalLoansAllowed: false,
                dorisUnsecuredLoansAllowed: false,
                dorisLinkTarget: '_blank',
                alertTitle: '',
                alertMessage: ''
            }
        },
        name: 'app',
        components: {
            'v-labeled-input': LabeledInput,
            'v-labeled-maturity-select-list': LabeledMaturitySelectList,
            'v-labeled-select-list': LabeledSelectList,
            'v-household': Household,
            'v-expenses-modal': ExpensesModal,
            'v-incomes-other-modal': IncomesOtherModal,
            'v-incomes-ts-modal': IncomesTSModal,
            'v-result-table': ResultTable,
            'v-rates-result-table': RatesResultTable,
            'v-pdf-send-extended-data-modal': PdfSendExtendedDataModal,
            'v-rates-filter-modal': RatesFilterModal,
            'v-methodology-filter-modal': MethodologyFilterModal,
            'v-alert-modal': AlertModal,
            'v-video-modal': VideoModal
        },
        watch: {
            loanPurpose: function (val) {
                if (val === 'Refinancing' && this.localization === 'cs') this.refinancingFields = true;
                else this.refinancingFields = false;
            },
            calcInProgress: function (val) {
                if (!this.generateHiddenFieldPdfPrint) return;

                if (val === true || this.resultItems == null || this.resultItems.length <= 0) {
                    /* eslint-disable */
                    $('#pdfPrintData').val(false);
                }
                else {
                    var obj = {
                        request: {
                            loanParams: this.loanParamsLastCalculation,
                            households: this.houseHoldsLastCalculation,
                            resultItems: this.resultItems,
                        }
                    }
                    $('#pdfPrintData').val(JSON.stringify(obj));
                }
            }
        },
        computed: {
            showSkInfo() {
                if (this.settings.taxIncomesProvider === 'sk' && !this.resultItems.some(d => d.BankCode == 'SK_UNI')) return true;
                return false;
            },
            resultItemsDiff() {
                const diff = loanParamsLastCalculation.length - ratesResultItems.length;
                if (diff <= 0) return [];
                let diffArray = []
                for (var i = diff; i > 0; i--) {
                    diffArray.push({
                        name: 'Varianta' + loanParamsLastCalculation.length - i
                    });
                }
                return diffArray;
            },
            loanPurposeTranslated() {
                return this.loanPurposes.filter(d => d.value === this.loanPurpose)[0].name;
            },
            loanPurpose: function () {
                return this.loanParams[0]?.loanPurpose;
            },
            showTrashBin: function () {
                return this.households.length > 1;
            },
            showAddHousehold: function () {
                return this.households.length < 2;
            },
            sortedResultItems: function () {
                function compare(a, b) {
                    if (a.MaxAmount == null) return 1;
                    if (b.MaxAmount == null) return -1;
                    return (b.MaxAmount - a.MaxAmount)
                }

                function compareSk(a, b) {
                    // UNI always first
                    if (a.BankCode === 'SK_UNI') return -1;
                    if (b.BankCode === 'SK_UNI') return 1;

                    if (a.MaxAmount == null) return 1;
                    if (b.MaxAmount == null) return -1;
                    return (b.MaxAmount - a.MaxAmount)
                }

                function compareSkNames(a, b) {
                    if (a.BankName < b.BankName) { return -1; }
                    if (a.BankName > b.BankName) { return 1; }
                    return 0;
                }

                if (this.settings.taxIncomesProvider === 'sk' && this.resultItems.some(d => d.BankCode == 'SK_UNI')) {
                    let superBanks = this.resultItems.filter((d) => d.BankCode === 'SK_UNI' || d.BankCode === 'SK_UCB' || d.BankCode === 'SK_MB' || d.BankCode === 'SK_CSOB').sort(compareSk);
                    let otherBanks = this.resultItems.filter((d) => d.BankCode != 'SK_UNI' && d.BankCode != 'SK_UCB' && d.BankCode != 'SK_MB' && d.BankCode != 'SK_CSOB').sort(compareSkNames);
                    return superBanks.concat(otherBanks);
                }
                return this.resultItems.slice().sort(compare);
            },
            secondHouseholdExists() {
                return this.households.length > 1;
            },
            ratesArrowIconSource() {
                return this.activePanels.rates ? this.arrowUpSrc : this.arrowDownSrc;
            },
            creditWorthinessArrowIconSource() {
                return this.activePanels.creditWorthiness ? this.arrowUpSrc : this.arrowDownSrc;
            },
            ratesFilterIsSelected() {
                return FilterIsSelected(this.filter.filterPartners[0].items, this.localization);
            },
            applicantsCount() {
                return this.households.flatMap(d => d.applicantPersons).length;
            },
            oldestApplicant() {
                return Math.max(...this.households.flatMap(d => d.applicantPersons).map(d => d.vek));
            },
            incomesFilled() {
                return BonitaIsFilled(this.households);
            },
            readyToCount() {
                return this.loadCompleted && !this.loanAmountError;
            },
            loanAmountError() {
                if (this.loanParams[0]?.loanAmount > (this.loanParams[0]?.reinsurance * 0.9))
                    return this.$i18n.t('error.loanAmountTooHigh');
                return undefined;
            },
            methodologyFilterActive() {
                return this.methodologyFilterIsSet //|| this.filter.filterPartners[0].items.some((item) => item.vybranyPartner == false);
            },
            methodologyFilterIsSet() {
                return this.methodologyFilter?.length > 0;
            }

        },
        methods: {
            partnerSelected(code, selected) {
                var foundIndex = this.dummyPartners.findIndex(x => x.code == code);
                this.dummyPartners[foundIndex].selected = !selected;
                for (var i = 0; i < this.filter.filterPartners.length; i++) {
                    var foundIndexF = this.filter.filterPartners[i].items.findIndex(x => x.kod == code);
                    this.filter.filterPartners[i].items[foundIndexF].vybranyPartner = !selected;
                }
            },
            ageChanged(event) {
                if (this.households.length === 1 && this.households[0].applicantPersons.length === 1) {
                    this.households[0].applicantPersons[0].vek = event.target.value;
                }
            },
            applicantAgeChanged(val, indexApplicant, indexHousehold) {
                let ages = this.households.flatMap(d => d.applicantPersons).map(d => d.vek);
                let maxAge = Math.max(...ages);
                if (val >= maxAge) {
                    this.loanParams[0].age = val;
                }
            },
            applicantDeleted() {
                let ages = this.households.flatMap(d => d.applicantPersons).map(d => d.vek);
                let maxAge = Math.max(...ages);
                this.loanParams[0].age = maxAge;
            },
            resetMethodologyFilter() {
                this.methodologyFilterItems = this.localization === 'sk' ? DefaultMethodologyFilterSk() : DefaultMethodologyFilter()
            },
            addLoanParams() {
                const clone = JSON.parse(JSON.stringify(this.loanParams[0]));
                const cloneFilter = JSON.parse(JSON.stringify(this.filter.filterPartners[0]));
                this.loanParams.push(clone);
                this.filter.filterPartners.push(cloneFilter);
            },
            removeLoanParams(index) {
                this.loanParams.splice(index, 1);
                this.filter.filterPartners.splice(index, 1);
            },
            showMenu() {
                $('#menuModal').modal();
            },
            resultNavClicked(event) {
                $('.nav-result-tables li').removeClass('active');
                var el = $(event.target);
                if (el.is('img'))
                    el = el.parent('li');
                el.addClass('active');
                this.activePanels.resultsType = el.data('result');
            },
            showBonitaClicked() {
                this.activePanels.creditWorthiness = true;
                this.hideRates();
            },
            selectAllClicked(event, shouldSelect) {
                if (event) {
                    console.log(event);
                }
                this.$refs.ratesResultTable.forEach(d => d.selectAllFromSibling(shouldSelect));
            },
            hideRates() {
                this.activePanels.rates = false;
                // vypočítat LTV z výše úvěru a zajištění
                this.loanParams[0].ltv = this.getHypoLtv(this.loanParams[0]);
            },
            hideFormPanels() {
                this.activePanels.rates = false;
                this.activePanels.creditWorthiness = false;
            },
            getHypoLtv(params) {
                const ltv = params.loanAmount / params.reinsurance;
                if (ltv > 1) return 1;
                return ltv;
                //const ltvHranice = this.LTVs.map(obj => obj.value);
                //if (ltvHranice[0] === 1) return 1;
                //for (let i = 0; i < ltvHranice.length; i++) {
                //    const element = ltvHranice[i];
                //    if (element >= ltv) return element;
                //}
                //throw new Error("Nebylo nalezeno nejbližší vyšší číslo pro číslo: " + ltv + " ze seznamu čísel: " + Array.from(ltvHranice).join(", "));
            },
            countReinsurance(ltv, index) {
                this.loanParams[index].reinsurance = Math.ceil(this.loanParams[index].loanAmount / ltv);
            },
            countLoanAmount(ltv, index) {
                this.loanParams[index].loanAmount = Math.floor(this.loanParams[index].reinsurance * ltv);
            },
            filterCheckboxChanged(checked, type) {
                for (var i = 0; i < this.filter.filterPartners.length; i++) {
                    this.filter.filterPartners[i].items.forEach(function (obj) {
                        obj.vlastnosti.forEach(function (property) {
                            if (property.typSlevy === type)
                                property.selected = checked;
                        });
                    });
                }
            },
            addNewHousehold: function () {
                AddNewHousehold(this);
                this.currentHousehold = this.households[this.households.length - 1];
            },
            handleApplicantLinkClicked(val) {
                this.currentHousehold = this.households[val.hh];
                this.activeApplicantIndex = val.app;
                this.applicantToModalEdit = JSON.parse(JSON.stringify(this.currentHousehold.applicantPersons[val.app]));
            },
            saveChangesExpenses() {
                this.currentHousehold.applicantPersons[this.activeApplicantIndex].expenses = JSON.parse(JSON.stringify(this.applicantToModalEdit.expenses));
                this.currentHousehold.applicantPersons[this.activeApplicantIndex].sumOfAllLoanBalances = JSON.parse(JSON.stringify(this.applicantToModalEdit.sumOfAllLoanBalances));
            },
            saveChangesIncomes() {
                this.currentHousehold.applicantPersons[this.activeApplicantIndex].incomes = JSON.parse(JSON.stringify(this.applicantToModalEdit.incomes));
                this.currentHousehold.applicantPersons[this.activeApplicantIndex].activeSections = JSON.parse(JSON.stringify(this.applicantToModalEdit.activeSections));
            },
            saveChangesIncomesOther() {
                this.currentHousehold.applicantPersons[this.activeApplicantIndex].incomes.otherIncomesCz = JSON.parse(JSON.stringify(this.applicantToModalEdit.incomes.otherIncomesCz));
            },
            removeHousehold: function (index) {
                RemoveHousehold(this, index);
                if (index === 0) index++;
                this.currentHousehold = this.households[index - 1];
                this.applicantDeleted();
            },
            resetForm: function () {
                InitializeBonitaLoanParamas(this);
                InitializeHouseholds(this);
                this.resetMethodologyFilter();
            },
            resetRatesForm() {
                InitializeRatesLoanParams(this);
            },
            resetHousehold: function (index) {
                ResetHousehold(this, index);
            },

            saveIncomeTypeToDataLayer: function (incomeType) {
                window.dataLayer.push({
                    'event': 'CZ-NACE',
                    'eventValue': incomeType
                });
            },

            checkIncomes: function (households) {
                households.forEach(houshold => {
                    houshold.applicantPersons.forEach(person => {
                        const incomeType = person.incomes.lastTaxSettlementCz.taxSettlementBusinessCz.czNaceCz;
                        if (incomeType != "CZ_Nace_0000")
                            this.saveIncomeTypeToDataLayer(this.czNaces.find(x => x.value === incomeType).name);
                    })
                })
            },
            countRatesOnly() {
                this.gtmTrackClickUI('Spočítat sazby', {
                    amount: this.loanParams[0].loanAmount,
                    maturity: this.loanParams[0].maturity,
                    fixation: this.loanParams[0].fixationRates,
                    propertyType: this.loanParams[0].propertyType,
                    loanPurpose: this.loanParams[0].loanPurpose
                });
                this.loanParams[0].ltv = this.getHypoLtv(this.loanParams[0]);
                $('#btnSaveModelation').addClass('disabled');
                this.hideFormPanels();
                this.showRatesResult();
                this.callRates();
            },
            countRates() {
                this.gtmTrackClickUI('Spočítat sazby s bonitou', {
                    amount: this.loanParams[0].loanAmount,
                    maturity: this.loanParams[0].maturity,
                    fixation: this.loanParams[0].fixationRates,
                    propertyType: this.loanParams[0].propertyType,
                    loanPurpose: this.loanParams[0].loanPurpose
                });
                this.loanParams[0].ltv = this.getHypoLtv(this.loanParams[0]);
                $('#btnSaveModelation').addClass('disabled');
                this.hideFormPanels();
                this.activateAllTables();
                this.showRatesResult();
                this.callBonita(); //Bonita calls rates
            },
            countBonita() {
                this.gtmTrackClickUI('Spočítat bonitu');
                this.loanParams[0].ltv = this.getHypoLtv(this.loanParams[0]);
                $('#btnSaveModelation').addClass('disabled');
                this.hideFormPanels();
                this.activateAllTables();
                this.showCreditWorthinessResult();
                this.callBonita(); //Bonita calls rates
            },

            callBonita: function (countRates = false) {
                var i18n = this.$i18n;

                this.checkIncomes(this.households);
                LoadPlaceholderItems(this);
                this.calcInProgress = true;
                this.hideFormPanels();
                this.loanParamsLastCalculation = JSON.parse(JSON.stringify(this.loanParams));
                this.houseHoldsLastCalculation = JSON.parse(JSON.stringify(this.households));

                // Removes extra default data from request model
                const householdsCopy = JSON.parse(JSON.stringify(this.households));
                householdsCopy.forEach(household => {
                    household.applicantPersons.forEach(applicant => {
                        if (this.settings.taxIncomesProvider === 'cz') {
                            applicant.incomes = {
                                ...applicant.incomes,
                                lastTaxSettlementSk: null
                            }
                        } else if (this.settings.taxIncomesProvider === 'sk') {
                            applicant.incomes = {
                                ...applicant.incomes,
                                lastTaxSettlementCz: null,
                                prePreviousTaxSettlementCz: null,
                                previousTaxSettlementCz: null,
                            }
                        } else {
                            applicant.incomes = {
                                ...applicant.incomes,
                                lastTaxSettlementSk: null,
                                lastTaxSettlementCz: null,
                                prePreviousTaxSettlementCz: null,
                                previousTaxSettlementCz: null
                            }
                        }
                    })
                })

                this.axios.post("/Default.aspx/ResultItems",
                    {
                        request: {
                            loanParams: [ this.loanParams[0] ],
                            households: householdsCopy,
                            filter: this.filter
                        }
                    }
                )
                    .then(response => {
                        if (this.calcInProgress) {
                            this.resultItems = JSON.parse(response.data.d);
                            this.processBonita();
                        }
                        this.callRates();
                        return;
                    }).catch(error => {
                        this.calcInProgress = false;
                        if (error.response) {
                            if (error.response.status === 403) {
                                alert(i18n.t('credit.app.userLogoutError'))
                            }
                        }
                        this.callRates();
                    });
            },
            callRates: function () {
                this.ratesCalcInProgress = true;
                this.ratesResultItems = [];
                this.ratesSelectedOnly = false;
                this.ratesExpandResults = false;
                this.loanParamsLastCalculation = JSON.parse(JSON.stringify(this.loanParams));

                this.axios.post("/Default.aspx/RatesResultItems",
                    {
                        request: {
                            loanParams: this.loanParams,
                            filter: this.filter,
                            methodologyFilter: this.methodologyFilter
                        }
                    }
                )
                    .then(response => {
                        this.ratesResultItems = JSON.parse(response.data.d);
                        //this.ratesResultItems.push(JSON.parse(response.data.d));
                        //this.ratesResultItems.forEach(this.trackResult, this);
                    }).catch(error => {
                        this.ratesCalcInProgress = false;
                        if (error.response) {
                            if (error.response.status === 403) {
                                alert(i18n.t('credit.app.userLogoutError'))
                            }
                        }
                    });

                
                $('#btnSaveModelation').removeClass('disabled');
                this.ratesCalcInProgress = false;
            },
            trackResult(item) {
                var data = {
                    partner: item.PartnerCode,
                    rate: item.Rate,
                    rpsn: item.RPSN * 100,
                    installment: item.Installment
                };
                this.gtmTrackResult(data);
            },
            showRatesResult() {
                this.activePanels.resultsType = 'rates';
                $('.nav-result-tables li').removeClass('active');
                $('#ratesPanel').css('display', 'block');
                $('#ratesPanel').addClass('active');
            },
            showCreditWorthinessResult() {
                this.activePanels.resultsType = 'creditWorthiness';
                $('.nav-result-tables li').removeClass('active');
                $('#creditWorthinessPanel').css('display', 'block');
                $('#creditWorthinessPanel').addClass('active');
            },
            activateAllTables() {
                $('#ratesPanel').css('display', 'block');
                $('#creditWorthinessPanel').css('display', 'block');
            },
            processBonita: function () {
                //zjistime, ktere kalkulace jsou ve stavu Loading. Pokud zadne, tak je konec nacitani.

                var inLoading = this.resultItems.filter(item => item.CalcState === 'Loading');
                if (inLoading.length <= 0) {
                    this.calcInProgress = false;

                    /*Google Analytics*/
                    var impressions = [];
                    var index = 1;
                    this.sortedResultItems.forEach(item => {
                        impressions.push({ id: item.BankId, name: item.BankName, list: 'Bonita', position: index++, totalIncome: item.AppliedIncomeTotal });
                    })

                    window.dataLayer.push({
                        event: 'eec.impressionView',
                        ecommerce: {
                            impressions: impressions
                        }
                    });


                    /*  */
                }
                else {
                    setTimeout(this.callBonitaNext, 500);
                }
            },
            callBonitaNext: function () {
                var i18n = this.$i18n;

                var guids = [];
                var inLoading = this.resultItems.filter(function (item) {
                    return item.CalcState === 'Loading'
                });

                inLoading.forEach(function (item) {
                    guids.push({
                        guid: item.Guid,
                        bankName: item.BankName,
                        bankCode: item.BankCode,
                        bankType: item.BankType,
                        bankOnlineContractLink: item.BankOnlineContractLink,
                        ShowOnlineContractLinkInCreditWorthiness: item.ShowOnlineContractLinkInCreditWorthiness,
                        bankId: item.BankId
                    });
                });

                this.axios.post("/Default.aspx/ResultItemsNext", {
                    ids: guids
                })
                    .then(response => {
                        if (this.calcInProgress) {
                            var resp = JSON.parse(response.data.d);
                            for (var i = 0; i < this.resultItems.length; i++) {
                                var localItem = this.resultItems[i];

                                if (localItem.CalcState === 'Loading') {
                                    var found = false;
                                    if (localItem.Guid !== null) {
                                        for (var ri = 0; ri < resp.length; ri++) {
                                            if (this.resultItems[i].Guid === resp[ri].Guid) {
                                                found = true;
                                                this.resultItems[i] = resp[ri];
                                            }
                                        }
                                    }
                                    if (!found) {
                                        localItem.CalcState = 'CompletedWithoutData';
                                        this.resultItems[i] = localItem;
                                    }
                                }
                            }
                            this.processBonita();
                        }
                        return;
                    })
                    .catch(error => {
                        this.calcInProgress = false;
                        if (error.response) {

                            if (error.response.status === 403) {
                                alert(i18n.t('credit.app.userLogoutError'))
                            }
                        }
                    });
                return;
            },
            printRequest: function (event) {
                var i18n = this.$i18n;

                this.axios({
                    url: '/Default.aspx/Print?withBankNames=' + event,
                    method: 'POST',
                    responseType: 'blob',
                    data:
                    {
                        request: {
                            loanParams: this.loanParamsLastCalculation,
                            households: this.houseHoldsLastCalculation,
                            resultItems: this.resultItems,
                        }
                    }
                }).then((response) => {
                    var blob = new Blob([response.data], {
                        type: 'application/pdf'
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;

                    link.download = event ? i18n.t('credit.app.pdfNameWithBanks') : i18n.t('credit.app.pdfNameWithoutBanks');
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();

                    setTimeout(function () {
                        document.body.removeChild(link);
                        window.URL.revokeObjectURL(url);
                    }, 5000);
                }).catch(function () {
                    alert(i18n.t('credit.app.pdfGenerateError'));
                });
            },
            printToUrlDataExtendedRequest: function () {
                this.pdfSendCompleted = false;
                $('#pdfSendExtendedDataModal').modal();
            },
            sendPrintToUrlDataExtendedRequestSwitch: function () {
                if (this.activePanels.resultsType == 'creditWorthiness')
                    sendPrintToUrlDataExtendedRequest();
                else
                    this.sendPrintRatesToUrlDataExtendedRequest(this.pdfSendData);
                    //this.$refs.ratesResultTable[0].sendPrintToUrlDataExtendedRequest(this.pdfSendData);
            },
            sendPrintToUrlDataExtendedRequest: function () {
                this.axios({
                    url: '/Default.aspx/PrintToUrlDataExtended',
                    method: 'POST',
                    responseType: 'application/json',
                    data:
                    {
                        request: {
                            printRequest: {
                                loanParams: this.loanParamsLastCalculation,
                                households: this.houseHoldsLastCalculation,
                                resultItems: this.resultItems,
                            },
                            pdfSendData: this.pdfSendData
                        }
                    }
                }).then((response) => {
                    this.pdfSendCompleted = true;
                    this.newPrintMessage(JSON.parse(response.data.d));
                }).catch(function () {
                    this.pdfSendCompleted = true;
                    this.newPrintMessage('PDF se nepodařilo odeslat');
                })
            },
            printToUrlRequest: function () {
                this.newPrintMessage('Test dokončen');
                this.axios({
                    url: '/Default.aspx/PrintToUrl',
                    method: 'POST',
                    responseType: 'application/json',
                    data:
                    {
                        request: {
                            loanParams: this.loanParamsLastCalculation,
                            households: this.houseHoldsLastCalculation,
                            resultItems: this.resultItems
                        }
                    }
                }).then((response) => {
                    this.newPrintMessage(JSON.parse(response.data.d));
                }).catch(function () {
                    this.newPrintMessage('PDF se nepodařilo uložit ke klientovi');
                });
            },
            getSelectedOfferIds() {
                let anySel = this.$refs.ratesResultTable.some(d => d.anySelected());
                return this.$refs.ratesResultTable.map(d => d.getSelectedOfferIdsForPrint(anySel));
            },
            printRatesRequest: function (fileId) {
                var i18n = this.$i18n;
                this.axios({
                    url: '/Default.aspx/PrintRates',
                    method: 'POST',
                    responseType: 'blob',
                    data:
                    {
                        request: {
                            fileId: fileId,
                            selectedOfferIds: this.getSelectedOfferIds(),
                            sortProperty: this.$refs.ratesResultTable[0].getSortProperty()
                        }
                    }
                }).then((response) => {
                    var blob = new Blob([response.data], {
                        type: 'application/pdf'
                    });
                    const url = window.URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = url;
                    link.download = response.headers.filename;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();

                    setTimeout(function () {
                        document.body.removeChild(link);
                        window.URL.revokeObjectURL(url);
                    }, 5000);
                }).catch(function () {
                    alert(i18n.t('credit.app.pdfGenerateError'));
                });
            },
            sendPrintRatesToUrlDataExtendedRequest: function (pdfSendData) {
                this.axios({
                    url: '/Default.aspx/PrintRatesToUrlDataExtended',
                    method: 'POST',
                    responseType: 'application/json',
                    data:
                    {
                        request: {
                            request: {
                                selectedOfferIds: this.getSelectedOfferIds(),
                                sortProperty: this.$refs.ratesResultTable[0].getSortProperty()
                            },
                            pdfSendData: pdfSendData
                        }
                    }
                }).then((response) => {
                    this.newPrintMessage(JSON.parse(response.data.d));
                }).catch(function () {
                    this.newPrintMessage('PDF se nepodařilo odeslat');
                })
            },
            printRatesToUrlRequest: function () {
                this.axios({
                    url: '/Default.aspx/PrintRatesToUrl',
                    method: 'POST',
                    responseType: 'application/json',
                    data:
                    {
                        request: {
                            selectedOfferIds: this.getSelectedOfferIds(),
                            sortProperty: this.$refs.ratesResultTable[0].getSortProperty()
                        }
                    }
                }).then((response) => {
                    var data = JSON.parse(response.data.d);
                    if (data.RedirectUrl)
                        //window.location.href = data.RedirectUrl;
                        window.open(data.RedirectUrl, "_blank");
                    else
                        this.newPrintMessage(data.Message);
                }).catch(function () {
                    this.newPrintMessage('PDF se nepodařilo uložit ke klientovi');
                });
            },
            newPrintMessage(val) {
                this.alertTitle = 'Uložení modelace';
                this.alertMessage = val;
                $('#alertModal').modal();
            },
            openFilterModal() {
                this.filterPartnersToEdit = JSON.parse(JSON.stringify(this.filter.filterPartners));
                const options = { backdrop: 'static' };
                $('#filterModal').modal(options);
            },
            openFilterModalSpecific(index) {
                for (var i = 0; i < this.filter.filterPartners.length; i++) {
                    if (i === index) this.filter.filterPartners[i].active = true;
                    else this.filter.filterPartners[i].active = false;
                }
                this.openFilterModal();
            },
            openMethodologyFilterModal() {
                this.filterPartnersToEdit = JSON.parse(JSON.stringify(this.filter.filterPartners));
                const options = { backdrop: 'static' };
                $('#methodologyfilterModal').modal(options);
            },
            saveFilter() {
                this.filter.filterPartners = JSON.parse(JSON.stringify(this.filterPartnersToEdit));
                this.ratesResultItems = [];
                this.callRates();
            },
            saveMethodologyFilter(items) {
                this.methodologyFilter = items;
                this.filter.filterPartners = JSON.parse(JSON.stringify(this.filterPartnersToEdit));
                this.ratesResultItems = [];
                this.callRates();
            },
            resetCalculation() {
                this.resetRatesForm();
                this.resetForm();
                for (var i = 0; i < this.dummyPartners.length; i++) {
                    this.dummyPartners.selected = true;
                }
                this.resultItems = [];
                this.ratesResultItems = [];
                this.activePanels.resultsType = '';
                this.activePanels.rates = true;
                this.activePanels.creditWorthiness = false;
                $('#ratesPanel').css('display', 'none');
                $('#creditWorthinessPanel').css('display', 'none');

                this.axios({
                    url: '/Default.aspx/ResetSession',
                    method: 'POST',
                    responseType: 'application/json',
                    data: {}
                }).catch(function () {
                    alert("Chyba při resetu dat na pozadí");
                });
            },
            sendUsersClientData() {
                if (this.ratesResultItems && this.usersClientData) {
                    this.printRatesToUrlRequest();
                }
            }
        },
        mounted: function () {
            this.localization = this.$i18n.locale;
            window.app = this;
            //if (!window.location.pathname.includes("/Default")) return;
            LoadEnumTypes(this);
        },
        async beforeMount() {
            const applicantInCircle = await getIcon('ApplicantInCircle');
            const revertIcon = await getIcon('RevertIcon');
            const revertIconInCircle = await getIcon('RevertIconInCircle');
            const plusIcon = await getIcon('PlusIcon');
            const plusIconInCircle = await getIcon('PlusIconInCircle');
            this.iconsLibrary = {
                applicantInCircle,
                revertIcon,
                revertIconInCircle,
                plusIcon,
                plusIconInCircle
            }
        }
    }

</script>

<style scoped>
    hr {
        margin-bottom: 20px;
    }

    .baseInfo {
        padding: 0 40px;
        padding-top: 10px;
    }

    .nav-tabs > li {
        margin-bottom: -2px;
    }

    i.fas.fa-trash-alt, i.fas.fa-undo-alt {
        color: dimgray;
    }

        i.fas.fa-trash-alt:hover {
            color: black;
            cursor: pointer;
        }

        i.fas.fa-undo-alt:hover {
            color: black;
            cursor: pointer;
        }

    .MMBFocused {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;
        width: 100%;
    }

        .MMBFocused img {
            height: 20px;
            width: auto;
            margin: 6px 6px;
        }

        .MMBFocused > label {
            line-height: 17px;
            text-align: right;
            padding-right: 10px;
        }

    @media (max-width: 991px) {
        .MMBFocused > .imgWrapper {
            padding-left: 2px;
        }

        .MMBFocused > label {
            width: 40%;
            padding-right: 15px;
        }
    }

    .form-group-pb20 {
        padding-bottom: 20px;
    }

    .amount-LTVs {
        position: absolute;
        top: 58px;
        font-size: 12px;
        color: #333;
    }

    @media (min-width: 991px) and (max-width: 1199px) {
        .amount-LTVs {
            font-size: 11px;
        }
    }

    .amount-LTVs > a {
        padding: 0 3px;
        color: #333;
        cursor: pointer;
    }

    .image-label {
        margin: 6px 0;
        font-weight: bold;
        font-size: 13px;
    }

        .image-label:nth-last-of-type(n+2) {
            margin: 6px 0;
            font-weight: bold;
            font-size: 13px;
            float: left;
        }

    ul.nav-tabs li h4 {
        display: inline-flex;
    }

    ul.nav-tabs li:first-child span.nav-cell-title {
        pointer-events: none;
    }

    @media (max-width: 420px) {
        ul.nav-tabs li span.nav-cell-title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 80px;
        }

        .baseInfo {
            padding: 0;
        }
    }

    @media (max-width: 370px) {
        ul.nav-tabs li:last-child i.credit-worthiness-add-household + span {
            display: none;
        }
    }

    .credit-worthiness-action-buttons {
        margin-top: 5px;
    }

        .credit-worthiness-action-buttons button {
            margin-top: 10px;
        }

            .credit-worthiness-action-buttons button:not(:last-of-type) {
                margin-right: 10px;
            }

    @media (max-width: 600px) {
        .credit-worthiness-action-buttons {
            width: 100%;
        }

        .credit-worthiness-action-buttons button {
            width: 100%;
        }
    }

    

    .row > * {
        padding-left: 8px;
        padding-right: 8px;
    }

    ul.nav-result-tables {
        /*display: flex;*/
    }

        ul.nav-result-tables > li {
            /*float: left;*/
            padding: 8px 24px;
            color: #666666;
            border-bottom: 1px solid #E6E6E6;
            cursor: pointer;
        }


           /* ul.nav-result-tables > li.not-nav {
                border: none;
                padding: 8px 0;
                cursor: default;
                display: flex;
            }

                ul.nav-result-tables > li.not-nav .btn {
                    margin-left: 8px;
                }*/

            ul.nav-result-tables > li.active {
                color: #2A77D1;
                border-bottom-color: #2A77D1;
            }

            ul.nav-result-tables > li > img {
                width: 34px;
                margin-left: 10px;
                filter: brightness(0) saturate(100%) invert(42%) sepia(0%) saturate(243%) hue-rotate(152deg) brightness(94%) contrast(96%);
            }

            ul.nav-result-tables > li.active > img {
                filter: brightness(0) saturate(100%) invert(37%) sepia(82%) saturate(1250%) hue-rotate(192deg) brightness(88%) contrast(86%);
            }

    li.not-nav > .alert {
        margin: 3px 0 0 10px;
        padding: 4px 8px 4px 0;
        border-radius: 7px;
    }
</style>

<style>
    div.vs__selected-options {
        overflow: hidden;
        white-space: nowrap;
        flex-wrap: nowrap;
    }

    .tooltip.white.top .tooltip-inner {
        margin-bottom: 10px;
    }

    .tooltip.white.right .tooltip-inner {
        margin-left: 10px;
    }

    .tooltip.white.bottom .tooltip-inner {
        margin-top: 10px;
    }

    .tooltip.white.left .tooltip-inner {
        margin-right: 10px;
    }

    .tooltip.white .tooltip-inner {
        background-color: #fff;
        color: #000;
        font-size: 14px;
        border: 1px solid rgba(0,0,0,.2);
        border-radius: 6px;
        -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
        box-shadow: 0 5px 10px rgba(0,0,0,.2);
        padding: 9px 14px;
    }

    .tooltip.white.top .tooltip-arrow {
        bottom: -6px;
        border-top-color: #fff;
    }

        .tooltip.white.top .tooltip-arrow:after {
            border-top-color: rgba(0,0,0,.2);
            top: -11px;
            left: -12px;
        }

    .tooltip.white.right .tooltip-arrow {
        left: -6px;
        border-right-color: #fff;
        margin-top: -10px;
    }

        .tooltip.white.right .tooltip-arrow:after {
            border-right-color: rgba(0,0,0,.2);
            top: -12px;
            right: -11px;
        }

    .tooltip.white.bottom .tooltip-arrow {
        top: -6px;
        border-bottom-color: #fff;
    }

        .tooltip.white.bottom .tooltip-arrow:after {
            border-bottom-color: rgba(0,0,0,.2);
            bottom: -11px;
            left: -12px;
        }

    .tooltip.white.left .tooltip-arrow {
        right: -6px;
        border-left-color: #fff;
        margin-top: -10px;
    }

        .tooltip.white.left .tooltip-arrow:after {
            border-left-color: rgba(0,0,0,.2);
            top: -12px;
            left: -11px;
        }


    .tooltip.white .tooltip-arrow {
        border-color: transparent;
        border-width: 11px;
    }

        .tooltip.white .tooltip-arrow:after {
            border: 12px solid transparent;
            content: "";
            position: absolute;
            z-index: -1;
        }

    i.fa-in-circle {
        font-size: 10px;
        cursor: pointer;
        color: #fff;
        background-color: #000;
        border-radius: 10px;
        height: 20px;
        width: 20px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .household-revert-changes > i {
        color: dimgray;
        cursor: pointer;
    }

        .household-revert-changes > i:hover {
            color: black;
        }

    .star-comment:before {
        content: "* ";
        color: red;
        font-size: 25px;
    }

    .empty-form-group {
        height: 68px;
    }

    .container {
        background-color: #f5f5f5;
    }
</style>
