<template>
    <div class="hypo-form-group">
        <label 
            :for="selectName" 
            :class="labelClass">
            {{ label }} <img v-if="labelTooltip" 
                                class="label-help" 
                                src="@/assets/icons/help.svg" 
                                v-bPopover="labelTooltip" />
        </label>
        <div class="splatnostRow" :data-toggle="inputTooltip ? 'tooltip' : null" :title="inputTooltip" data-placement="auto" data-container="body">
            <v-select-list :selectName="selectName"
                           :items="items"
                           v-model="localValue"
                           :addonString="addonString"
                           :searchable="searchable"
                           @update:modelValue="onInput"
                           style="width: 50%">
            </v-select-list>
            <v-select-list :selectName="selectName2"
                           :items="items2"
                           v-model="localValue2"
                           :addonString="addonString2"
                           :searchable="searchable2"
                           @update:modelValue="onInput"
                           style="width: 50%">
            </v-select-list>
        </div>
        
    </div>
</template>

<script>
    import SelectList from './SelectList.vue'

    export default {
        name: 'v-maturity-select-list',
        components: {
            'v-select-list': SelectList
        },
        data() {
            return {
                localValue: (Math.floor(this.modelValue / 12) * 12).toString(),
                localValue2: (this.modelValue % 12).toString(),
            }
        },
        methods: {
            onInput() {
                this.$emit('update:modelValue', parseInt(this.localValue) + parseInt(this.localValue2));
            }
        },
        emits: ['update:modelValue'],
        props: {
            selectName: {
                type: String,
                required: true
            },
            selectName2: {
                type: String,
                required: true
            },
            modelValue: String,
            items: Array,
            items2: Array,
            label: String,
            addonString: String,
            addonString2: String,
            searchable: Boolean,
            searchable2: Boolean,
            inputSize: {
                type: Number,
                default: 60
            },
            labelTooltip: String,
            inputTooltip: String,
            labelClass: String
        }
    }
</script>

<style scoped>
    .hypo-form-group {
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
    }

    .hypo-form-group > label {
        color: #333;
        font-weight: normal;
        padding-bottom: 4px
    }

    img.label-help {
        filter: brightness(0) saturate(100%) invert(15%) sepia(0%) saturate(0%) hue-rotate(243deg) brightness(99%) contrast(86%);
    }

    img.label-help:hover {
        filter: brightness(0) saturate(100%) invert(50%) sepia(8%) saturate(7287%) hue-rotate(186deg) brightness(81%) contrast(102%);
    }
    
    .splatnostRow{
        display: flex;
    }
</style>