<template>
    <div :class="{ 'input-group': hasAddon }">
        <vue-numeric v-if="number" :id="inputId" :class="[ 'form-control', { 'is-invalid': anyError }, { 'has-icon': iconDef }]" v-model="inputValue" :disabled="disabledInput" @input="onInput" @blur="onBlur" :min="numberMin" :minus="minus" separator="space" :precision="precision" />
        <input v-else :id="inputId" v-model="inputValue" @input="onInput" :class="[ 'form-control', { 'is-invalid': anyError }]" :disabled="disabledInput" @blur="onBlur" :placeholder="placeholder" :maxlength="maxLength" />
        <i v-if="iconDef" :class="[ iconDef, { 'addon-active': hasAddon } ]" @click="iconClicked" data-toggle="tooltip" :title="iconTooltip"></i>
        <i v-if="anyError" :class="[ 'fas fa-exclamation-circle', { 'addon-active': hasAddon }, { 'is-second': iconDef } ]" data-container="body" data-toggle="popover" :data-content="errors"></i>
        <span v-if="hasAddon" class="input-group-addon">{{addonString}}</span>

        <div class="comboboxList" :id="listIdPrefix + inputId">
            <ul>
                <li v-for="item in items" :key="item.id" @click="itemSelected(item)">
                    {{ item.text }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'v-combobox',
        data() {
            return {
                listIdPrefix: 'comboBoxList',
                modalOpened: false,
                inputValue: this.modelValue,
            }
        },
        methods: {
            onInput: function () {
                this.$emit('update:modelValue', this.inputValue);
            },
            onBlur: function () {
                this.$emit('blured');
                if (this.number) {
                    if (+this.inputValue > +this.numberMax) {
                        this.inputValue = this.numberMax;
                    }
                    else if (+this.inputValue < +this.numberMin) {
                        this.inputValue = this.numberMin;
                    }
                    this.onInput();
                }
            },
            iconClicked(e) {
                if (this.modalOpened) {
                    this.hideModal();
                }
                else {
                    // eslint-disable-next-line
                    $('#' + this.listIdPrefix + this.inputId).css("display", "block");
                    this.modalOpened = true;
                    this.addModalhideHandler();
                }
                e.stopPropagation();
            },
            hideModal() {
                // eslint-disable-next-line
                $('#' + this.listIdPrefix + this.inputId).css("display", "none");
                this.modalOpened = false;
            },
            addModalhideHandler() {
                // eslint-disable-next-line
                $(document.body).click((e) => {
                    this.hideModal();
                    this.removeModalhideHandler();
                    e.stopPropagation();
                });
            },
            removeModalhideHandler() {
                // eslint-disable-next-line
                $(document.body).off('click');
            },
            itemSelected(item) {
                this.inputValue = item.value;
                this.onInput();
            }
        },
        watch: {
            modelValue() {
                this.inputValue = this.modelValue;
            }
        },
        computed: {
            iconDef() {
                return this.modalOpened ? "fas fa-caret-up" : "fas fa-caret-down";
            },
            hasAddon: function () {
                if (this.addonString !== undefined) return true;
                return false;
            },
            anyError: function () {
                if (this.errors == undefined) return false;
                return this.errors.length;
            }
        },
        emits: ['update:modelValue'],
        props: {
            modelValue: String,
            addonString: String,
            inputId: {
                type: String,
                required: true
            },
            number: {
                type: Boolean,
                default: false
            },
            numberMin: Number,
            numberMax: Number,
            maxLength: Number,
            iconTarget: String,
            iconToggle: {
                type: String,
                default: 'modal'
            },
            precision: Number,
            placeholder: String,
            iconTooltip: String,
            inputTooltip: String,
            minus: {
                type: Boolean,
                default: false
            },
            errors: Array,
            disabledInput: {
                type: Boolean,
                default: false
            },
            items: Array
        }
    }
</script>

<style scoped>
    i {
        color: #bfcbd9;
        position: absolute;
        top: 0;
        right: 10px;
        z-index: 2;
        display: block;
        line-height: 34px;
        text-align: center;
        cursor: pointer;
        font-size: 20px;
    }

        i.addon-active {
            right: 53px;
        }

    .form-control.is-invalid {
        border-color: #dc3545;
    }

    .fa-exclamation-circle {
        color: #dc3545;
        right: 20px;
    }

    i.addon-active.fa-exclamation-circle {
        right: 43px;
    }

        i.addon-active.fa-exclamation-circle.is-second {
            right: 75px;
        }

    input.has-icon {
        padding-right: 35px;
    }

    .comboboxList {
        display: none;
        position: absolute;
        left: 0;
        top: 34px;
        z-index: 3;
        width: -webkit-fill-available;
        border: solid 1px #d1dbe5;
        border-radius: 2px;
        background-color: #fff;
        box-shadow: 0 2px 4px rgb(0 0 0 / 12%), 0 0 6px rgb(0 0 0 / 4%);
        box-sizing: border-box;
        margin: 5px 0;
    }

        .comboboxList > ul {
            list-style: none;
            padding: 6px 0;
            margin: 0;
            box-sizing: border-box;
        }

            .comboboxList > ul > li {
                height: unset !important;
                white-space: unset !important;
                font-size: 14px;
                padding: 8px 10px;
                position: relative;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #48576a;
                line-height: 1.5;
                box-sizing: border-box;
                cursor: pointer;
            }

                .comboboxList > ul > li:hover {
                    background-color: #e4e8f1;
                }

                .comboboxList > ul > li.selected {
                    color: #fff;
                    background-color: #20a0ff;
                }

                    .comboboxList > ul > li.selected:hover {
                        background-color: #1c8de0;
                    }

    
</style>