<template>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.starobniDuchod')" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.starobniDuchod')" v-model="applicant.incomes.otherIncomesCz.starobniDuchod" number="true" :addonString="$t('global.currencySymbol')" inputId="pension" methodologyItem='"Starobní důchod"'></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.rodicovskyPrispevek')" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.rodicovskyPrispevek')" v-model="applicant.incomes.otherIncomesCz.rodicovskyPrispevek" number="true" :addonString="$t('global.currencySymbol')" inputId="parentalAllowance" methodologyItem='"Rodičovský příspěvek"'></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.invalidniDuchod12')" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.invalidniDuchod12')" v-model="applicant.incomes.otherIncomesCz.invalidniDuchod12" number="true" :addonString="$t('global.currencySymbol')" inputId="firstAndSecondGradeInvalidPension" methodologyItem='"Invalidní důchod"'></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.materska')" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.materska')" v-model="applicant.incomes.otherIncomesCz.materska" number="true" :addonString="$t('global.currencySymbol')" inputId="maternityBenefit" methodologyItem="Peněžitá pomoc v mateřství"></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.invalidniDuchod')" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.invalidniDuchod')" v-model="applicant.incomes.otherIncomesCz.invalidniDuchod" number="true" :addonString="$t('global.currencySymbol')" inputId="thirdGradeInvalidPension" methodologyItem='"Invalidní důchod"'></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.vyzivne')" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.vyzivne')" v-model="applicant.incomes.otherIncomesCz.vyzivne" number="true" :addonString="$t('global.currencySymbol')" inputId="alimonyIncome" methodologyItem="Výživné"></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.dpc')" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.dpc')" v-model="applicant.incomes.otherIncomesCz.dpc" number="true" :addonString="$t('global.currencySymbol')" inputId="employmentAgreementIncome" methodologyItem='"Dohoda o provedení činnosti"'></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.najemniSmlouva')" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.najemniSmlouva')" v-model="applicant.incomes.otherIncomesCz.najemniSmlouva" number="true" :addonString="$t('global.currencySymbol')" inputId="leaseAgreementIncome" methodologyItem='"Příjem z pronájmu neuvedený v DP"'></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.odmenaPestouna')" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.odmenaPestouna')" v-model="applicant.incomes.otherIncomesCz.odmenaPestouna" number="true" :addonString="$t('global.currencySymbol')" inputId="fosterParentReward" methodologyItem="Odměna pěstouna"></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.cestovne')" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.cestovne')" v-model="applicant.incomes.otherIncomesCz.cestovne" number="true" :addonString="$t('global.currencySymbol')" inputId="travelExpenses" methodologyItem='"Cestovní náhrady (diety)"'></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.budoucniPronajem')" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.budoucniPronajem')" v-model="applicant.incomes.otherIncomesCz.budoucniPronajem" number="true" :addonString="$t('global.currencySymbol')" inputId="futureRentalIncome" methodologyItem='"Příjem z budoucího pronájmu"'></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.vysluhaPolicieVojaci')" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.vysluhaPolicieVojaci')" v-model="applicant.incomes.otherIncomesCz.vysluhaPolicieVojaci" number="true" :addonString="$t('global.currencySymbol')" inputId="retairementRentPolice" methodologyItem='"Výsluhový příspěvek"'></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.nahradaZtratyVydelku')" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.nahradaZtratyVydelku')" v-model="applicant.incomes.otherIncomesCz.nahradaZtratyVydelku" number="true" :addonString="$t('global.currencySymbol')" inputId="lossOfEarningsCompensation" methodologyItem='"Trvalé náhrady za ztrátu výdělku"'></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.vysluhaHornici')" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.vysluhaHornici')" v-model="applicant.incomes.otherIncomesCz.vysluhaHornici" number="true" :addonString="$t('global.currencySymbol')" inputId="retairementRentMiners" methodologyItem='"Výsluhový příspěvek"'></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.vdovskyDuchod')" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.vdovskyDuchod')" v-model="applicant.incomes.otherIncomesCz.vdovskyDuchod" number="true" :addonString="$t('global.currencySymbol')" inputId="widowPension" methodologyItem='"Vdovský důchod"'></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.prispevekNaBydleni')" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.prispevekNaBydleni')" v-model="applicant.incomes.otherIncomesCz.prispevekNaBydleni" number="true" :addonString="$t('global.currencySymbol')" inputId="housingAllowance" methodologyItem='"Příspěvek na bydlení pro vojáky"'></v-labeled-input-methodology-link>
            </div>
            <div class="col-md-6">
                <v-labeled-input-methodology-link :label="$t('credit.incomesOtherModal.otherIncomesCz.sirotciDuchod')" :labelTooltip="$t('tooltip.credit.incomesOtherModal.otherIncomesCz.sirotciDuchod')" v-model="applicant.incomes.otherIncomesCz.sirotciDuchod" number="true" :addonString="$t('global.currencySymbol')" inputId="orphanPension" methodologyItem='"Sirotčí důchod"'></v-labeled-input-methodology-link>
            </div>
        </div>

        <div class="modal fade" id="confirmModalOtherIncomes" tabindex="-1" role="dialog" aria-labelledby="confirmModalLabelOther" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" id="confirmModalLabelOther">{{ $t('credit.applicant.incomes.otherConfirmation.title') }}</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-2">
                                <label class="persons-label">{{ $t('credit.household.numberOfPersons') }}</label>
                            </div>
                            <div class="col-md-10">
                                <div class="row">
                                    <div class="col-md-3">
                                        <v-labeled-input inputId="modalDite5-" :label="$t('credit.household.child0_6')" v-model="household.dite5minusCount" number="true"></v-labeled-input>
                                    </div>
                                    <div class="col-md-3">
                                        <v-labeled-input inputId="modalDite6+" :label="$t('credit.household.child6_10')" v-model="household.dite6plusCount" number="true"></v-labeled-input>
                                    </div>
                                    <div class="col-md-3">
                                        <v-labeled-input inputId="modalDite10+" :label="$t('credit.household.child10_15')" v-model="household.dite10plusCount" number="true"></v-labeled-input>
                                    </div>
                                    <div class="col-md-3">
                                        <v-labeled-input inputId="modalDite15+" :label="$t('credit.household.child15_26')" v-model="household.dite16plusCount" number="true"></v-labeled-input>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p class="confirmationWarning">
                            {{ $t('credit.applicant.incomes.otherConfirmation.text') }}
                            <ul>
                                <li>{{ $t('tooltip.credit.incomesOtherModal.otherIncomesCz.rodicovskyPrispevek') }}</li>
                                <li>{{ $t('tooltip.credit.incomesOtherModal.otherIncomesCz.materska') }}</li>
                                <li>{{ $t('tooltip.credit.incomesOtherModal.otherIncomesCz.vyzivne') }}</li>
                            </ul>
                        </p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal" @click="saveChanges" id="btnIncomesOtherSaveChangesConfirmation">{{ $t('global.save') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LabeledInput from '../LabeledInput.vue'
    import LabeledInputMethodologyLink from '../LabeledInputMethodologyLink.vue'

    export default {
        name: 'v-incomes-other-modal-body',
        components: {
            'v-labeled-input': LabeledInput,
            'v-labeled-input-methodology-link': LabeledInputMethodologyLink
        },
        watch: {
            'applicant.incomes.otherIncomesCz': {
                handler() {
                    {
                        this.$emit('update:applicant', this.applicant);
                    }
                },
                deep: true
            },
            'household': {
                handler() {
                    {
                        this.$emit('update:household', this.household);
                    }
                },
                deep: true
            },
        },
        methods: {
            saveChanges() {
                this.$emit('saveChanges');
            },
            checkChildIncome() {
                if (this.childIncomesActive() && !this.hasChild()) {
                    $('#confirmModalOtherIncomes').modal('show');
                }
                else {
                    this.saveChanges();
                }
            },
            childIncomesActive() {
                if (this.applicant.incomes.otherIncomesCz.rodicovskyPrispevek) return true;
                if (this.applicant.incomes.otherIncomesCz.materska) return true;
                if (this.applicant.incomes.otherIncomesCz.vyzivne) return true;
                return false;
            },
            hasChild() {
                if (this.household.dite5minusCount) return true;
                if (this.household.dite6plusCount) return true;
                if (this.household.dite10plusCount) return true;
                if (this.household.dite16plusCount) return true;
                return false;
            }
        },
        props: {
            applicant: Object,
            personCounts: Array,
            household: Object,
            localizedProvider: String
        }
    }
</script>

<style scoped>
    .modal-dialog {
        width: 700px;
    }

    label.persons-label {
        margin-top: 27px;
        white-space: nowrap;
    }

    .confirmationWarning {
        margin-top: 10px;
    }
</style>

