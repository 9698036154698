<template>
    <div class="hypo-form-group">
        <label :for="inputId"
               :class="[labelClass, { required : isRequired }]">
            {{ label }} <img v-if="labelTooltip"
                             class="label-help"
                             src="@/assets/icons/help.svg"
                             v-bPopover="labelTooltip" />
        </label>
        <v-combobox class="hypo-input"
                 :inputId="inputId"
                 v-model="localValue"
                 @update:modelValue="onInput"
                 :addonString="addonString"
                 :number="number"
                 :numberMin="numberMin"
                 :numberMax="numberMax"
                 :maxLength="maxLength"
                 :iconTarget="iconTarget"
                 :iconToggle="iconToggle"
                 :precision="precision"
                 :errors="errors"
                 :iconTooltip="iconTooltip"
                 :minus="minus"
                 :disabledInput="disabledInput"
                 @blured="$emit('blured')"
                 :items="items"   
                    />
    </div>
</template>

<script>
    import Combobox from './Combobox.vue'

    export default {
        name: 'v-labeled-combobox',
        components: {
            'v-combobox': Combobox
        },
        data() {
            return {
                localValue: this.modelValue
            }
        },
        watch: {
            modelValue() {
                this.localValue = this.modelValue;
            }
        },
        methods: {
            onInput(val) {
                this.$emit('update:modelValue', val);
            }
        },
        props: {
            label: String,

            modelValue: String,
            addonString: String,
            inputId: {
                type: String,
                required: true
            },
            number: Boolean,
            numberMin: Number,
            numberMax: Number,
            maxLength: Number,
            iconTarget: String,
            iconToggle: String,
            precision: Number,
            errors: Array,
            inputSize: {
                type: Number,
                default: 40
            },
            iconTooltip: String,
            labelTooltip: String,
            inputTooltip: String,
            labelClass: String,
            minus: Boolean,
            isRequired: Boolean,
            disabledInput: Boolean,
            items: Array
        }
    }
</script>

<style scoped>
    label {
        white-space: pre-line;
    }

        label.required::after {
            content: "*";
        }

    .hypo-form-group {
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
    }

        .hypo-form-group > label {
            color: #333;
            font-weight: normal;
            padding-bottom: 4px
        }

    img.label-help {
        filter: brightness(0) saturate(100%) invert(15%) sepia(0%) saturate(0%) hue-rotate(243deg) brightness(99%) contrast(86%);
    }

        img.label-help:hover {
            filter: brightness(0) saturate(100%) invert(50%) sepia(8%) saturate(7287%) hue-rotate(186deg) brightness(81%) contrast(102%);
        }

</style>