<template>
    <ul>
        <li v-for="item in items" :key="item.label" :class="{ 'filter-checkbox-item' : !item.children || item.type === 'foreigners' }">
            <template v-if="item.type === 'foreigners'">
                <label @click="item.unwraped = !item.unwraped">
                    <!--<img :class="[ 'heading-arrow', { rotated: !item.unwraped}]" src="/CreditWorthiness/img/downFill.svg">-->
                    <input type="checkbox" v-model="item.isChecked">
                    {{item.label}}
                </label>
                <div v-if="item.isChecked" style="margin-left: 38px;">
                    <div class="row">
                        <div class="col-md-4">
                            <v-labeled-select-list selectName="country"
                                                   label="Země cizince"
                                                   labelClass="bold"
                                                   v-model="item.country"
                                                   :items="methodologyForeignersSettings.countries" />
                        </div>
                        <div class="col-md-4">
                            <v-labeled-select-list selectName="country"
                                                   label="Typ pobytu"
                                                   labelClass="bold"
                                                   v-model="item.typeOfStay"
                                                   :items="methodologyForeignersSettings.typesOfStay" />
                        </div>
                        <div class="col-md-4">
                            <v-labeled-select-list selectName="country"
                                                   label="Druh úvěru"
                                                   labelClass="bold"
                                                   v-model="item.loanPurpose"
                                                   :items="methodologyForeignersSettings.loanPurposes" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            Zohlednit:
                            <label>
                                <input type="checkbox"
                                       class="check-switch"
                                       v-model="item.czechIncome" />
                                Příjem z ČR
                            </label>
                            <label>
                                <input type="checkbox"
                                       class="check-switch"
                                       v-model="item.maxLtv" />
                                Max. LTV
                            </label>
                            <label>
                                <input type="checkbox"
                                       class="check-switch"
                                       v-model="item.maxMaturity" />
                                Max. splatnost
                            </label>
                            <label>
                                <input type="checkbox"
                                       class="check-switch"
                                       v-model="item.maxLoanAmount" />
                                Max. výše úvěru
                            </label>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <label @click="item.unwraped = !item.unwraped">
                    <img v-if="item.children" :class="[ 'heading-arrow', { rotated: !item.unwraped}]" src="/CreditWorthiness/img/downFill.svg">
                    <input v-else type="checkbox" v-model="item.isChecked">
                    {{item.label}}
                </label>
                <v-tree-items-checkboxes v-if="item.unwraped" :items="item.children" />
            </template>
        </li>
    </ul>
</template>

<script>
    import LabeledSelectList from '../LabeledSelectList.vue'
    export default {
        name: 'v-tree-items-checkboxes',
        components: {
            'v-labeled-select-list': LabeledSelectList,
        },
        props: {
            items: Array,
            methodologyForeignersSettings: Object
        }
    }

</script>

<style scoped>
    ul {
        list-style: none;
    }

    label {
        padding: 8px;
        cursor: pointer;
    }

    .heading-arrow.rotated {
        transform: rotate(270deg);
    }
</style>

