<template>
    <v-pdf-send-extended-data-modal :clientName.sync="pdfSendData.clientName" :clientSurname.sync="pdfSendData.clientSurname" :clientBirthDate.sync="pdfSendData.clientBirthDate" :label.sync="pdfSendData.label" v-on:sendPrintToUrlDataExtendedRequest="sendPrintToUrlDataExtendedRequest()" :sendCompleted="pdfSendCompleted" :sendStateMessage="pdfSendStateMessage"></v-pdf-send-extended-data-modal>
</template>

<script>
    import PdfSendExtendedDataModal from './components/PdfSendExtendedDataModal.vue'

    export default {
        name: 'app',
        components: {
            'v-pdf-send-extended-data-modal': PdfSendExtendedDataModal
        },
        data() {
            return {
                pdfSendData: {
                    clientName: '',
                    clientSurname: '',
                    clientBirthDate: '',
                    label: ''
                },
                pdfSendCompleted: false,
                pdfSendStateMessage: ''
            }
        },
        methods: {
            sendPrintToUrlDataExtendedRequest: function () {
                $('#hdnClientName').val(this.pdfSendData.clientName);
                $('#hdnClientSurname').val(this.pdfSendData.clientSurname);
                $('#hdnClientBirthdate').val(this.pdfSendData.clientBirthDate);
                $('#hdnLabel').val(this.pdfSendData.label);

                $('#hdnBtnSendPdfDataExtended').click();
            },
            sendRequestCompleted: function (message) {
                this.pdfSendCompleted = true;
                this.pdfSendStateMessage = message
            }
        }
    }
</script>
