<template>
    <div :class="{ 'hypo-addon-group': hasAddon }" @click="hideSelected">
        <v-select v-if="!multiselect" v-model="localValue" label="name" :options="items" :reduce="option => option.value" @update:modelValue="onInput" :clearable="false">
            <template v-slot:no-options>
                {{ $t('credit.app.noResults') }}
            </template>
        </v-select>
        <v-select v-else multiple v-model="localValue" label="name" :options="items" :reduce="option => option.value" @update:modelValue="onInput" :clearable="false">
            <template v-slot:no-options>
                {{ $t('credit.app.noResults') }}
            </template>
        </v-select>

        <span v-if="hasAddon" class="input-group-addon">{{addonString}}</span>
    </div>
</template>
<script>
    export default {
        name: 'v-select-list',
        data() {
            return {
                localValue: this.modelValue,
            }
        },
        watch: {
            modelValue() {
                this.localValue = this.modelValue;
            }
        },
        computed: {
            hasAddon: function () {
                if (this.addonString !== undefined) return true;
                return false;
            }
        },
        methods: {
            onInput(val) {
                this.$emit('update:modelValue', val);
            }
        },
        emits: ['update:modelValue'],
        props: {
            selectName: {
                type: String,
                required: true
            },
            items: Array,
            modelValue: String,
            addonString: String,
            multiselect: {
                type: Boolean,
                default: false
            },
        }
    }
</script>

<style scoped>
    .hypo-addon-group {
        display: table;
    }
</style>

<style>
    .hypo-select:focus {
        border: 2px solid rebeccapurple;
    }


    .sharpRightEdge input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .vm-select-input__inner {
        width: 100% !important;
        border-color: #ccc !important;
        padding: 6px 35px 6px 12px !important;
        text-overflow: ellipsis;
    }

        .vm-select-input__inner:focus {
            border-color: #66afe9 !important;
            outline: 0;
            -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        }

    .vm-select{
        width: 100%;
    }

    .vm-select-dropdown{
        width: 0;
    }

    .vm-select-dropdown__item {
        height: unset !important;
        white-space: unset !important;
    }

    .vm-scrollbar__bar{
        width: 10px !important;
    }

        .vm-scrollbar__bar.vertical .vm-scrollbar__thumb {
            background-color: rgba(151, 168, 190, 0.7);
            /*min-height:*/ 
        }

        .cursor-text{
            cursor: text !important;
        }
</style>