<!-- eslint-disable vue/no-v-model-argument -->
<template>
    <div>
        <div class="col-md-12 page-title">
            <span>{{ $t('storedCalc.title') }}</span>
        </div>

        <div v-for="alert in alerts" v-bind:key="alert" :class="['alert', alert.class ]" role="alert">
            {{ alert.message }}
        </div>

        <div class="col-md-12">
            <ul class="rates-module-select">
                <li :class="{ active : selectedPanel == 'all' }" @click="selectedPanel = 'all'">
                    <span>{{ $t('storedCalc.allCalculations') }}</span>
                </li>
                <li :class="{ active : selectedPanel == 'rates' }" @click="selectedPanel = 'rates'">
                    <img class="heading-icon" src="@/assets/icons/sazby.svg" />
                    <span>{{ $t('storedCalc.sazbyBonita') }}</span>
</li>
                <li :class="{ active : selectedPanel == 'payments' }" @click="selectedPanel = 'payments'">
                    <img class="heading-icon" src="@/assets/icons/prijmy_pausal.svg" />
                    <span>{{ $t('storedCalc.payments') }}</span>
                </li>
                <li :class="{ active : selectedPanel == 'refinance' }" @click="selectedPanel = 'refinance'">
                    <img class="heading-icon" src="@/assets/icons/prijmy_ostatni.svg" />
                    <span>{{ $t('storedCalc.refinance') }}</span>
                </li>
            </ul>
        </div>
        
        <table v-if="windowWidth > 992" class="table table-hypo" style="margin-top: 0">
            <thead>
                <tr>
                    <th style="position:unset"></th>
                    <th style="position:unset" colspan="11" class="filterRow">
                        <div>
                            {{ $t('storedCalc.table.modelationTitle') }}
                            <br />
                            <div style="position:relative" class="mx-input-wrapper">
                                <input v-model="find" class="form-control" />
                                <i v-if="find == ''" class="inBox fas fa-search"></i>
                                <i v-else class="inBox fas fa-times" @click="removeFindValue()"></i>
                            </div>
                        </div>
                        <div>
                            {{ $t('storedCalc.table.modelationDateTime') }}<br />
                            <date-picker v-model:value="dateRange" range format="DD-MM-YYYY"></date-picker>
                        </div>
                    </th>
                </tr>
                <tr class="noBackground">
                    <th></th>
                    <v-table-header-with-sort :headerValue="$t('storedCalc.table.clientTitle')"
                                              :sortASC="sortProperty === sortProperties.name"
                                              :selected="sortIsSelected(sortProperties.name)"
                                              @headerClicked="changeSortProperty(sortProperties.name)"
                                              class="justifyStart">
                    </v-table-header-with-sort>
                    <v-table-header-with-sort :headerValue="$t('storedCalc.table.date')"
                                              :sortASC="sortProperty === sortProperties.date"
                                              :selected="sortIsSelected(sortProperties.date)"
                                              @headerClicked="changeSortProperty(sortProperties.date)"
                                              class="justifyStart">
                    </v-table-header-with-sort>
                    <th v-if="selectedPanel == 'all'">Typ</th>
                    <th>Poznámka</th>
                    <th>{{ $t('credit.app.loanAmount') }}</th>
                    <th>{{ $t('credit.app.maturity') }}</th>
                    <th>{{ $t('credit.app.fixation') }}</th>

                    <th></th>
                    <th></th>
                    <th></th>

                    <th></th>
                </tr>
            </thead>
            <template v-if="applicants?.length">
                <tbody v-for="applicant in applicants" :key="applicant.Id">
                    <tr :class="['applicant active', { 'cursor-pointer': applicant.Calculations?.length !== 0 }]"
                        @click.stop.prevent="toggleCalculations($event)"
                        @mouseover="hoverRow($event)"
                        @mouseleave="hoverRowLeave($event)">
                        <td><i v-if="applicant.Calculations?.length !== 0" class="fas stored calcToggleSign fa-plus"></i></td>
                        <td class="name" :data-content="$t('storedCalc.table.clientTitle')">{{ applicant.Name }}</td>
                        <td :data-content="$t('storedCalc.table.modelationDateTimeShort')">{{ date(applicant.Date) }} {{ time(applicant.Date) }}</td>
                        <td v-if="selectedPanel == 'all'"></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <td></td>
                        <td></td>
                        <td></td>
                        <td :data-content="$t('storedCalc.table.action')">
                            <a>
                                <i v-if="applicant.Calculations?.length !== 0" class="fas stored fa-plus" style="display:none"> Rozbalit</i>
                            </a>
                            <img src="@/assets/icons/bin.svg"
                                 class="trash"
                                 style="display: none;"
                                 @click.stop.prevent="removeApplicant(applicant.Id)"
                                 v-bTooltip="$t('tooltip.storedCalc.table.deleteClient')" />
                        </td>
                    </tr>
                    <tr v-for="calculation in applicant.Calculations"
                        v-bind:key="calculation.Id"
                        class="calculation"
                        @mouseover="hoverRow($event)"
                        @mouseleave="hoverRowLeave($event)">
                        <td></td>
                        <td :data-content="$t('storedCalc.table.modelationTitle')" class="name" v-bTooltip="calculation.Name.length > 32? calculation.Name : null">{{ calculation.Name.length > 32? calculation.Name.substring(0,32) + "..." : calculation.Name }}</td>
                        <td :data-content="$t('storedCalc.table.modelationDateTimeShort')">{{ date(calculation.Date) }} {{ time(calculation.Date) }}</td>
                        <td v-if="selectedPanel == 'all'">{{translateType(calculation.Type)}}</td>
                        <td><span v-bTooltip="calculation.Note">{{ calculation.Note?.length > 42? calculation.Note.substring(0,42) + "..." : calculation.Note }}</span></td>
                        <td>
                            <vue-numeric :currency="$t('global.currencySymbol')"
                                         currency-symbol-position="suffix"
                                         separator="space"
                                         read-only="true"
                                         v-model="calculation.LoanAmount" />
                        </td>
                        <td>
                            <vue-numeric :currency="$t('global.years')"
                                         currency-symbol-position="suffix"
                                         separator="space"
                                         read-only="true"
                                         v-model="calculation.Maturity" />
                        </td>
                        <td>
                            <vue-numeric v-if="calculation.Fixations?.length < 2"
                                         :currency="$t('global.years')"
                                         currency-symbol-position="suffix"
                                         separator="space"
                                         read-only="true"
                                         v-model="calculation.Fixations[0]" />
                            <span v-else>
                                Více
                            </span>
                        </td>

                        <td :data-content="$t('storedCalc.table.rates')" class="btn-column">
                            <button v-if="calculation.RatesResponseAvaliable"
                                    type="button"
                                    class="btn btn-default-gray"
                                    v-bTooltip="$t('tooltip.storedCalc.table.ratesPdf')"
                                    @click="printRatesCalculation(calculation.Id)">
                                <img class="left" src="@/assets/icons/printer.svg" /> {{ $t('credit.app.buttons.rates') }}
                            </button>
                        </td>
                        <td :data-content="$t('storedCalc.table.credit')" class="btn-column">
                            <button v-if="calculation.CreditWorthinessResponseAvaliable"
                                    type="button"
                                    class="btn btn-default-gray"
                                    v-bTooltip="$t('tooltip.storedCalc.table.creditPdf')"
                                    @click="printBonitaCalculation(calculation.Id)">
                                <img class="left" src="@/assets/icons/printer.svg" /> {{ $t('credit.app.buttons.bonita') }}
                            </button>
                        </td>
                        <td :data-content="$t('storedCalc.table.rates')" class="btn-column">
                            <button v-if="calculation.RatesRequestAvaliable"
                                    type="button"
                                    class="btn btn-primary"
                                    v-bTooltip="translateGoTo(calculation.Type)"
                                    @click="loadCalculation(calculation.Id, calculation.Type)">
                                <img class="left" src="@/assets/icons/kalkulacka.svg" /> {{ $t('credit.app.buttons.count') }}
                            </button>
                        </td>
                        <td :data-content="$t('storedCalc.table.delete')">
                            <i style="display: none;"
                               class="fas stored fa-trash-alt"
                               aria-hidden="true"
                               @click.stop.prevent="removeCalculation(calculation.Id, calculation.Type)"
                               v-tooltip data-toggle="tooltip" :title="$t('tooltip.storedCalc.table.deleteModelation')">
                            </i>
                            <img src="@/assets/icons/bin.svg"
                                 class="trash"
                                 style="display: none;"
                                 @click.stop.prevent="removeCalculation(calculation.Id, calculation.Type)"
                                 v-bTooltip="$t('tooltip.storedCalc.table.deleteModelation')" />
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-else>
                <tbody>
                    <tr v-if="!loaded" class="active">
                        <td colspan="10" style="text-align:center"><i class="fas fa-spinner fa-spin"></i> {{ $t('storedCalc.table.loading') }}</td>
                    </tr>
                    <tr v-else class="active">
                        <td colspan="10" style="text-align:center">{{ $t('storedCalc.table.noCalculations') }}</td>
                    </tr>
                </tbody>
            </template>

        </table>

        <div v-else class="stored-mobile">
            <div>
                {{ $t('storedCalc.table.modelationTitle') }}
                <br />
                <div style="position:relative" class="mx-input-wrapper">
                    <input v-model="find" class="form-control" />
                    <i v-if="find == ''" class="inBox fas fa-search"></i>
                    <i v-else class="inBox fas fa-times" @click="removeFindValue()"></i>
                </div>
            </div>
            <div>
                {{ $t('storedCalc.table.modelationDateTime') }}<br />
                <date-picker v-model="dateRange" range format="DD-MM-YYYY"></date-picker>
            </div>

            <template v-if="applicants?.length">
                <div v-for="applicant in applicants" :key="applicant.Id" class="offer-group">
                    <div class="offer-mobile active">
                        <div class="offer-mobile-parameters">
                            <div>
                                {{ $t('storedCalc.table.clientTitle') }}
                                <b>{{ applicant.Name }}</b>
                            </div>
                            <div>
                                {{ $t('storedCalc.table.modelationDateTimeShort') }}
                                <b>{{ date(applicant.Date) }} {{ time(applicant.Date) }}</b>
                            </div>
                        </div>
                        <div class="row-arrow offer-row-arrow-mobile">
                            <img src="@/assets/icons/downFill.svg" @click="toggleGroupRows" />
                        </div>
                    </div>
                    <div class="offer-mobile"
                         v-for="calculation in applicant.Calculations"
                         v-bind:key="calculation.Id">
                        <div class="offer-mobile-parameters">
                            <div>
                                {{ $t('storedCalc.table.calculationTitle') }}
                                <b>{{ calculation.Name.length > 32? calculation.Name.substring(0,32) + "..." : calculation.Name }}</b>
                            </div>
                            <div>
                                {{ $t('storedCalc.table.modelationDateTimeShort') }}
                                <b>{{ date(calculation.Date) }} {{ time(calculation.Date) }}</b>
                            </div>
                            <div>
                                Poznámka
                                <b v-bTooltip="calculation.Note">{{ calculation.Note?.length > 42? calculation.Note.substring(0,32) + "..." : calculation.Note }}</b>
                            </div>
                            <div>
                                Výše úvěru
                                <b>
                                    <vue-numeric :currency="$t('global.currencySymbol')"
                                                 currency-symbol-position="suffix"
                                                 separator="space"
                                                 read-only="true"
                                                 v-model="calculation.LoanAmount" />
                                </b>
                            </div>
                            <div>
                                Splatnost
                                <b>
                                    <vue-numeric :currency="$t('global.years')"
                                                 currency-symbol-position="suffix"
                                                 separator="space"
                                                 read-only="true"
                                                 v-model="calculation.Maturity" />
                                </b>
                            </div>
                            <div>
                                Fixace
                                <b>
                                    <vue-numeric v-if="calculation.Fixations?.length < 2"
                                                 :currency="$t('global.years')"
                                                 currency-symbol-position="suffix"
                                                 separator="space"
                                                 read-only="true"
                                                 v-model="calculation.Fixations[0]" />
                                    <span v-else>
                                        Více
                                    </span>
                                </b>
                            </div>
                        </div>
                        <div class="offer-mobile-buttons">
                            <button v-if="calculation.RatesResponseAvaliable"
                                    type="button"
                                    class="btn btn-default-gray"
                                    v-bTooltip="$t('tooltip.storedCalc.table.ratesPdf')"
                                    @click="printRatesCalculation(calculation.Id)">
                                <img class="left" src="@/assets/icons/printer.svg" /> {{ $t('credit.app.buttons.rates') }}
                            </button>
                            <button v-if="calculation.CreditWorthinessResponseAvaliable"
                                    type="button"
                                    class="btn btn-default-gray"
                                    v-bTooltip="$t('tooltip.storedCalc.table.creditPdf')"
                                    @click="printBonitaCalculation(calculation.Id)">
                                <img class="left" src="@/assets/icons/printer.svg" /> {{ $t('credit.app.buttons.bonita') }}
                            </button>
                            <button v-if="calculation.RatesRequestAvaliable"
                                    type="button"
                                    class="btn btn-primary"
                                    v-bTooltip="$t('tooltip.storedCalc.table.ratesModelation')"
                                    @click="loadCalculation(calculation.Id, calculation.Type)">
                                <img class="left" src="@/assets/icons/kalkulacka.svg" /> {{ $t('credit.app.buttons.count') }}
                            </button>
                            <button type="button"
                                    class="btn btn-default pull-left"
                                    @click.stop.prevent="removeCalculation(calculation.Id, calculation.Type)">
                                <img class="left" src="@/assets/icons/X.svg" /> Smazat
                            </button>
                        </div>

                    </div>
                </div>
            </template>
            <template v-else>
                <div v-if="!loaded" class="text-center">
                    <i class="fas fa-spinner fa-spin"></i> {{ $t('storedCalc.table.loading') }}
                </div>
                <div v-else class="text-center">
                    {{ $t('storedCalc.table.noCalculations') }}
                </div>
            </template>
        </div>

        <!-- Pagination -->
        <ul id="calculationsPagination" class="pagination">
            <li v-for="index in pages" v-bind:key="index" class="page-link" v-bind:class="{ active: index == currentPage }"><a class="page-link" v-on:click="loadPage(index)">{{ index }}</a></li>
        </ul>
    </div>
</template>

<script>
    import DatePicker from 'vue-datepicker-next';
    import 'vue-datepicker-next/index.css';
    import 'vue-datepicker-next/locale/cs';
    import TableHeaderWithSort from '@/components/table/TableHeaderWithSort.vue';

    export default {
        name: 'app',
        components: {
            DatePicker,
            'v-table-header-with-sort': TableHeaderWithSort,
        },
        data() {
            return {
                dateRange: [null, null],
                find: '',
                applicants: [],
                alerts: [],
                loaded: false,
                showBonita: false,
                perPage: 10,
                pages: null,
                currentPage: 1,
                windowWidth: 0,
                sortProperty: '-Date',
                sortProperties: {
                    name: 'Name',
                    date: 'Date',
                },
                selectedPanel: 'all', // all, rates, payments, refinance
            }
        },
        mounted() {
            if (!window.location.pathname.includes("/StoredCalculations")) return;

            this.windowWidth = window.innerWidth;
            window.addEventListener("resize", this.windowResized);

            this.axios.post("/WebServices/StoredCalculations.asmx/ShowBonita", {
                headers: { Accept: 'application/json' }
            })
                .then(response => {
                    var parsedResponse = JSON.parse(response.data.d);

                    if (parsedResponse.ResponseStatusCode == 200) {
                        this.showBonita = parsedResponse.Data;
                    }
                });

            this.loadApplicants();
            
        },
        watch: {
            dateRange: function () {
                this.loadApplicants();
            },
            find: function () {
                this.loadApplicants();
            },
            selectedPanel: function () {
                this.applicants = [];
                this.currentPage = 1;
                switch (this.selectedPanel) {
                    case 'all':
                        this.loadApplicants();
                        break;
                    case 'rates':
                        this.loadApplicantsRates();
                        break;
                    case 'payments':
                        this.loadApplicantsPayments();
                        break;
                    case 'refinance':
                        this.loadApplicantsRefinance();
                        break;
                }
            }
        },
        computed: {
            filterRequest() {
                return {
                    CurrentPage: this.currentPage ?? 1,
                    DateFrom: this.dateRange[0],
                    DateTo: this.dateRange[1],
                    PageSize: this.perPage,
                    SearchQuery: this.find,
                    SortDesc: this.sortProperty[0] === "-",
                    SortProperty: this.sortProperty[0] === "-" ? this.sortProperty.slice(1) : this.sortProperty,
                }
            }
        },
        methods: {
            translateType(type) {
                switch (type) {
                    case 'Rates':
                        return this.$t('storedCalc.sazbyBonita');
                    case 'Payments':
                        return this.$t('storedCalc.payments');
                    case 'Refinance':
                        return this.$t('storedCalc.refinance');
                    default:
                        return "Neznámý";
                }
            },
            translateGoTo(type) {
                switch (type) {
                    case 'Rates':
                        return this.$t('storedCalc.goToRates');
                    case 'Payments':
                        return this.$t('storedCalc.goToPayments');
                    case 'Refinance':
                        return this.$t('storedCalc.goToRefinance');
                    default:
                        return "Neznámo";
                }
            },
            loadApplicants: function () {
                this.loaded = false;
                var request = this.filterRequest
                this.axios.post("/WebServices/StoredCalculations.asmx/GetFilteredApplicants", {
                    headers: { Accept: 'application/json' },
                    request
                })
                    .then(response => {
                        this.processFilterResponse(response);
                    });
            },
            loadApplicantsRates: function () {
                this.loaded = false;
                var request = this.filterRequest
                this.axios.post("/WebServices/StoredCalculations.asmx/GetFilteredRatesApplicants", {
                    headers: { Accept: 'application/json' },
                    request
                })
                    .then(response => {
                        this.processFilterResponse(response);
                    });
            },
            loadApplicantsPayments: function () {
                this.loaded = false;
                var request = this.filterRequest
                this.axios.post("/WebServices/StoredCalculations.asmx/GetFilteredPaymentsApplicants", {
                    headers: { Accept: 'application/json' },
                    request
                })
                    .then(response => {
                        this.processFilterResponse(response);
                    });
            },
            loadApplicantsRefinance: function () {
                this.loaded = false;
                var request = this.filterRequest
                this.axios.post("/WebServices/StoredCalculations.asmx/GetFilteredRefinanceApplicants", {
                    headers: { Accept: 'application/json' },
                    request
                })
                    .then(response => {
                        this.processFilterResponse(response);
                    });
            },
            processFilterResponse(response) {
                var parsedResponse = JSON.parse(response.data.d);
                if (parsedResponse.ResponseStatusCode == 200) {
                    this.applicants = parsedResponse.Data.Applicants;
                    this.updatePagination(parsedResponse.Data.TotalCount);
                }
                if (parsedResponse.ResponseStatusCode == 403)
                    this.alerts.push({ class: "alert-danger", message: parsedResponse.Data });
                if (parsedResponse.ResponseStatusCode == 500) {
                    this.alerts.push({ class: "alert-danger", message: parsedResponse.Data });
                }
                this.loaded = true;
            },
            loadPage(index) {
                this.loaded = false;
                this.currentPage = index;
                this.loadApplicants();
            },
            dynamicSort: function (property) {
                var sortOrder = 1;
                if (property[0] === "-") {
                    sortOrder = -1;
                    property = property.substr(1);
                }
                return function (a, b) {
                    const aVal = a[property];
                    const bVal = b[property];
                    var result = (aVal < bVal) ? -1 : (aVal > bVal) ? 1 : 0;
                    return result * sortOrder;
                }
            },
            sortIsSelected(prop) {
                return this.sortProperty.endsWith(prop);
            },
            changeSortProperty(property) {
                if (this.sortProperty === property || this.sortProperty.substring(1) === property)
                    this.sortProperty = this.toggleSortPropertySort(this.sortProperty);
                else
                    this.sortProperty = property;
                this.loadApplicants();
            },
            toggleSortPropertySort(prop) {
                if (prop[0] === "-") return prop.substring(1);
                return '-' + prop;
            },
            removeFindValue: function () {
                this.find = '';
            },
            closeAllCalculations: function () {
                $(".applicant i.fas.calcToggleSign").removeClass('fa-minus').addClass('fa-plus');
                $(".calculation").removeClass('active');
            },
            date: function (date) {
                var ass = new Date(date);
                return ass.toLocaleDateString("cs-CZ");
            },
            time: function (date) {
                var ass = new Date(date);
                return ass.toLocaleTimeString("cs-CZ");
            },
            toggleCalculations: function (event) {
                var parentTr = $(event.target).closest('tr');
                parentTr.find('i.fas.calcToggleSign').toggleClass('fa-plus').toggleClass('fa-minus');
                parentTr.nextUntil('.applicant').toggleClass('active');
            },
            hoverRow: function (event) {
                $('img.trash').hide();
                $(event.target).closest('tr').find('img.trash').show();
            },
            hoverRowLeave: function (event) {
                $(event.target).closest('tr').find('img.trash').hide();
            },
            loadSazbyCalculation: function (calcId) {
                this.axios.post("/WebServices/StoredCalculations.asmx/LoadRatesCalculation", {
                    headers: { Accept: 'application/json' },
                    calculationId: calcId,
                })
                    .then(response => {
                        var parsedResponse = JSON.parse(response.data.d);
                        if (parsedResponse.ResponseStatusCode == 200)
                            window.location.href = parsedResponse.Data;
                        if (parsedResponse.ResponseStatusCode == 404)
                            this.alerts.push({ class: "alert-danger", message: parsedResponse.Data });
                        if (parsedResponse.ResponseStatusCode == 500) {
                            this.alerts.push({ class: "alert-danger", message: parsedResponse.Data });
                        }
                    })
            },
            loadCalculation: function (calcId, module) {
                switch (module) {
                    case 'Rates':
                        this.loadSazbyCalculation(calcId);
                        break;
                    case 'Payments':
                        window.location.href = this.calendarHrefLink("/" + calcId);
                        break;
                    case 'Refinance':
                        window.location.href = this.refinanceHrefLink("/" + calcId);
                        break;
                }
            },
            removeCalculation: function (calcId, module) {
                switch (module) {
                    case 'Rates':
                        this.removeRatesCalculation(calcId);
                        break;
                    case 'Payments':
                        this.removePaymentsCalculation(calcId);
                        break;
                    case 'Refinance':
                        this.removeRefinanceCalculation(calcId);
                        break;
                }
            },
            calendarHrefLink: function (calendarParams) {
                return '/GuidRedirect.aspx?module=payments&params=' + encodeURIComponent(calendarParams);
            },
            refinanceHrefLink: function (calendarParams) {
                return '/GuidRedirect.aspx?module=refinance&params=' + encodeURIComponent(calendarParams);
            },
            printBonitaCalculation: function (calcId) {
                this.axios({
                    url: '/WebServices/StoredCalculations.asmx/PrintCreditCalculation',
                    method: 'POST',
                    responseType: 'blob',
                    data:
                    {
                        calculationId: calcId
                    }
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'bonita-vcetne-bank.pdf');
                    document.body.appendChild(link);
                    link.click();
                }).catch(function () {
                    alert("Chyba při vytváření PDF, prosím zkuste znovu.");
                });
            },
            printRatesCalculation: function (calcId) {
                this.axios({
                    url: '/WebServices/StoredCalculations.asmx/PrintRatesCalculation',
                    method: 'POST',
                    responseType: 'blob',
                    data:
                    {
                        calculationId: calcId
                    }
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'sazby-vcetne-bank.pdf');
                    document.body.appendChild(link);
                    link.click();
                }).catch(function () {
                    alert("Chyba při vytváření PDF, prosím zkuste znovu.");
                });
            },
            removeApplicant: function (applicantId) {
                this.axios.post("/WebServices/StoredCalculations.asmx/RemoveApplicant", {
                    headers: { Accept: 'application/json' },
                    applicantId: applicantId,
                })
                    .then(response => {
                        var parsedResponse = JSON.parse(response.data.d);
                        if (parsedResponse.ResponseStatusCode == 200) {
                            var index = this.applicants.findIndex(d => d.Id == applicantId);
                            if (index > -1) {
                                this.applicants.splice(index, 1);
                            }
                        }
                        if (parsedResponse.ResponseStatusCode == 403)
                            this.alerts.push({ class: "alert-danger", message: parsedResponse.Data });
                        if (parsedResponse.ResponseStatusCode == 404)
                            this.alerts.push({ class: "alert-danger", message: parsedResponse.Data });
                        if (parsedResponse.ResponseStatusCode == 500) {
                            this.alerts.push({ class: "alert-danger", message: parsedResponse.Data });
                        }
                    })
            },
            removeRatesCalculation: function (calculationId) {
                this.axios.post("/WebServices/StoredCalculations.asmx/RemoveCalculation", {
                    headers: { Accept: 'application/json' },
                    calculationId: calculationId,
                })
                    .then(response => {
                        var parsedResponse = JSON.parse(response.data.d);
                        if (parsedResponse.ResponseStatusCode == 200) {
                            for (var i = 0; i < this.applicants?.length; i++) {
                                var index = this.applicants[i].Calculations.findIndex(d => d.Id == calculationId && d.Type == 'Rates');
                                if (index > -1) {
                                    this.applicants[i].Calculations.splice(index, 1);
                                }
                            }
                        }
                        else
                            this.alerts.push({ class: "alert-danger", message: parsedResponse.Data });
                    })
            },
            removePaymentsCalculation: function (calculationId) {
                this.axios.post("/WebServices/StoredCalculations.asmx/RemovePaymentsCalculation", {
                    headers: { Accept: 'application/json' },
                    calculationId: calculationId,
                })
                    .then(response => {
                        var parsedResponse = JSON.parse(response.data.d);
                        if (parsedResponse.ResponseStatusCode == 200) {
                            for (var i = 0; i < this.applicants?.length; i++) {
                                var index = this.applicants[i].Calculations.findIndex(d => d.Id == calculationId && d.Type == 'Payments');
                                if (index > -1) {
                                    this.applicants[i].Calculations.splice(index, 1);
                                }
                            }
                        }
                        else
                            this.alerts.push({ class: "alert-danger", message: parsedResponse.Data });
                    })
            },
            removeRefinanceCalculation: function (calculationId) {
                this.axios.post("/WebServices/StoredCalculations.asmx/RemoveRefinanceCalculation", {
                    headers: { Accept: 'application/json' },
                    calculationId: calculationId,
                })
                    .then(response => {
                        var parsedResponse = JSON.parse(response.data.d);
                        if (parsedResponse.ResponseStatusCode == 200) {
                            for (var i = 0; i < this.applicants?.length; i++) {
                                var index = this.applicants[i].Calculations.findIndex(d => d.Id == calculationId && d.Type == 'Refinance');
                                if (index > -1) {
                                    this.applicants[i].Calculations.splice(index, 1);
                                }
                            }
                        }
                        else
                            this.alerts.push({ class: "alert-danger", message: parsedResponse.Data });
                    })
            },
            switchToSpinner: function (event) {
                event.target.classList.remove('fa-file-download', 'fa-calculator');
                event.target.classList.add('fa-spinner', 'fa-spin');
            },
            switchToFileDownload: function (event) {
                event.target.classList.remove('fa-spinner', 'fa-spin');
                event.target.classList.add('fa-file-download');
            },
            switchToCalculator: function (event) {
                event.target.classList.remove('fa-spinner', 'fa-spin');
                event.target.classList.add('fa-calculator');
            },
            updatePagination: function (totalCount) {
                this.pages = Math.ceil(totalCount / this.perPage);
            },
            windowResized() {
                this.windowWidth = window.innerWidth;
            },
            toggleGroupRows(event) {
                $(event.target).closest('div.offer-mobile').siblings().toggleClass('active');
            },
        }
    }
</script>

<style scoped>
    .heading-icon {
        width: 25px;
        margin-right: .5rem;
    }

    .table {
        min-width: 900px;
        border-radius: 7px;
    }

        .table .btn {
            font-size: 12px;
            padding: 4px 8px;
            margin: 2px 4px;
        }

            .table .btn img {
                height: 14px;
            }

        .table .btn-column {
            width: 80px;
            padding: 8px 0;
        }

    .trash {
        cursor: pointer;
        width: 25px;
        /*filter: brightness(0) saturate(100%) invert(100%) sepia(76%) saturate(0%) hue-rotate(169deg) brightness(106%) contrast(105%);*/
    }

    td.name {
        word-break: break-word;
        max-width: 250px;
    }

    .table tr.applicant > td:nth-child(2) {
        font-weight: bold;
    }

    .table tr.calculation > td:nth-child(2) {
        padding-left: 20px;
    }

    .table tr {
        height: 50px;
        font-size: 12px;
    }

    /*.table td:not(:nth-child(2)) {
        text-align: center;
    }*/

    .table th {
        vertical-align: middle;
        font-size: 12px;
        font-weight: 600;
        text-align: left;
    }

    .table td:last-child {
        min-width: 60px;
    }

    .fa-calculator.stored, .fa-file-download.stored, .fa-trash-alt.stored, .fa-spinner.stored {
        cursor: pointer;
        font-size: 25px;
    }

    .fa-trash-alt.stored {
        color: red;
    }

    .table .fa-search, .table .fa-times {
        color: rgba(0,0,0,.5);
        font-size: 15px;
    }

    .icon-placeholder {
        width: 50px;
        display: inline-flex;
        justify-content: center;
    }

    i.inBox {
        position: absolute;
        top: 0;
        right: 10px;
        z-index: 2;
        display: block;
        line-height: 34px;
        text-align: center;
        cursor: pointer;
        font-size: 20px;
    }

    #calculationsPagination {
        margin-left: 8px;
    }

        #calculationsPagination li a {
            cursor: pointer;
        }

    .offer-mobile-parameters {
        gap: 0px;
    }

        .offer-mobile-parameters > div {
            flex: 0 1 auto;
            width: 50%;
        }

    .offer-mobile-buttons {
        flex-wrap: wrap;
    }

    .filterRow > div {
        float: left;
        padding: 10px;
    }

    ul.rates-module-select {
        padding: 0px;
        margin: 0px;
        display: flex;
        gap: 5px;
    }

        ul.rates-module-select > li {
            /*flex: 1 0 0;*/
            list-style: none;
            text-align: center;
            background-color: none;
            color: #000;
            padding: 14px;
            cursor: pointer;
            font-size: 12px;
            font-weight: 600;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
        }

            ul.rates-module-select > li.active {
                background: #fff;
            }

</style>

<style>
    .table-hypo .mx-input-wrapper {
        max-width: 250px;
    }

    table.table-hypo th .mx-datepicker-range {
        width: unset !important;
    }

    .stored-mobile .mx-datepicker-range {
        width: 100%;
    }

    th.justifyStart > div{
        justify-content: flex-start;
    }
</style>
