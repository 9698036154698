<template>
    <div class="modal-body">

        <v-button-select selectId="businessIncomeTypeSk"
                         :items="[{name: 'DP FO, typB', value: 'fo'}, {name: 'DP PO - s.r.o.', value: 'po'}]"
                         :selectedValue="applicant.activeSections.incomes.incomeTypeSk"
                         @update:selectedValue="val => applicant.activeSections.incomes.incomeTypeSk = val"
                         fontSize="18px">
        </v-button-select>

        <template v-if="applicant.activeSections.incomes.incomeTypeSk === 'fo'">
            <div class="bordered-group">
                <div class="form-check">
                    <label class="clickable">
                        <input type="checkbox" id="incomesBusiness" v-model="applicant.activeSections.incomes.incomeTS.incomeCheckboxes.prijmyPodnikani" @click="collapse" /> Príjmy z podnikania
                    </label>
                    <img class="group-reset"
                         src="@/assets/icons/update-auto.svg"
                         @click="resetIncomesBusiness"
                         v-bTooltip="$t('global.defaultValues')" />
                </div>
                <div class="collapse" id="incomesBusinessContent">
                    <div class="bordered-group-inner">
                        <div class="row">
                            <div class="col-md-12">
                                <v-labeled-button-select label="Vedenie účtovnej evidencie"
                                                         :value="applicant.incomes.lastTaxSettlementSk.taxSettlementBusinessSk.outcomeType"
                                                         @update:value="val => applicant.incomes.lastTaxSettlementSk.taxSettlementBusinessSk.outcomeType = val"
                                                         :items="flatRates"
                                                         selectId="flatRate" />
                            </div>

                            <div class="col-md-6">
                                <v-labeled-select-list selectName="occupation"
                                                       label="Slobodné povolanie"
                                                       v-model="applicant.incomes.lastTaxSettlementSk.taxSettlementBusinessSk.occupation"
                                                       :items="occupations"
                                                       :searchable="true" />
                            </div>
                            <div class="col-md-6 empty-form-group"></div>

                            <div class="col-md-6">
                                <v-labeled-input label="Príjmy z podnikania (r. 39)"
                                                 v-model="applicant.incomes.lastTaxSettlementSk.taxSettlementBusinessSk.businessIncome"
                                                 number="true"
                                                 :addonString="$t('global.currencySymbol')"
                                                 inputId="businessIncome" />
                            </div>
                            <div class="col-md-6">
                                <v-labeled-input label="Čiastkový základ dane z podnikania (r. 45)"
                                                 v-model="applicant.incomes.lastTaxSettlementSk.taxSettlementBusinessSk.businessTaxBase"
                                                 number="true"
                                                 :addonString="$t('global.currencySymbol')"
                                                 inputId="businessTaxBase" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bordered-group">
                <div class="form-check">
                    <label class="clickable">
                        <input type="checkbox" id="incomesRenting" v-model="applicant.activeSections.incomes.incomeTS.incomeCheckboxes.prijmyPronajem" @click="collapse" /> Príjmy z prenájmu
                    </label>
                    <img class="group-reset"
                         src="@/assets/icons/update-auto.svg"
                         @click="resetIncomesRenting"
                         v-bTooltip="$t('global.defaultValues')" />
                </div>
                <div class="collapse" id="incomesRentingContent">
                    <div class="bordered-group-inner">
                        <div class="row">
                            <div class="col-md-6">
                                <v-labeled-input label="Príjmy z prenájmu a autorských príjmov (r. 58)"
                                                 v-model="applicant.incomes.lastTaxSettlementSk.taxSettlementLeaseSk.leaseIncome"
                                                 number="true"
                                                 :addonString="$t('global.currencySymbol')"
                                                 inputId="leaseIncome" />
                            </div>
                            <div class="col-md-6">
                                <v-labeled-input label="Čiastkový základ dane  z prenájmu a autorských príjmov (r. 65)"
                                                 v-model="applicant.incomes.lastTaxSettlementSk.taxSettlementLeaseSk.leaseTaxBase"
                                                 number="true"
                                                 :addonString="$t('global.currencySymbol')"
                                                 inputId="leaseTaxBase" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <!--<div class="col-md-6">
                <v-labeled-input label="Základ dane" v-model="applicant.incomes.lastTaxSettlementSk.taxBase" number="true" :addonString="$t('global.currencySymbol')" inputId="taxBaseInput" />
            </div>-->
                <div class="col-md-6">
                    <v-labeled-input label="Daňová povinnosť (r. 118)"
                                     v-model="applicant.incomes.lastTaxSettlementSk.tax"
                                     number="true"
                                     :addonString="$t('global.currencySymbol')"
                                     inputId="dan" />
                </div>
            </div>
        </template>

        <template v-if="applicant.activeSections.incomes.incomeTypeSk === 'po'">
            <div class="bordered-group">
                <div class="form-check">
                    <label>
                        Príjmy z podnikania
                    </label>
                    <img class="group-reset"
                         src="@/assets/icons/update-auto.svg"
                         @click="resetIncomesBusinessLegalEntity"
                         v-bTooltip="$t('global.defaultValues')" />
                </div>
                <div>
                    <div class="bordered-group-inner">
                        <div class="row">
                            <div class="col-md-6">
                                <v-labeled-select-list selectName="occupation"
                                                       label="Slobodné povolanie"
                                                       v-model="applicant.incomes.taxSettlementLegalEntitySk.taxSettlementBusinessSk.occupation"
                                                       :items="occupations"
                                                       :searchable="true" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <v-labeled-input label="Príjem z podnikania (časť F, r. 1)"
                                                 v-model="applicant.incomes.taxSettlementLegalEntitySk.taxSettlementBusinessSk.businessIncome"
                                                 number="true"
                                                 :addonString="$t('global.currencySymbol')"
                                                 inputId="businessIncome" />
                            </div>
                            <div class="col-md-6">
                                <v-labeled-input label="Základ dane (čásť 2, r. 400)"
                                                 v-model="applicant.incomes.taxSettlementLegalEntitySk.taxSettlementBusinessSk.businessTaxBase"
                                                 number="true"
                                                 :addonString="$t('global.currencySymbol')"
                                                 inputId="businessTaxBase" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <v-labeled-input label="Daňová povinnosť (r. 118)"
                                     v-model="applicant.incomes.taxSettlementLegalEntitySk.tax"
                                     number="true"
                                     :addonString="$t('global.currencySymbol')"
                                     inputId="dan" />
                </div>
            </div>
        </template>


    </div>

</template>

<script>
    import ButtonSelect from '../ButtonSelect.vue'
    import LabeledButtonSelect from '../LabeledButtonSelect.vue'
    import LabeledSelectList from '../LabeledSelectList.vue'
    import LabeledInput from '../LabeledInput.vue'
    import { ResetTSIncomes, ResetTSIncomesBusiness, ResetTSIncomesLease, ResetTSIncomesBusinessLegalEntity } from '../../js/HouseholdHelpers.mjs'

    export default {
        name: 'v-incomes-ts-modal-body-sk',
        props: {
            tsProvider: String,
            applicant: Object,
            occupations: Array,
            flatRates: Array
        },
        computed: {

        },
        watch: {
            'applicant.activeSections.incomes.incomeTypeSk'() {
                this.$nextTick(() => {
                    this.manageCollapses();
                });
            }
        },
        methods: {
            collapse(event) {
                /* eslint-disable */
                var id = event.currentTarget.id;
                var checkInput = document.getElementById(id);
                if (checkInput.checked) $('#' + id + 'Content').collapse('show');
                else $('#' + id + 'Content').collapse('hide');
            },
            resetIncomesBusiness() {
                ResetTSIncomesBusiness(this);
            },
            resetIncomesRenting() {
                ResetTSIncomesLease(this);
            },
            resetIncomesBusinessLegalEntity() {
                ResetTSIncomesBusinessLegalEntity(this);
            },
            manageCollapses: function () {
                /* eslint-disable */
                if (this.applicant.activeSections.incomes.incomeTS.incomeCheckboxes.prijmyPodnikani) $('#incomesBusinessContent').collapse('show');
                else $('#incomesBusinessContent').collapse('hide');
                if (this.applicant.activeSections.incomes.incomeTS.incomeCheckboxes.prijmyPronajem) $('#incomesRentingContent').collapse('show');
                else $('#incomesRentingContent').collapse('hide');
            },
            resetModal() {
                ResetTSIncomes(this);
                this.manageCollapses();
            },
            saveChanges() {
                this.$emit('saveChanges');
            },
            checkTax() {
                this.saveChanges();
                // eslint-disable-next-line
                $('#prijmyDPModal').modal('hide');
            }  
        },
        mounted() {
            this.observer = new MutationObserver(mutations => {
                for (const m of mutations) {
                    const newValue = m.target.className;
                    if (newValue === 'modal fade in') {
                        this.$nextTick(() => {
                            this.manageCollapses();
                        });
                    }
                }
            });
            // eslint-disable-next-line
            $(document).on('hidden.bs.modal', '.modal', function () {
                // eslint-disable-next-line
                $('.modal:visible').length && $(document.body).addClass('modal-open');
            });

            this.observer.observe(this.$parent.$refs.incomesTSModal, {
                attributes: true,
                attributeFilter: ['class'],
            });
        },
        components: {
            'v-button-select': ButtonSelect,
            'v-labeled-button-select': LabeledButtonSelect,
            'v-labeled-select-list': LabeledSelectList,
            'v-labeled-input': LabeledInput,
        }

    }
</script>

<style scoped src="./IncomesBodyStyle.css"></style>

