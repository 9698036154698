import { InitializeHouseholds, AddNewHousehold, DefaultActiveSections, DefaultSolidLinks, DefaultExpenses, DefaultIncomes } from './HouseholdHelpers.mjs'
import { DefaultLastTaxSettlementCz, DefaultTurnoverIncome } from './HouseholdHelpers.mjs';
import { DefaultPreviousTaxSettlementCz } from './HouseholdHelpers.mjs';
import { DefaultPrePreviousTaxSettlement } from './HouseholdHelpers.mjs';
import { DefaultTaxSettlementBusinessCz, DefaultTaxSettlementLeaseCz } from './HouseholdHelpers.mjs';
import { DefaultTaxFlatRateCz, DefaultTaxFlatRateBusinessCz, DefaultTaxFlatRateLeaseCz } from './HouseholdHelpers.mjs';
import { DefaultMethodologyFilter, DefaultMethodologyFilterSk, ApplyMethodologyFilter } from './HouseholdHelpers.mjs';


export function LoadEnumTypes(anchor) {
    anchor.axios.post("/Default.aspx/EnumData", { headers: { Accept: 'application/json' } })
        .then(response => {
            if(!anchor.$i18n) return;
            var res = JSON.parse(response.data.d);
            if (res.taxIncomesProvider === 'cz') {
               anchor.czNaces = res.czNaces;
               anchor.flatRates = [{name: '40%', value: 'FlatRate40'}, {name: '60%', value: 'FlatRate60'}, {name: '80%', value: 'FlatRate80'}];
               anchor.closedTaxPeriods = res.closedTaxPeriods;
            }
            if (res.taxIncomesProvider === 'sk') {
                anchor.flatRates = res.flatRates;
            }

            anchor.settings.expensesProvider = res.expensesProvider;
            anchor.settings.otherIncomesProvider = res.otherIncomesProvider;
            anchor.settings.taxIncomesProvider = res.taxIncomesProvider;

            anchor.propertyTypes = res.propertyTypes;
            anchor.loanPurposes = res.loanPurposes;
            anchor.maritalStatuses = res.maritalStatuses;
            anchor.LTVs = res.ltVs;
            anchor.occupations = res.occupations;
            anchor.fixations = res.fixations;
            anchor.maturities = res.maturities;
            anchor.maturitiesMonths = res.maturitiesMonths;
            anchor.regions = res.regions;
            anchor.personCounts = res.personCounts;
            anchor.childTaxReturns = [
                    { id: 1, value: 15204, text: "1 dítě 15 204 Kč" },
                    { id: 2, value: 37524, text: "2 děti 37 524 Kč" },
                    { id: 3, value: 65364, text: "3 děti 65 364 Kč" },
                    { id: 4, value: 0, text: "Jiná varianta" },
                ];
            anchor.showLoanScheduleLink = res.showLoanScheduleLink;
            anchor.settings.showReportCalculationError = res.showReportCalculationError;
            
            anchor.printButtonVisible = res.printButtonVisible;
            anchor.printToUrlButtonVisible = res.printToUrlButtonVisible;
            anchor.printToUrlDataExtendedButtonVisible = res.printToUrlDataExtendedButtonVisible;
            anchor.allowRatesVariants = res.allowRatesVariants;

            anchor.generateHiddenFieldPdfPrint = res.generateHiddenFieldPdfPrint;
            anchor.usersClientData = res.usersClientData;
            anchor.hypoLtv = res.hypoRatesLtv;
            anchor.hypoFix = res.hypoRatesFix;
            anchor.vychoziVek = res.vychoziVek;
            anchor.dorisMortgagesAllowed = res.dorisMortgagesAllowed;
            anchor.dorisPersonalLoansAllowed = res.dorisPersonalLoansAllowed;
            anchor.dorisUnsecuredLoansAllowed = res.dorisUnsecuredLoansAllowed;
            anchor.dorisLinkTarget = res.dorisLinkTarget;

            for (var k in res.model.loanParams) {
                anchor.loanParams[k] = res.model.loanParams[k];
            }

            anchor.filter = res.filter;
            anchor.dummyPartners = res.dummyPartners;
            anchor.ratesExportFiles = res.exportFiles;
            anchor.methodologyForeignersSettings = res.foreigners;
            anchor.methodologyFilterItems = anchor.localization === 'sk' ? DefaultMethodologyFilterSk() : DefaultMethodologyFilter()
            ApplyMethodologyFilter(anchor.methodologyFilterItems, res.methodologyFilters);
            anchor.methodologyFilter = res.methodologyFilters;
            anchor.methodologyMenuItems = res.methodologyMenuItems;

            anchor.maturityTemp = (Math.floor(parseInt(res.model.loanParams.maturity) / 12) * 12).toString();
            anchor.maturityExtraMonthsTemp = (parseInt(res.model.loanParams.maturity) % 12).toString();
            if (res.model !== null && res.model.households != null && res.model.households.length) {
                //clean array
                anchor.households.splice(0, anchor.households.length);

                //new items
                for (var i = 0; i < res.model.households.length; i++) {
                    var hh = AddNewHousehold(anchor);
                    hh.maritalStatus = res.model.households[i].maritalStatus;
                    hh.region = res.model.households[i].region;

                    hh.dite5minusCount = res.model.households[i].dite5minusCount;
                    hh.dite6plusCount = res.model.households[i].dite6plusCount;
                    hh.dite10plusCount = res.model.households[i].dite10plusCount;
                    hh.dite16plusCount = res.model.households[i].dite16plusCount;

                    hh.applicantPersons.splice(0, hh.applicantPersons.length);

                    for (var j in res.model.households[i]) {
                        if (j == 'applicantPersons') {
                            for (var apl = 0; apl < res.model.households[i].applicantPersons.length; apl++) {
                                var applicant = res.model.households[i].applicantPersons[apl];
                                var appl = {
                                    id: 0,
                                    vek: 30,
                                    incomes: DefaultIncomes(anchor.settings),
                                    expenses: DefaultExpenses(anchor.settings),
                                    sumOfAllLoanBalances: 0,
                                    citizenship: 'CZ',
                                    activeSections: DefaultActiveSections(),
                                    solidLinks: DefaultSolidLinks()
                                };
                                appl = Object.assign({}, appl, applicant);

                                try {
                                    //hack kvuli zpracovani bool as enum
                                    if (appl.incomes.lastTaxSettlementCz.taxSettlementLeaseCz.leaseFlatRate === true) {
                                        appl.incomes.lastTaxSettlementCz.taxSettlementLeaseCz.leaseFlatRate = 'true';
                                    } else {
                                        appl.incomes.lastTaxSettlementCz.taxSettlementLeaseCz.leaseFlatRate = 'false';
                                    }
                                } catch (err) { }

                                //Check for nulls in model
                                if (appl.incomes.lastTaxSettlementCz === null) appl.incomes.lastTaxSettlementCz = DefaultLastTaxSettlementCz();
                                if (appl.incomes.previousTaxSettlementCz === null) appl.incomes.previousTaxSettlementCz = DefaultPreviousTaxSettlementCz();
                                if (appl.incomes.prePreviousTaxSettlementCz === null) appl.incomes.prePreviousTaxSettlementCz = DefaultPrePreviousTaxSettlement();
                                if (appl.incomes.taxFlatRateCz === null) appl.incomes.taxFlatRateCz = DefaultTaxFlatRateCz();

                                if (appl.incomes.lastTaxSettlementCz.taxSettlementBusinessCz === null) appl.incomes.lastTaxSettlementCz.taxSettlementBusinessCz = DefaultTaxSettlementBusinessCz();
                                if (appl.incomes.lastTaxSettlementCz.taxSettlementLeaseCz === null) appl.incomes.lastTaxSettlementCz.taxSettlementLeaseCz = DefaultTaxSettlementLeaseCz();

                                if (appl.incomes.previousTaxSettlementCz.taxSettlementBusinessCz === null) appl.incomes.previousTaxSettlementCz.taxSettlementBusinessCz = DefaultTaxSettlementBusinessCz();
                                if (appl.incomes.previousTaxSettlementCz.taxSettlementLeaseCz === null) appl.incomes.previousTaxSettlementCz.taxSettlementLeaseCz = DefaultTaxSettlementLeaseCz();

                                if (appl.incomes.prePreviousTaxSettlementCz.taxSettlementBusinessCz === null) appl.incomes.prePreviousTaxSettlementCz.taxSettlementBusinessCz = DefaultTaxSettlementBusinessCz();
                                if (appl.incomes.prePreviousTaxSettlementCz.taxSettlementLeaseCz === null) appl.incomes.prePreviousTaxSettlementCz.taxSettlementLeaseCz = DefaultTaxSettlementLeaseCz();

                                if (appl.incomes.taxFlatRateCz.taxFlatRateBusinessCz === null) appl.incomes.taxFlatRateCz.taxFlatRateBusinessCz = DefaultTaxFlatRateBusinessCz();
                                if (appl.incomes.taxFlatRateCz.taxFlatRateLeaseCz === null) appl.incomes.taxFlatRateCz.taxFlatRateLeaseCz = DefaultTaxFlatRateLeaseCz();

                                if (appl.incomes.turnoverIncome === null) appl.incomes.turnoverIncome = DefaultTurnoverIncome();

                                try {
                                    //hack kvuli zpracovani string as enum
                                    var naceNumber = appl.incomes.lastTaxSettlementCz.taxSettlementBusinessCz.czNaceCz;
                                    var obje = res.czNaces.find(obj => { return obj.value === "CZ_Nace_" + naceNumber });
                                    appl.incomes.lastTaxSettlementCz.taxSettlementBusinessCz.czNaceCz = obje.value;
                                } catch (err) { }

                                try {
                                    //hack kvuli zpracovani string as enum
                                    var naceNumber = appl.incomes.taxFlatRateCz.taxFlatRateBusinessCz.czNaceCz
                                    var obje = res.czNaces.find(obj => { return obj.value === "CZ_Nace_" + naceNumber });
                                    appl.incomes.taxFlatRateCz.taxFlatRateBusinessCz.czNaceCz = obje.value;
                                } catch (err) { }

                                hh.applicantPersons.push(appl);
                            }
                            continue;
                        }
                        hh[k] = res.model.households[i][k];
                    }
                }

                anchor.currentHousehold = anchor.households[0];

            } else {
                InitializeHouseholds(anchor);
            }
            anchor.loadCompleted = true;
        });
    return;
}



export function LoadPlaceholderItems(anchor) {
    anchor.axios.post("/Default.aspx/PlaceholderItems", { headers: { Accept: 'application/json' } })
        .then(response => {
            anchor.resultItems = JSON.parse(response.data.d);
            return;
        });
}
