<template>
    <div class="modal" id="videoModal" tabindex="-1">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Videonávod</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <template v-if="localization == 'cs'">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/hbSiCNRnSMY?si=-Zj4I-znVXW06YQh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/i26VEwK34AU?si=C9GfZTw14lQHsHNQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                    </template>
                    <template v-if="localization == 'sk'">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/4m3q3iJNpo8?si=1IXn_zJZPDWwiy1e" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/K7qY_HXknvo?si=q4cXY3ERBr-ePMTR" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </template>
                </div>
                <div class="modal-footer">
                    <button type="button"
                            class="btn btn-default"
                            data-dismiss="modal">
                        <img class="left" src="@/assets/icons/X.svg" />{{ $t('global.cancel') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'v-video-modal',
        props: {
            localization: String
        }
    }
</script>

<style scoped>
    .modal-body {
        min-height: 500px;
        padding: 0;
    }

    iframe {
        width: 100%;
    }

    iframe:not(:last-of-type) {
        margin-bottom: 30px;
    }
</style>

