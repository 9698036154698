<template>
    <div class="modal fade" id="prijmyOstatniModal" tabindex="-1" data-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title"> {{ $t('credit.incomesOtherModal.otherIncomes') }} </h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="$t('credit.incomesOtherModal.closeWindow')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <component :applicant="applicant"
                           @update:applicant="val => applicant = val"
                           :personCounts="personCounts"
                           :household="household"
                           @update:household="val => household = val"
                           v-bind:is="'v-incomes-other-body-' + settings.otherIncomesProvider"
                           @saveChanges="saveChanges"
                           ref="incomesOtherBody"
                           :localizedProvider="settings.otherIncomesProvider"
                           ></component>

                <div class="modal-footer">
                    <button type="button"
                            class="btn btn-default pull-left"
                            data-dismiss="modal">
                        <img class="left" src="@/assets/icons/X.svg" />{{ $t('global.cancel') }}
                    </button>
                    <button type="button"
                            class="btn btn-default"
                            @click="resetModal">
                        <img class="pencil-reset left" src="@/assets/icons/update-auto.svg" />{{ $t('global.setDefault') }}
                    </button>
                    <button type="button"
                            class="btn btn-primary"
                            @click="checkOtherIncomes">
                        <img class="left" src="@/assets/icons/savesmall.svg" />{{ $t('global.save') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import IncomesOtherBodyCz from './IncomesOtherBody/IncomesOtherBodyCz.vue'
    import IncomesOtherBodySk from './IncomesOtherBody/IncomesOtherBodySk.vue'

    import { ResetIncomesOther } from '../js/HouseholdHelpers.mjs'

    export default {
        name: 'v-incomes-other-modal',
        components: {
            'v-incomes-other-body-cz': IncomesOtherBodyCz,
            'v-incomes-other-body-sk': IncomesOtherBodySk,
        },
        watch: {
            'applicant.incomes.otherIncomesCz': {
                handler() {
                    {
                        this.$emit('update:applicant', this.applicant);
                    }
                },
                deep: true
            }
        },
        computed: {
            incomesOtherBody() {
                return this.$refs.incomesOtherBody;
            }
        },
        methods: {
            resetModal: function () {
                ResetIncomesOther(this);
            },
            saveChanges() {
                this.$emit('saveChanges');
                // eslint-disable-next-line
                $('#prijmyOstatniModal').modal('hide');
            },
            checkOtherIncomes() {
                this.incomesOtherBody.checkChildIncome();
            }

        },
        props: {
            applicant: Object,
            settings: Object,
            personCounts: Array,
            household: Object,
        }
    }
</script>

