<template>
    <tr class="active">
        <td class="lh18">
            <div class="smallImgWrapper">
                <img v-bTooltip="item.ProductName" :src="'/ImageProxy.aspx?f=' + item.BankCode + '.png'" />
            </div>
        </td>
        <td colspan="70">
            {{ $t('credit.resultTableItemError.creditCantBeCounted') }}
        </td>
    </tr>
</template>

<script>
    export default {
        name: 'v-result-table-item',
        props: {
            item: Object
        }
    }
</script>